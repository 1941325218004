import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import moment from "moment";
import { postSubmitForm } from "../../helpers/forms_helper";

const ViewInventory = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadProducts();
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [transactionsModal, setTransactionsModal] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [isSelectedProductForSale, setIsSelectedProductForSale] = useState();
  const [isSelectedProductForUse, setIsSelectedProductForUse] = useState();
  const [selectedProduct_Transactions, setSelectedProduct_Transactions] =
    useState([]);
  const handleViewDetailsClick = async (row) => {
    let url = process.env.REACT_APP_BASEURL + "inventories/get_by_product";
    const response = await postSubmitForm(url, { product_id: row._id });
    if (response && response.status === 1) {
      setSelectedProduct_Transactions(response.data);
      setSelectedProduct(row);

      setTransactionsModal(!transactionsModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="mdi mdi-text-box-search font-size-15"
          title="View Details"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleViewDetailsClick(row);
            setIsSelectedProductForSale(row.is_for_sale);
            setIsSelectedProductForUse(row.is_for_use);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "available_quantity_for_sale",
      text: props.t("Available (for sale)"),
      sort: true,
      formatter: (cell, row) => {
        return row.is_for_sale ? (
          row.available_quantity_for_sale < row.threshold_quantity_for_sale ? (
            <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
              {row.available_quantity_for_sale}
            </span>
          ) : (
            row.available_quantity_for_sale
          )
        ) : (
          props.t("NA")
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      // dataField: "threshold_quantity_for_sale",
      text: props.t("Threshold (for sale)"),
      formatter: (cell, row) => {
        return row.is_for_sale
          ? row.threshold_quantity_for_sale
          : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "available_quantity_for_use",
      text: props.t("Available (for use)"),
      sort: true,
      formatter: (cell, row) => {
        return row.is_for_use ? (
          row.available_quantity_for_use < row.threshold_quantity_for_use ? (
            <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
              {row.available_quantity_for_use}
            </span>
          ) : (
            row.available_quantity_for_use
          )
        ) : (
          props.t("NA")
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      //dataField: "threshold_quantity_for_use",
      text: props.t("Threshold (for use)"),
      formatter: (cell, row) => {
        return row.is_for_use ? row.threshold_quantity_for_use : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "product_category_details.name",
      text: props.t("Product Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const [selectedInventory, setSelectedInventory] = useState();
  const [inventoryModal, setInventoryModal] = useState();
  const [withdrawModal, setWithdrawModal] = useState();
  const [transferType, setTransferType] = useState();
  const columns_transaction = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      //dataField: "quantity_for_sale",
      text: props.t("Quantity (Sale)"),
      sort: true,
      formatter: (cell, row) => {
        return isSelectedProductForSale ? row.quantity_for_sale : props.t("NA");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      //dataField: "quantity_for_use",
      text: props.t("Quantity (Use)"),
      sort: true,
      formatter: (cell, row) => {
        return isSelectedProductForUse ? row.quantity_for_use : props.t("NA");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      //dataField: "selling_price",
      text: props.t("Selling Price"),
      sort: true,
      formatter: (cell, row) => {
        return isSelectedProductForSale
          ? row.selling_price === 0
            ? ""
            : row.selling_price
          : props.t("NA");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "expiry_date",
      text: props.t("Expiry Date"),
      formatter: (cell, row) => {
        if (row.expiry_date === null) {
          return <span>{row.expiry_months + " months"}</span>;
        } else {
          return <span>{row.expiry_date}</span>;
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Entry Date"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Transfer"),
      formatter: (cell, row) => {
        if (isSelectedProductForSale && isSelectedProductForUse) {
          return (
            <span className="text-info">
              <i
                className="bx bx-transfer font-size-22"
                title="Transfer"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedInventory(row);
                  setInventoryModal(!inventoryModal);
                  setTransferType();
                }}
              ></i>
            </span>
          );
        } else {
          return (
            <span className="text-danger font-size-12">
              {props.t("Cannot Transfer")}
            </span>
          );
        }
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Withdraw"),
      formatter: (cell, row) => {
        if (isSelectedProductForUse && isSelectedProductForUse) {
          return (
            <span className="text-info">
              <i
                className="bx bx-download font-size-22"
                title="Withdraw for Use"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedInventory(row);
                  setWithdrawModal(!withdrawModal);
                }}
              ></i>
            </span>
          );
        } else {
          return (
            <span className="text-danger font-size-12">
              {props.t("Cannot Withdraw")}
            </span>
          );
        }
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Edit"),
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="bx bxs-edit font-size-15"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setInventoryUpdateModal(!inventoryUpdateModal);
                setSelectedBatch(row);
                setSelectedCategoryID(
                  row.product_details.product_category_details
                    .product_category_id
                );
                setSelectedProductID(row.product_details._id);
                setForUse(row.product_details.is_for_use);
                setIsNoExpiry(row.expiry_date === null ? true : false);

                setForSale(row.product_details.is_for_sale);

                setSelectedProductDetails(row.product_details);
              }}
            ></i>
          </span>
        );
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];
  const pagination_options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value:
          selectedProduct_Transactions && selectedProduct_Transactions.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const handleTransferInventory = async (e, v) => {
    if (
      +v.quantity > selectedInventory.quantity_for_sale &&
      v.type === "sales_to_use"
    ) {
      showNotification(
        props.t("Insufficient quantity available for sale."),
        props.t("Error")
      );
    } else if (
      +v.quantity > selectedInventory.quantity_for_use &&
      v.type === "use_to_sales"
    ) {
      showNotification(
        props.t("Insufficient quantity available for use."),
        props.t("Error")
      );
    } else {
      v.id = selectedInventory._id;
      let url = process.env.REACT_APP_BASEURL + "inventories/transfer";
      const response = await postSubmitForm(url, v);
      if (response && response.status === 1) {
        loadProducts();
        showNotification(props.t(response.message), props.t("Success"));
        setInventoryModal(!inventoryModal);
        setTransactionsModal(!transactionsModal);
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    }
  };
  const handleWithdrawInventory = async (e, v) => {
    if (+v.quantity > selectedInventory.quantity_for_use) {
      showNotification(
        props.t("Insufficient quantity available to withdraw."),
        props.t("Error")
      );
    } else {
      v.id = selectedInventory._id;
      let url = process.env.REACT_APP_BASEURL + "inventories/use";
      const response = await postSubmitForm(url, v);
      if (response && response.status === 1) {
        loadProducts();
        showNotification(props.t(response.message), props.t("Success"));
        setWithdrawModal(!withdrawModal);
        setTransactionsModal(!transactionsModal);
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    }
  };

  //#region Update inventory related functions ad variables
  const [inventoryUpdateModal, setInventoryUpdateModal] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const [selectedBatchCategory, setSelectedBatchCategory] = useState();
  const handleValidUpdate = async (e, v) => {
    try {
      let data_to_send = {};

      data_to_send.id = selectedBatch._id;
      data_to_send.product_id = selectedProductID;

      data_to_send.expiry_date = isNoExpiry ? null : v.expiry_date;
      data_to_send.expiry_months = isNoExpiry ? v.expiry_months : null;

      data_to_send.qty_for_sale = forSale ? v.qty_for_sale : 0;
      data_to_send.qty_for_use = forUse ? v.qty_for_use : 0;

      data_to_send.cost_price = v.cost_price;
      data_to_send.selling_price = v.selling_price ? v.selling_price : 0;
      data_to_send.remarks = v.remarks;
      data_to_send.transaction_password = v.transaction_password;

      let url = process.env.REACT_APP_BASEURL + "inventories/update";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        setInventoryUpdateModal(!inventoryUpdateModal);
        setSelectedBatch();
        setSelectedCategoryID();
        setSelectedProductID();
        setForUse(false);
        setIsNoExpiry(false);

        setForSale(false);

        setSelectedProductDetails();
        setTransactionsModal(!transactionsModal);
        loadProducts();
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  useEffect(() => {
    loadAllProductCategories();
  }, []);
  const [isNoExpiry, setIsNoExpiry] = useState(false);
  const [selectedCategoryID, setSelectedCategoryID] = useState();
  const [selectedProductID, setSelectedProductID] = useState();
  const [selectedProductDetails, setSelectedProductDetails] = useState();
  const [allProductCategories, setAllProductCategories] = useState();
  const loadAllProductCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProductCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [categoryWiseProducts, setCategoryWiseProducts] = useState();
  const loadCategoryWiseProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/get_by_category";
    const response = await postSubmitForm(url, {
      product_category_id: selectedCategoryID,
    });
    if (response && response.status === 1) {
      setCategoryWiseProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (selectedCategoryID) {
      // setSelectedProductDetails();
      // setSelectedProductID("");
      loadCategoryWiseProducts();
    }
  }, [selectedCategoryID]);

  const [forUse, setForUse] = useState(false);
  const [forSale, setForSale] = useState(false);
  const [currentDate] = useState(() => {
    var d1 = new Date();
    var d = moment(d1).add(1, "days").toDate();
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });
  //#endregion

  const handleDownload = () => {
    const fileName = "inventory";
    const exportType = "xls";
    console.log(allProducts);
    if (allProducts) {
      var data = JSON.parse(JSON.stringify(allProducts));
      data.forEach(function (v) {
        v.name = v.name;
        v.available_quantity_for_sale = v.is_for_sale
          ? v.available_quantity_for_sale
          : props.t("NA");
        v.threshold_quantity_for_sale = v.is_for_sale
          ? v.threshold_quantity_for_sale
          : props.t("NA");
        v.available_quantity_for_use = v.is_for_use
          ? v.available_quantity_for_use
          : props.t("NA");
        v.threshold_quantity_for_use = v.is_for_use
          ? v.threshold_quantity_for_use
          : props.t("NA");
        v.available_quantity_for_use = v.available_quantity_for_use;
        v.is_for_sale = v.is_for_sale ? props.t("Yes") : props.t("No");
        v.is_for_use = v.is_for_use ? props.t("Yes") : props.t("No");

        v.category = v.product_category_details.name;

        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.product_category_details;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Inventory")}
            breadcrumbItem={props.t("View Inventory")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("View Inventory")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all products in your inventory here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={allProducts && allProducts}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          {...props.searchProps}
                          style={{ width: "300px" }}
                        />

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={transactionsModal}
                    toggle={() => setTransactionsModal(!transactionsModal)}
                  >
                    <ModalHeader
                      toggle={() => setTransactionsModal(!transactionsModal)}
                    >
                      Product Name: {selectedProduct && selectedProduct.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedProduct && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedProduct_Transactions &&
                                          selectedProduct_Transactions
                                        }
                                        columns={columns_transaction}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              pagination={paginationFactory(
                                                pagination_options
                                              )}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={inventoryModal}
                    toggle={() => setInventoryModal(!inventoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setInventoryModal(!inventoryModal)}
                    >
                      {props.t("Batch")}:{" "}
                      {selectedInventory && selectedInventory.batch}
                    </ModalHeader>
                    <ModalBody>
                      {selectedInventory && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={handleTransferInventory}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={6}>
                                            <Label>
                                              {props.t("Available For Sale")}:{" "}
                                              {
                                                selectedInventory.quantity_for_sale
                                              }
                                            </Label>
                                          </Col>
                                          <Col lg={6}>
                                            <Label>
                                              {props.t("Available For Use")}:{" "}
                                              {
                                                selectedInventory.quantity_for_use
                                              }
                                            </Label>
                                          </Col>
                                        </Row>
                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        {" "}
                                        <Label>
                                          {props.t("Trnasfer Type")}
                                        </Label>
                                        <AvRadioGroup
                                          inline
                                          name="type"
                                          // onChange={(e, v) => {
                                          //   console.log(v);
                                          // }}
                                          required
                                          errorMessage={props.t(
                                            "Type cannot be empty."
                                          )}
                                        >
                                          <AvRadio
                                            label={props.t("Sales to Use")}
                                            value="sales_to_use"
                                            onChange={(e) => {
                                              setTransferType(e.target.value);
                                            }}
                                          />
                                          <AvRadio
                                            label={props.t("Use to Sales")}
                                            value="use_to_sales"
                                            onChange={(e) => {
                                              setTransferType(e.target.value);
                                            }}
                                          />
                                        </AvRadioGroup>
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="quantity"
                                          label={props.t("Quantity")}
                                          placeholder={props.t(
                                            "Enter quantity to transfer"
                                          )}
                                          errorMessage={props.t(
                                            "Quantity cannot be empty."
                                          )}
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>
                                      {transferType &&
                                      transferType === "use_to_sales" ? (
                                        <Col lg={12}>
                                          <AvField
                                            name="selling_price"
                                            label={props.t("Selling Price")}
                                            placeholder={props.t(
                                              "Enter Selling Price"
                                            )}
                                            // value={
                                            //   selectedInventory.selling_price +
                                            //   ""
                                            // }
                                            value={
                                              selectedInventory.selling_price ===
                                              0
                                                ? ""
                                                : selectedInventory.selling_price +
                                                  ""
                                            }
                                            type="text"
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage: props.t(
                                                  "Selling price cannot be empty"
                                                ),
                                              },
                                              pattern: {
                                                value: "^\\d+(\\.\\d{1,2})?$",
                                                errorMessage: props.t(
                                                  "Only numbers and decimals upto two places are allowed."
                                                ),
                                              },
                                            }}
                                          />
                                        </Col>
                                      ) : null}

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Submit")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={withdrawModal}
                    toggle={() => setWithdrawModal(!withdrawModal)}
                  >
                    <ModalHeader
                      toggle={() => setWithdrawModal(!withdrawModal)}
                    >
                      {props.t("Batch")}:{" "}
                      {selectedInventory && selectedInventory.batch}
                    </ModalHeader>
                    <ModalBody>
                      {selectedInventory && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={handleWithdrawInventory}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={12} className="text-center">
                                            <Label>
                                              {props.t("Available For Use")}:{" "}
                                              {
                                                selectedInventory.quantity_for_use
                                              }
                                            </Label>
                                          </Col>
                                        </Row>
                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        <AvField
                                          name="quantity"
                                          label={props.t("Quantity")}
                                          placeholder={props.t(
                                            "Enter quantity to withdraw"
                                          )}
                                          errorMessage={props.t(
                                            "Quantity cannot be empty."
                                          )}
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Submit")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={inventoryUpdateModal}
                    toggle={() =>
                      setInventoryUpdateModal(!inventoryUpdateModal)
                    }
                  >
                    <ModalHeader
                      toggle={() =>
                        setInventoryUpdateModal(!inventoryUpdateModal)
                      }
                    >
                      Batch: {selectedBatch && selectedBatch.batch}
                    </ModalHeader>
                    <ModalBody>
                      {selectedBatch && (
                        <>
                          <AvForm
                            onValidSubmit={(e, v) => {
                              handleValidUpdate(e, v);
                            }}
                          >
                            <Row>
                              <Col lg={6}>
                                <AvField
                                  name="product_categories"
                                  label={props.t("Category") + " *"}
                                  type="select"
                                  value={selectedCategoryID}
                                  onChange={(e, v) => {
                                    setSelectedCategoryID(e.target.value);
                                  }}
                                  required
                                  errorMessage={props.t("Select Category")}
                                >
                                  <option value="" name="">
                                    -- {props.t("Select Category")} --
                                  </option>
                                  {allProductCategories &&
                                    allProductCategories.map((item) => {
                                      return (
                                        <option
                                          value={item._id}
                                          name={item.name}
                                        >
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </AvField>
                              </Col>
                              <Col lg={6}>
                                <AvField
                                  name="product_name"
                                  label={props.t("Product") + " *"}
                                  type="select"
                                  value={selectedProductID}
                                  onChange={(e, v) => {
                                    setSelectedProductID(e.target.value);

                                    let arr = categoryWiseProducts.filter(
                                      (prod) => prod._id === e.target.value
                                    );
                                    setSelectedProductDetails(arr[0]);
                                  }}
                                  required
                                  errorMessage={props.t("Select Product")}
                                >
                                  <option value="" name="">
                                    -- {props.t("Select Product")} --
                                  </option>
                                  {categoryWiseProducts &&
                                    categoryWiseProducts.map((item) => {
                                      return (
                                        <option
                                          value={item._id}
                                          name={item.name}
                                        >
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </AvField>
                              </Col>
                              {selectedProductDetails && (
                                <Col lg={6}>
                                  <Label>{props.t("Quantity") + " *"}</Label>
                                  {selectedProductDetails.is_for_sale ? (
                                    <Row>
                                      <Col lg={3}>
                                        <div class="form-check">
                                          <input
                                            type="checkbox"
                                            name="for_sale"
                                            className="is-untouched is-pristine av-valid form-check-input"
                                            checked={forSale}
                                            required={!forUse}
                                            errorMessage={props.t(
                                              "Select either 'For Sale' or 'For Use.' or both."
                                            )}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setForSale(true);
                                              } else {
                                                setForSale(false);
                                              }
                                            }}
                                          />
                                          <label for="for_sale">
                                            {" "}
                                            {props.t("For Sale")}
                                          </label>
                                        </div>
                                        {/* <AvField
                                          type="checkbox"
                                          name="for_sale"
                                          label="For Sale"
                                          onChange={(e, v) => {
                                            setForSale(v);
                                          }}
                                          required={!forUse}
                                          errorMessage={props.t(
                                            "Select either 'For Sale' or 'For Use.' or both."
                                          )}
                                        /> */}
                                      </Col>
                                      <Col lg={9}>
                                        {forSale ? (
                                          <AvField
                                            name="qty_for_sale"
                                            placeholder={props.t(
                                              "Enter Quantity For Sale"
                                            )}
                                            value={
                                              selectedBatch.quantity_for_sale
                                            }
                                            errorMessage="Quantity cannot be empty."
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                              pattern: {
                                                value: "^[0-9]+$",
                                                errorMessage: props.t(
                                                  "Only numbers are allowed."
                                                ),
                                              },
                                            }}
                                          />
                                        ) : null}
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {selectedProductDetails.is_for_use ? (
                                    <Row>
                                      <Col lg={3}>
                                        <div class="form-check">
                                          <input
                                            type="checkbox"
                                            name="for_use"
                                            className="is-untouched is-pristine av-valid form-check-input"
                                            checked={forUse}
                                            required={!forSale}
                                            errorMessage={props.t(
                                              "Select either 'For Sale' or 'For Use.' or both."
                                            )}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setForUse(true);
                                              } else {
                                                setForUse(false);
                                              }
                                            }}
                                          />
                                          <label for="for_use">
                                            {" "}
                                            {props.t("For Use")}
                                          </label>
                                        </div>
                                        {/* <AvField
                                          type="checkbox"
                                          name="for_use"
                                          label="For Use"
                                          onChange={(e, v) => {
                                            setForUse(v);
                                          }}
                                          required={!forSale}
                                          errorMessage={props.t(
                                            "Select either 'For Sale' or 'For Use.' or both."
                                          )}
                                        /> */}
                                      </Col>
                                      <Col lg={9}>
                                        {forUse ? (
                                          <AvField
                                            name="qty_for_use"
                                            placeholder={props.t(
                                              "Enter Quantity For Use"
                                            )}
                                            value={
                                              selectedBatch.quantity_for_use
                                            }
                                            errorMessage="Quantity cannot be empty."
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                              pattern: {
                                                value: "^[0-9]+$",
                                                errorMessage: props.t(
                                                  "Only numbers are allowed."
                                                ),
                                              },
                                            }}
                                          />
                                        ) : null}
                                      </Col>
                                    </Row>
                                  ) : null}
                                  <br />
                                </Col>
                              )}
                              <Col lg={6}>
                                <Row>
                                  <Col lg={7}>
                                    {isNoExpiry ? (
                                      <AvField
                                        name="expiry_months"
                                        label={props.t("Expiry Months") + " *"}
                                        placeholder={props.t(
                                          "Enter Number of Months"
                                        )}
                                        value={
                                          selectedBatch.expiry_months !== null
                                            ? selectedBatch.expiry_months
                                            : ""
                                        }
                                        type="text"
                                        validate={{
                                          required: { value: true },
                                          pattern: {
                                            value: "^[0-9]+$",
                                            errorMessage: props.t(
                                              "Only numbers are allowed."
                                            ),
                                          },
                                        }}
                                        required
                                        errorMessage="Expiry months cannot be empty"
                                      />
                                    ) : (
                                      <AvField
                                        name="expiry_date"
                                        label={props.t("Expiry Date") + " *"}
                                        placeholder={props.t(
                                          "Select Expiry Date"
                                        )}
                                        value={
                                          selectedBatch.expiry_date !== null
                                            ? moment(
                                                new Date(
                                                  selectedBatch.expiry_date.substring(
                                                    6,
                                                    10
                                                  ),
                                                  +selectedBatch.expiry_date.substring(
                                                    3,
                                                    5
                                                  ) - 1,
                                                  selectedBatch.expiry_date.substring(
                                                    0,
                                                    2
                                                  )
                                                )
                                              ).format("YYYY-MM-DD")
                                            : ""
                                        }
                                        type="date"
                                        min={currentDate}
                                        required
                                        errorMessage="Expiry date cannot be empty"
                                      />
                                    )}
                                  </Col>
                                  <Col lg={5}>
                                    <br />
                                    <br />
                                    <div class="form-check">
                                      <input
                                        type="checkbox"
                                        name="is_no_expiry"
                                        className="is-untouched is-pristine av-valid form-check-input"
                                        checked={isNoExpiry}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setIsNoExpiry(true);
                                          } else {
                                            setIsNoExpiry(false);
                                          }
                                        }}
                                      />
                                      <label for="select_all">
                                        {" "}
                                        {props.t("There is no expiry date.")}
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={6}>
                                <AvField
                                  name="cost_price"
                                  label={props.t("Cost Price") + " *"}
                                  placeholder={props.t("Enter Cost Price")}
                                  value={selectedBatch.cost_price}
                                  type="text"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: props.t(
                                        "Cost price cannot be empty"
                                      ),
                                    },
                                    pattern: {
                                      value: "^\\d+(\\.\\d{1,2})?$",
                                      errorMessage: props.t(
                                        "Only numbers and decimals upto two places are allowed."
                                      ),
                                    },
                                  }}
                                />
                              </Col>
                              {selectedProductDetails &&
                                selectedProductDetails.is_for_sale && (
                                  <Col lg={6}>
                                    <AvField
                                      name="selling_price"
                                      label={props.t("Selling Price") + " *"}
                                      placeholder={props.t(
                                        "Enter Selling Price"
                                      )}
                                      value={selectedBatch.selling_price}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: forSale ? true : false,
                                          errorMessage: props.t(
                                            "Selling price cannot be empty"
                                          ),
                                        },
                                        pattern: {
                                          value: "^\\d+(\\.\\d{1,2})?$",
                                          errorMessage: props.t(
                                            "Only numbers and decimals upto two places are allowed."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                )}

                              <Col lg={6}>
                                <AvField
                                  name="remarks"
                                  label={props.t("Remarks")}
                                  value={selectedBatch.remarks}
                                  placeholder={props.t("Enter Remarks")}
                                  type="textarea"
                                />
                              </Col>

                              <Col lg={6}>
                                <AvField
                                  name="transaction_password"
                                  label={props.t("Transaction Password")}
                                  required
                                  errorMessage="Password cannot be empty."
                                  placeholder={props.t("Enter Password")}
                                  type="password"
                                />
                              </Col>
                              <Col lg={12}>
                                <hr />
                                <FormGroup className="mb-0 text-center">
                                  <div>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1"
                                    >
                                      {props.t("Update")}
                                    </Button>{" "}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ViewInventory))
);

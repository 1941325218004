import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const animatedComponents = makeAnimated();

const AllServices = (props) => {
  useEffect(() => {
    loadAllBeauticians();
  }, []);
  const [allBeauticians, setAllBeauticians] = useState([]);

  useEffect(() => {
    if (allBeauticians.length > 0) {
      loadServices();
      loadAllServiceCategories();
    }
  }, [allBeauticians]);
  const [allPrimaryBeauticians, setAllPrimaryBeauticians] = useState([]);
  const [allSecondaryBeauticians, setAllSecondaryBeauticians] = useState([]);
  const loadAllBeauticians = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/get_beauticians";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllBeauticians((oldArray) => [...response.data]);
      setAllPrimaryBeauticians((oldArray) => [...response.data]);
      setAllSecondaryBeauticians((oldArray) => [...response.data]);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allServices, setAllServices] = useState([]);
  const loadServices = async () => {
    let url = process.env.REACT_APP_BASEURL + "services/get_grouped"; //"services/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServices(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  function showPrimaryBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.primary_beautician_list.map((beautician) => {
          return (
            <>
              <span
                className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                title={props.t("Mobile:") + " " + beautician.mobile}
              >
                {beautician.name}
              </span>
            </>
          );
        })}
      </>
    );
  }
  function showSecondaryBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.secondary_beautician_list.map((beautician) => {
          return (
            <>
              <span
                class="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                title={props.t("Mobile:") + " " + beautician.mobile}
              >
                {beautician.name}
              </span>
            </>
          );
        })}
      </>
    );
  }
  const [editModal, setEditModal] = useState();
  const [selectedService, setSelectedService] = useState();
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit font-size-15"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedService(row);
            setSelectedCategoryID(row.service_category_details.service_id);
            setSelectedCategoryName(row.service_category_details.name);

            setSelectedPrimaryBeauticians(row.primary_beautician_list);
            setSelectedSecondaryBeauticians(row.secondary_beautician_list);
            setIsCostUpdatable(row.is_cost_updatable);

            setAllPrimaryBeauticians(
              allBeauticians.slice().filter((objFromA) => {
                return !row.secondary_beautician_list.find((objFromB) => {
                  return objFromA._id === objFromB._id;
                });
              })
            );
            setAllSecondaryBeauticians(
              allBeauticians.slice().filter((objFromA) => {
                return !row.primary_beautician_list.find((objFromB) => {
                  return objFromA._id === objFromB._id;
                });
              })
            );

            setEditModal(!editModal);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "duration",
      text: props.t("Duration (minutes)"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "service_category_details.name",
      text: props.t("Service Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Primary Beauticians"),
      formatter: showPrimaryBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Secondary Beauticians"),
      formatter: showSecondaryBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Category Name"),
      dataField: "category_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "total_services",
      text: props.t("Total Services"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      style: { cursor: "pointer" },
    },
  ];
  //Update related functions
  const [selectedCategoryID, setSelectedCategoryID] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [allServiceCategories, setAllServiceCategories] = useState();
  const loadAllServiceCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServiceCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [isNewCategoryModal, setNewCategoryModal] = useState();
  const handleAddNewCategory = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllServiceCategories();
      setSelectedCategoryID(response.data._id);
      setSelectedCategoryName(response.data.name);
      setNewCategoryModal(!isNewCategoryModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [isCostUpdatable, setIsCostUpdatable] = useState(false);
  const [isDurationUpdatable, setIsDurationUpdatable] = useState(false);
  const handleValidUpdate = async (e, v) => {
    try {
      if (selectedPrimaryBeauticians && selectedPrimaryBeauticians.length > 0) {
        let data_to_send = {};
        data_to_send.is_cost_updatable = isCostUpdatable;
        data_to_send.service_category_details = {
          service_id: selectedCategoryID,
          name: selectedCategoryName,
        };
        data_to_send.name = v.name;
        data_to_send.cost = v.cost;
        data_to_send.duration = v.duration;
        data_to_send.is_duration_updatable = isDurationUpdatable;

        data_to_send.primary_beautician_list =
          selectedPrimaryBeauticians.slice();
        data_to_send.secondary_beautician_list =
          selectedSecondaryBeauticians.slice();

        data_to_send.remarks = v.remarks;
        data_to_send.id = selectedService._id;

        let url = process.env.REACT_APP_BASEURL + "services/update";
        const response = await postSubmitForm(url, data_to_send);
        if (response && response.status === 1) {
          loadServices();
          loadAllBeauticians();
          setEditModal(!editModal);
          showNotification(response.message, "Success");
        } else {
          showNotification(response.message, "Error");
        }
        setSelectedPrimaryBeauticians([]);
        setSelectedSecondaryBeauticians([]);
      } else {
        showNotification("Select at least one primary beautician!", "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };

  const [selectedPrimaryBeauticians, setSelectedPrimaryBeauticians] = useState(
    []
  );
  const [selectedSecondaryBeauticians, setSelectedSecondaryBeauticians] =
    useState([]);

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.services}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };

  const handleDownload = async () => {
    const fileName = "services";
    const exportType = "xls";
    let data_to_export = [];
    let url = process.env.REACT_APP_BASEURL + "services/getall"; //"services/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      data_to_export = response.data;
    } else {
      showNotification(response.message, "Error");
    }
    //console.log(data_to_export);
    if (data_to_export.length > 0) {
      data_to_export.forEach(function (v) {
        v.name = v.name;
        console.log(v);
        v.is_cost_updatable = v.is_cost_updatable
          ? props.t("Yes")
          : props.t("No");
        v.is_duration_updatable = v.is_duration_updatable
          ? props.t("Yes")
          : props.t("No");

        v.category = v.service_category_details.name;
        let primary_beauticians = "";
        for (let i = 0; i < v.primary_beautician_list.length; i++) {
          primary_beauticians += v.primary_beautician_list[i].label;
          if (i < v.primary_beautician_list.length - 1) {
            primary_beauticians += ", ";
          }
        }
        let secondary_beauticians = "";
        for (let i = 0; i < v.secondary_beautician_list.length; i++) {
          secondary_beauticians += v.secondary_beautician_list[i].label;
          if (i < v.secondary_beautician_list.length - 1) {
            secondary_beauticians += ", ";
          }
        }
        v.primary_beauticians = primary_beauticians;
        v.secondary_beauticians = secondary_beauticians;
        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.service_category_details;
        delete v.primary_beautician_list;
        delete v.secondary_beautician_list;
      });
      var data = JSON.parse(JSON.stringify(data_to_export));
      //console.log(data_to_export);
      exportFromJSON({ data, fileName, exportType });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Services")}
            breadcrumbItem={props.t("All Services")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Services")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing services here")}
                  </CardSubtitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allServices && allServices}
                    columns={columns_external}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                    expandRow={expandRow}
                  />
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      Service Name: {selectedService && selectedService.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedService && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={8}>
                                            {" "}
                                            <AvField
                                              name="service_categories"
                                              label={props.t("Category") + " *"}
                                              type="select"
                                              value={selectedCategoryID}
                                              onChange={(e, v) => {
                                                const selectedIndex =
                                                  e.target.options
                                                    .selectedIndex;
                                                setSelectedCategoryID(
                                                  e.target.value
                                                );
                                                setSelectedCategoryName(
                                                  e.target.options[
                                                    selectedIndex
                                                  ].getAttribute("name")
                                                );
                                              }}
                                              required
                                              errorMessage={props.t(
                                                "Select Category"
                                              )}
                                            >
                                              <option value="" name="">
                                                -- {props.t("Select Category")}{" "}
                                                --
                                              </option>
                                              {allServiceCategories &&
                                                allServiceCategories.map(
                                                  (item) => {
                                                    return (
                                                      <option
                                                        value={item._id}
                                                        name={item.name}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewCategoryModal(
                                                  !isNewCategoryModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              Add New
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name") + " *"}
                                          placeholder={props.t("Enter Name")}
                                          value={selectedService.name}
                                          type="text"
                                          errorMessage="Name cannot be empty."
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={6}>
                                            <Label>
                                              {props.t("Is Price Updatable?") +
                                                " *"}
                                            </Label>
                                            <AvRadioGroup
                                              inline
                                              name="is_cost_updatable"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Select an option."
                                              )}
                                              value={
                                                selectedService.is_cost_updatable
                                                  ? "true"
                                                  : "false"
                                              }
                                            >
                                              <AvRadio
                                                label={props.t("Yes")}
                                                value="true"
                                                onChange={(e, v) => {
                                                  setIsCostUpdatable(true);
                                                }}
                                              />

                                              <AvRadio
                                                label={props.t("No")}
                                                value="false"
                                                onChange={(e, v) => {
                                                  setIsCostUpdatable(false);
                                                }}
                                              />
                                            </AvRadioGroup>
                                          </Col>
                                          <Col lg={6}>
                                            <AvField
                                              name="cost"
                                              label={props.t("Price") + " *"}
                                              value={selectedService.cost}
                                              placeholder={props.t(
                                                "Enter Price"
                                              )}
                                              errorMessage="Price cannot be empty."
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                                pattern: {
                                                  value: "^\\d+(\\.\\d{1,2})?$",
                                                  errorMessage: props.t(
                                                    "Only numbers and decimals upto two places are allowed."
                                                  ),
                                                },
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={6}>
                                            <Label>
                                              {props.t(
                                                "Is Duration Updatable?"
                                              ) + " *"}
                                            </Label>
                                            <AvRadioGroup
                                              inline
                                              name="is_duration_updatable"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Select an option."
                                              )}
                                              value={
                                                selectedService.is_duration_updatable
                                                  ? "true"
                                                  : "false"
                                              }
                                            >
                                              <AvRadio
                                                label={props.t("Yes")}
                                                value="true"
                                                onChange={(e, v) => {
                                                  setIsDurationUpdatable(true);
                                                }}
                                              />

                                              <AvRadio
                                                label={props.t("No")}
                                                value="false"
                                                onChange={(e, v) => {
                                                  setIsDurationUpdatable(false);
                                                }}
                                              />
                                            </AvRadioGroup>
                                          </Col>
                                          <Col lg={6}>
                                            {" "}
                                            <AvField
                                              name="duration"
                                              value={selectedService.duration}
                                              label={
                                                props.t("Duration (minutes)") +
                                                " *"
                                              }
                                              placeholder={props.t(
                                                "Enter Duration"
                                              )}
                                              errorMessage="Duration cannot be empty."
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                                pattern: {
                                                  value: "^[0-9]+$",
                                                  errorMessage: props.t(
                                                    "Only numbers are allowed."
                                                  ),
                                                },
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={6}>
                                        <Label>
                                          {props.t("Primary Beauticians")}
                                        </Label>
                                        <Select
                                          value={selectedPrimaryBeauticians}
                                          isMulti={true}
                                          onChange={(selected) => {
                                            if (
                                              selected &&
                                              selected.length > 0
                                            ) {
                                              const newArray = allBeauticians
                                                .slice()
                                                .filter((objFromA) => {
                                                  return !selected.find(
                                                    (objFromB) => {
                                                      return (
                                                        objFromA._id ===
                                                        objFromB._id
                                                      );
                                                    }
                                                  );
                                                });

                                              setAllSecondaryBeauticians(
                                                newArray
                                              );
                                            } else {
                                              setAllSecondaryBeauticians(
                                                allBeauticians.slice()
                                              );
                                            }
                                            setSelectedPrimaryBeauticians(
                                              selected
                                            );
                                          }}
                                          options={allPrimaryBeauticians}
                                          classNamePrefix="select2-selection"
                                          closeMenuOnSelect={false}
                                          components={animatedComponents}
                                          required
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Label>
                                          {props.t("Secondary Beauticians")}
                                        </Label>
                                        <Select
                                          value={selectedSecondaryBeauticians}
                                          isMulti={true}
                                          onChange={(selected) => {
                                            if (
                                              selected &&
                                              selected.length > 0
                                            ) {
                                              const newArray = allBeauticians
                                                .slice()
                                                .filter((objFromA) => {
                                                  return !selected.find(
                                                    (objFromB) => {
                                                      return (
                                                        objFromA._id ===
                                                        objFromB._id
                                                      );
                                                    }
                                                  );
                                                });
                                              setAllPrimaryBeauticians(
                                                newArray
                                              );
                                            } else {
                                              setAllPrimaryBeauticians(
                                                allBeauticians.slice()
                                              );
                                            }
                                            setSelectedSecondaryBeauticians(
                                              selected
                                            );
                                          }}
                                          options={allSecondaryBeauticians}
                                          classNamePrefix="select2-selection"
                                          closeMenuOnSelect={false}
                                          components={animatedComponents}
                                          required
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <br />
                                        <AvField
                                          name="remarks"
                                          value={selectedService.remarks}
                                          label={props.t("Remarks")}
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={isNewCategoryModal}
                    toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                    >
                      Add New Service Category
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category Name") + " *"}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      errorMessage="Category name cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <AvField
                                      name="remarks"
                                      label={props.t("Remarks")}
                                      placeholder={props.t("Enter Remarks")}
                                      type="textarea"
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AllServices))
);

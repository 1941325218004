import React, { useState } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import megamenuImg from "../../assets/images/megamenu-img.png";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

//i18n
import { withNamespaces } from "react-i18next";

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoLight}
                    alt=""
                    height="40"
                    style={{ borderRadius: "5px" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            {localStorage.getItem("role") !== "beautician" && (
              <>
                <Dropdown
                  //className="dropdown-mega d-none d-lg-block ms-2"
                  className="dropdown-mega ms-2"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    caret
                    tag="button"
                  >
                    {" "}
                    <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                    {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col sm={9}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Appointments")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/appointments_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Appointments Report")}</span>
                                </Link>
                              </li>
                            </ul>

                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Accounts")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/accounts_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Accounts Report")}</span>
                                </Link>
                              </li>
                            </ul>

                            <h5 className="font-size-14 mt-0">
                              <u>{props.t("Employee")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/employee_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Employee Report")}</span>
                                </Link>
                              </li>
                            </ul>
                          </Col>

                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u>{props.t("Charity")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/charity_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Charity Report")}</span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Client Loyalty")} </u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/loyalty_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Client Loyalty Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Closure")} </u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/closure_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Closure Report")}</span>
                                </Link>
                              </li>
                            </ul>
                          </Col>

                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u>{props.t("Expiry")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/expired_products_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Expired Products Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/expiring_products_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Expiring Products Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/wasted_products_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Wasted Products Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u>{props.t("Inventory")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/inventory_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Inventory Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/inventory_transfer_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Inventory Transfer Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Sales")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/service_sales_chart">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Service & Sales Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/service_sales_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Service Sales Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/product_sales_report">
                                  {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Product Sales Report")}</span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <Row>
                          <Col sm={6}></Col>

                          <Col sm={5}>
                            <div>
                              <img
                                src={megamenuImg}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Update Incentives"
                  >
                    <Link to="/arrear_deduction">
                      <i className="mdi mdi-account-cash"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Submit Charity"
                  >
                    <Link to="/charity_report">
                      <i className="mdi mdi-charity"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Submit Closure"
                  >
                    <Link to="/submit_closure">
                      <i className="bx bxs-report"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="App Settings"
                  >
                    <Link to="/app_settings">
                      <i className="bx bx-wrench"></i>
                    </Link>
                  </button>
                </div>
              </>
            )}
            {localStorage.getItem("role") === "manager" ? (
              <>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Scheduler"
                  >
                    <Link to="/appointments">
                      <i className="bx bx-timer"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Check-out"
                  >
                    <Link to="/ongoing_appointments">
                      <i className="bx bxs-user-check"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="All Appointments"
                  >
                    <Link to="/all_appointments">
                      <i className="bx bx-list-ul"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Wallet Transactions"
                  >
                    <Link to="/wallet_transaction">
                      <i className="bx bx-wallet"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Add Expense"
                  >
                    <Link to="/add_expense">
                      <i className="mdi mdi-cash-minus"></i>
                    </Link>
                  </button>
                </div>
              </>
            ) : null}
          </div>

          <div className="d-flex">
            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withNamespaces()(Header));

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import Moment from "react-moment";
import confirmCkeckInImage from "../../assets/images/checkinConfirm.png";
import SweetAlert from "react-bootstrap-sweetalert";
import Draggable from "react-draggable";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Button,
} from "reactstrap";
import swal from "sweetalert2";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { postSubmitForm } from "../../helpers/forms_helper";
import chroma from "chroma-js";
import moment from "moment";
const animatedComponents = makeAnimated();
const AddAppointment = (props) => {
  useEffect(() => {
    loadShift();
    loadAllBeauticians();
    loadClients();
    loadServices();
    // let date_new = new Date();
    // console.log(moment(date_new).add(30, "minutes").toDate());
  }, []);
  function preloader(status) {
    console.log(status);
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const [isHomeService, setIsHomeService] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [appointmentStartTime, setAppointmentStartTime] = useState();
  const [serviceStartTime, setServiceStartTime] = useState();
  const [appointmentEndTime, setAppointmentEndTime] = useState();
  const [appointmentModal, setAppointmentModal] = useState();
  const [remarks, setRemarks] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedAppointmentID, setSelectedAppointmentID] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedService, setSelectedService] = useState();
  const [selectedBeauticiansForService, setSelectedBeauticiansForService] =
    useState([]);
  const [isInProcess, setIsInProcess] = useState();
  const [transactionPassword, setTransactionPassword] = useState();
  const [transactionPasswordModal, setTransactionPasswordModal] = useState();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(
    moment().add("day", 1).format("YYYY-MM-DD")
  );

  //#region Page load functions begin
  const [selectedBeauticians, setSelectedBeauticians] = useState([]);
  const [allBeauticians, setAllBeauticians] = useState();
  const loadAllBeauticians = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/get_beauticians";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      var all_beauticians_id = [];
      response.data.map((beautician) => {
        all_beauticians_id.push(beautician._id);
        beautician.is_checked = true;
      });
      setAllBeauticians(response.data);
      setSelectedBeauticians(all_beauticians_id);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allServices, setAllServices] = useState();
  //const [allServicesToBind, setAllServicesToBind] = useState();
  const loadServices = async () => {
    let url = process.env.REACT_APP_BASEURL + "services/get_for_appointment";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServices(response.data);
      //setAllServicesToBind(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const loadClients = async () => {
    let url = process.env.REACT_APP_BASEURL + "clients/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllClients(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [shift, setShift] = useState();
  const loadShift = async () => {
    let url = process.env.REACT_APP_BASEURL + "shifts/getactive";
    const response = await postSubmitForm(url, null);
    if (response && response.status === 1) {
      setShift(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [appointments, setAppointments] = useState([]);
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const loadAppointments = async () => {
    preloader(true);
    let url =
      process.env.REACT_APP_BASEURL +
      "appointments/get_by_beauticians_and_date";
    const response = await postSubmitForm(url, {
      beauticians: selectedBeauticians,
      by_token: props.readOnly,
      from_date: fromDate,
      to_date: toDate,
    });
    if (response && response.status === 1) {
      let array_new = response.data;
      array_new.map((event) => {
        if (event.status === "Completed") {
          event.backgroundColor = "#b0ff9e"; //getRandomColor();
        } else if (event.status === "Booked") {
          event.backgroundColor = "#9ec3ff";
        } else if (event.status === "In Process") {
          event.backgroundColor = "#fffd9e";
        } else if (event.status === "Cancelled") {
          event.backgroundColor = "#ff9e9e";
        }
      });
      setAppointments(JSON.parse(JSON.stringify(array_new)));
      preloader(false);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  //#endregion Page load functions

  //#region Add new client popup functions
  const [newClientModal, setNewClientModal] = useState();
  const handleAddNewClient = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "clients/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadClients();
      response.data.label =
        response.data.name + " (Mobile: " + response.data.mobile + ")";
      response.data.value = response.data.mobile;
      setSelectedClient(response.data);
      setNewClientModal(false);
    } else {
      showNotification(response.message, "Error");
    }
  };
  //#endregion Add new client popup functions

  //#region Services for appointment
  const [servicesForAppointment, setServicesForAppointment] = useState([]);
  const getEndTime = (inputTime, duration, type) => {
    if (type === "add") {
      return moment(inputTime).add(duration, "minutes").toDate();
    } else if (type === "subtract") {
      return moment(inputTime).subtract(duration, "minutes").toDate();
    } else {
      return inputTime;
    }
  };
  const handleAddServiceForAppointment = async () => {
    var new_array = JSON.parse(JSON.stringify(servicesForAppointment));
    const existing_service = new_array.filter(function (service) {
      return service.name === selectedService.label;
    });
    if (existing_service.length > 0) {
      showNotification(
        props.t("This service is already added."),
        props.t("Error")
      );
    } else if (selectedBeauticiansForService.length <= 0) {
      showNotification(props.t("Please select beauticians."), props.t("Error"));
    } else {
      const beauticians = [];
      for (var i = 0; i < selectedBeauticiansForService.length; i++) {
        beauticians.push({
          beautician_id: selectedBeauticiansForService[i]._id,
          name: selectedBeauticiansForService[i].name,
          type: selectedBeauticiansForService[i].type,
          mobile: selectedBeauticiansForService[i].mobile,
          label: selectedBeauticiansForService[i].label,
          value: selectedBeauticiansForService[i].value,
          color: selectedBeauticiansForService[i].color,
        });
      }

      var service_start_time = new Date(serviceStartTime);
      let service_end_time = getEndTime(
        service_start_time,
        selectedService.duration,
        "add"
      );

      let services_for_appointment = [
        ...servicesForAppointment,
        {
          service_id: selectedService.value,
          name: selectedService.label,
          duration: selectedService.duration,
          cost: selectedService.cost,
          is_duration_updatable: selectedService.is_duration_updatable,
          beautician_details: beauticians,
          start_time: service_start_time,
          end_time: service_end_time,
        },
      ];

      setServicesForAppointment(services_for_appointment);
      setAppointmentEndTime(
        new Date(
          Math.max(...services_for_appointment.map((e) => new Date(e.end_time)))
        )
      );
      setServiceStartTime(
        moment(
          new Date(
            Math.max(
              ...services_for_appointment.map((e) => new Date(e.end_time))
            )
          )
        ).format("YYYY-MM-DDTHH:mm")
      );
      // var new_array = JSON.parse(JSON.stringify(allServicesToBind));
      // new_array.forEach(function (service) {
      //   service.options = service.options.filter(function (service_inner) {
      //     return service_inner.label != selectedService.label;
      //   });
      // });

      // setAllServicesToBind(new_array);

      setSelectedBeauticiansForService([]);
      setSelectedService();
    }
  };
  function showBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.beautician_details.map((beautician) => {
          return (
            <>
              {beautician.type === "Primary" ? (
                <span
                  className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              ) : (
                <span
                  class="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              )}
            </>
          );
        })}
      </>
    );
  }
  const [
    selectedServiceForAppointmentEdit,
    setSelectedServiceForAppointmentEdit,
  ] = useState();
  const [
    selectedBeauticiansForServiceEdit,
    setSelectedBeauticiansForServiceEdit,
  ] = useState([]);
  const [allBeauticiansForServiceEdit, setAllBeauticiansForServiceEdit] =
    useState([]);
  const [editServiceForAppointmentModal, setEditServiceForAppointmentModal] =
    useState();
  const handleUpdateServiceForAppointment = async (e, v) => {
    var new_array_beauticians = JSON.parse(
      JSON.stringify(selectedBeauticiansForServiceEdit)
    );
    for (var j = 0; j < new_array_beauticians.length; j++) {
      if (new_array_beauticians[j].beautician_id) {
        delete new_array_beauticians[j]._id;
      } else {
        new_array_beauticians[j].beautician_id = new_array_beauticians[j]._id;
        delete new_array_beauticians[j]._id;
      }
    }

    let oldDuration = selectedServiceForAppointmentEdit.duration;

    let service_start_time = new Date(v.start_time);
    let service_end_time = getEndTime(service_start_time, v.duration, "add");

    var new_array_services = JSON.parse(JSON.stringify(servicesForAppointment));
    for (var i = 0; i < new_array_services.length; i++) {
      if (
        new_array_services[i].name === selectedServiceForAppointmentEdit.name
      ) {
        new_array_services[i].duration = v.duration;
        new_array_services[i].cost = v.cost;
        new_array_services[i].beautician_details = new_array_beauticians;
        new_array_services[i].start_time = service_start_time;
        new_array_services[i].end_time = service_end_time;
        break;
      }
    }
    setAppointmentEndTime(
      new Date(Math.max(...new_array_services.map((e) => new Date(e.end_time))))
    );
    setServiceStartTime(
      moment(
        new Date(
          Math.max(...new_array_services.map((e) => new Date(e.end_time)))
        )
      ).format("YYYY-MM-DDTHH:mm")
    );
    setServicesForAppointment(new_array_services);
    setEditServiceForAppointmentModal(!editServiceForAppointmentModal);
    // setAppointmentEndTime(
    //   getEndTime(appointmentEndTime, v.duration - oldDuration, "add")
    // );
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (selectedAppointment &&
      (selectedAppointment.status === "Booked" ||
        selectedAppointment.status === "In Process")) ||
      !selectedAppointment ? (
      <>
        <span className="text-warning mr-2">
          <i
            className="bx bx-edit font-size-15"
            title="Click to Edit"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              setSelectedServiceForAppointmentEdit(row);
              setSelectedBeauticiansForServiceEdit(row.beautician_details);
              let url =
                process.env.REACT_APP_BASEURL +
                "services/get_beauticians_by_service_id";
              const response = await postSubmitForm(url, {
                service_id: row.service_id,
              });
              if (response && response.status === 1) {
                let primary_beautician_list =
                  response.data[0].primary_beautician_list;
                primary_beautician_list.map((beaut) => {
                  beaut.color = "#34c38f";
                  beaut.type = "Primary";
                });
                let secondary_beautician_list =
                  response.data[0].secondary_beautician_list;
                secondary_beautician_list.map((beaut) => {
                  beaut.color = "#50a5f1";
                  beaut.type = "Secondary";
                });
                setAllBeauticiansForServiceEdit(
                  primary_beautician_list.concat(secondary_beautician_list)
                );
              } else {
                showNotification(response.message, "Error");
              }
              setEditServiceForAppointmentModal(true);
            }}
          ></i>
        </span>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter((item) => item.name !== row.name);
              setAppointmentEndTime(
                new Date(Math.max(...arr.map((e) => new Date(e.end_time))))
              );
              setServiceStartTime(
                moment(
                  new Date(Math.max(...arr.map((e) => new Date(e.end_time))))
                ).format("YYYY-MM-DDTHH:mm")
              );
              // setAppointmentEndTime(
              //   getEndTime(appointmentEndTime, row.duration, "subtract")
              // );
              setServicesForAppointment(arr);
            }}
          ></i>
        </span>
      </>
    ) : null;
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Service"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "start_time",
      text: props.t("Time"),
      sort: true,
      formatter: (cell, row) => {
        return (
          <span className="text-info font-weight-bold">
            <Moment format="hh:mm a">{row.start_time}</Moment>
            {" - "}
            <Moment format="hh:mm a">{row.end_time}</Moment>
          </span>
        );
      },
      sortFunc: (a, b, order, dataField) => {
        if (order === "asc") return Date.parse(a) - Date.parse(b);
        // asc
        else return Date.parse(b) - Date.parse(a); // asc
      },
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "duration",
      text: props.t("Dur."),
      sort: false,
      formatter: (cell, row) => {
        return <>{row.duration + props.t(" min.")}</>;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Beauticians"),
      formatter: showBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "32%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: servicesForAppointment,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const defaultSorted = [
    {
      dataField: "start_time",
      order: "asc",
    },
  ];
  //#endregion Services for appointment

  useEffect(() => {
    loadAppointments();
  }, [selectedBeauticians, fromDate, toDate]);

  //#region Submit, Update, Cancel and check in Appointment
  const handleSubmitAppointment = async (e, v) => {
    if (servicesForAppointment.length > 0) {
      preloader(true);
      //console.log(appointmentStartTime);
      const data_to_send = {
        start: new Date(appointmentStartTime), //moment(appointmentStartTime).format("YYYY-MM-DD HH:mm"),
        end: appointmentEndTime, //moment(appointmentEndTime).format("YYYY-MM-DD HH:mm"),
        client_id: selectedClient._id,
        client_name: selectedClient.name,
        client_mobile: selectedClient.mobile,
        service_details: servicesForAppointment,
        remarks: remarks,
        is_homeservice: isHomeService,
      };

      let url = process.env.REACT_APP_BASEURL + "appointments/insert";

      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        preloader(false);
        loadAppointments();
        setAppointmentModal(false);
        showNotification(response.message, props.t("Success"));
      } else {
        preloader(false);
        showNotification(response.message, props.t("Error"));
      }
    } else {
      showNotification(
        props.t("Please select at least one service."),
        props.t("Error")
      );
    }
    // console.log(data_to_send);
  };
  const handleUpdateAppointment = async () => {
    if (servicesForAppointment.length > 0) {
      preloader(true);
      if (!isInProcess) {
        const data_to_send = {
          id: selectedAppointmentID,
          start: new Date(appointmentStartTime), //moment(appointmentStartTime).format("YYYY-MM-DD HH:mm"),
          end: appointmentEndTime, //moment(appointmentEndTime).format("YYYY-MM-DD HH:mm"),
          client_id: selectedClient._id,
          client_name: selectedClient.name,
          client_mobile: selectedClient.mobile,
          service_details: servicesForAppointment,
          remarks: remarks,
          is_homeservice: isHomeService,
        };
        let url = process.env.REACT_APP_BASEURL + "appointments/update";
        const response = await postSubmitForm(url, data_to_send);
        if (response && response.status === 1) {
          preloader(false);
          loadAppointments();
          setAppointmentModal(false);
          showNotification(response.message, "Success");
        } else {
          preloader(false);
          showNotification(response.message, "Error");
        }
      } else if (isInProcess && transactionPassword) {
        setTransactionPasswordModal(!transactionPasswordModal);
        const data_to_send = {
          id: selectedAppointmentID,
          start: new Date(appointmentStartTime), //moment(appointmentStartTime).format("YYYY-MM-DD HH:mm"),
          end: appointmentEndTime, //moment(appointmentEndTime).format("YYYY-MM-DD HH:mm"),
          client_id: selectedClient._id,
          client_name: selectedClient.name,
          client_mobile: selectedClient.mobile,
          service_details: servicesForAppointment,
          remarks: remarks,
          is_homeservice: isHomeService,
          transaction_password: transactionPassword,
        };
        let url = process.env.REACT_APP_BASEURL + "appointments/update";
        const response = await postSubmitForm(url, data_to_send);
        if (response && response.status === 1) {
          preloader(false);
          loadAppointments();
          setAppointmentModal(false);
          showNotification(response.message, "Success");
        } else {
          preloader(false);
          showNotification(response.message, "Error");
        }
        setTransactionPassword();
      } else {
        preloader(false);
        setTransactionPasswordModal(!transactionPasswordModal);
      }
    } else {
      showNotification(
        props.t("Please select at least one service."),
        props.t("Error")
      );
    }
  };

  const [confirmCancel, setConfirmCancel] = useState();
  const handleCancelAppointment = async (appointmentID) => {
    let url = process.env.REACT_APP_BASEURL + "appointments/cancel";
    const response = await postSubmitForm(url, { id: appointmentID });
    if (response && response.status === 1) {
      loadAppointments();
      setAppointmentModal(false);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [confirmCheckIn, setConfirmCheckIn] = useState();
  const handleCheckInAppointment = async (appointmentID) => {
    let url = process.env.REACT_APP_BASEURL + "appointments/check_in";
    const response = await postSubmitForm(url, { id: appointmentID });
    if (response && response.status === 1) {
      loadAppointments();
      setAppointmentModal(false);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  //#endregion Submit and Update Appointment

  //#region notification
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  //#endregion notification

  // #region Beauticians dropdown style
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };
  //#endregion Beauticians dropdown style

  //#region Products for Sale to Show on Completed Appointments
  const [productsForSale, setProductsForSale] = useState([]);

  const columns_products = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Product Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "total_price",
      text: props.t("Total Amount"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  //#endregion Products for Sale to Show on Completed Appointments

  //#region  Calendar Events
  // Calendar Events Began ---------------------------------------------
  // const [calendarView, setCalendarView] = useState();
  // const handleViewMount = async (view_info) => {
  //   setCalendarView(view_info.view.type);
  // };
  // const handleDateClick = async (arg) => {
  //   if (arg.view.type === "timeGridDay") {
  //     console.log(arg.start);
  //     alert(arg.dateStr);
  //   }
  // };
  const handleSelectRange = async (selection_info) => {
    if (
      selection_info.view.type === "timeGridDay" ||
      selection_info.view.type === "timeGridWeek"
    ) {
      setSelectedSlot(selection_info);
      setAppointmentStartTime(
        moment(selection_info.start).format("YYYY-MM-DDTHH:mm")
      );
      setServiceStartTime(
        moment(selection_info.start).format("YYYY-MM-DDTHH:mm")
      );
      setAppointmentEndTime();
      setIsHomeService(false);
      setAppointmentModal(!appointmentModal);
      setServicesForAppointment([]);
      setSelectedClient();
      setSelectedAppointmentID();
      setSelectedAppointment();
      setRemarks("");
      setIsUpdate(false);
    }
  };
  const handleEventClick = async (info) => {
    //console.log(moment(info.event.start).format("YYYY-MM-DDTHH:mm"));

    // alert("Event: " + info.event.title);
    // alert("Coordinates: " + info.jsEvent.pageX + "," + info.jsEvent.pageY);
    // alert("View: " + info.view.type);
    setSelectedClient({
      _id: info.event.extendedProps.client_details.client_id,
      name: info.event.extendedProps.client_details.client_name,
      label:
        info.event.extendedProps.client_details.client_name +
        " (Mobile: " +
        info.event.extendedProps.client_details.client_mobile +
        ")",
      value: info.event.extendedProps.client_details.client_mobile,

      mobile: info.event.extendedProps.client_details.client_mobile,
    });
    setIsUpdate(true);
    info.event.extendedProps.status === "In Process"
      ? setIsInProcess(true)
      : setIsInProcess(false);
    setServicesForAppointment(info.event.extendedProps.service_details);
    setIsHomeService(info.event.extendedProps.is_homeservice);
    setProductsForSale(info.event.extendedProps.sold_product_details);
    setAppointmentStartTime(
      moment(info.event.start).format("YYYY-MM-DDTHH:mm")
    ); //setAppointmentStartTime(info.event.start);

    setServiceStartTime(moment(info.event.end).format("YYYY-MM-DDTHH:mm"));
    setAppointmentEndTime(info.event.end);
    setRemarks(info.event.extendedProps.remarks);
    setAppointmentModal(true);
    setSelectedAppointmentID(info.event.extendedProps._id);
    setSelectedAppointment(info.event.extendedProps);
    // console.log(info.event.start);
    // console.log(info.event.end);
    //console.log(info.event.extendedProps);
  };
  const handleDragDrop = async (info) => {
    if (info.event.extendedProps.status === "Booked") {
      const diff = moment(info.event.start).diff(
        moment(info.oldEvent.start),
        "minutes"
      );
      var new_array_services = JSON.parse(
        JSON.stringify(info.event.extendedProps.service_details)
      );
      for (var i = 0; i < new_array_services.length; i++) {
        new_array_services[i].start_time = getEndTime(
          new_array_services[i].start_time,
          diff,
          "add"
        );
        new_array_services[i].end_time = getEndTime(
          new_array_services[i].end_time,
          diff,
          "add"
        );
      }

      const data_to_send = {
        id: info.event.extendedProps._id,
        start: info.event.start, //moment(appointmentStartTime).format("YYYY-MM-DD HH:mm"),
        end: info.event.end, //moment(appointmentEndTime).format("YYYY-MM-DD HH:mm"),
        client_id: info.event.extendedProps.client_details.client_id,
        client_name: info.event.extendedProps.client_details.client_name,
        client_mobile: info.event.extendedProps.client_details.client_mobile,
        service_details: new_array_services, //info.event.extendedProps.service_details,
        remarks: info.event.extendedProps.remarks,
        is_homeservice: info.event.extendedProps.is_homeservice,
      };

      let url = process.env.REACT_APP_BASEURL + "appointments/update";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAppointments();
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      loadAppointments();
      showNotification(
        "Cannot update " + info.event.extendedProps.status + " appointment.",
        "Error"
      );
    }
  };
  const handleMouseEnterEvent = async (mouseEnterInfo) => {
    try {
      const make_content =
        "<b>" +
        mouseEnterInfo.event.start.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " - " +
        mouseEnterInfo.event.end.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        "</b>" +
        " (" +
        props.t(mouseEnterInfo.event.extendedProps.status) +
        ")" +
        "<br />" +
        mouseEnterInfo.event.extendedProps.client_details.client_name +
        mouseEnterInfo.event.extendedProps.service_details.map(function (
          service,
          index,
          arrayobj
        ) {
          return (
            "<br />" +
            (index + +1) +
            "/" +
            mouseEnterInfo.event.extendedProps.service_details.length +
            " " +
            service.name +
            " (" +
            service.beautician_details.map(function (beaut) {
              return " " + beaut.name;
            }) +
            " )"
          );
        });
      tippy(mouseEnterInfo.el, {
        content: make_content,
        allowHTML: true,
        theme: "light-border",
      });
      // console.log(mouseEnterInfo.event.title);
    } catch (error) {}
  };
  const handleMouseLeaveEvent = async (mouseLeaveInfo) => {
    // console.log(mouseLeaveInfo.event.title);
  };

  const handleDateSet = async (info) => {
    setFromDate(moment(info.startStr.substr(0, 10)).format("YYYY-MM-DD"));
    setToDate(moment(info.endStr.substr(0, 10)).format("YYYY-MM-DD"));
  };

  // const handleEventDidMount = async (info) => {
  //   //console.log(info);
  // };

  // Calendar Events End ---------------------------------------------
  //#endregion Calendar Events

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                {!props.readOnly && (
                  <Col lg={3}>
                    <AvForm>
                      <Label>{props.t("STAFF")}</Label>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="select_all"
                          className="is-untouched is-pristine av-valid form-check-input"
                          checked={isSelectAll}
                          onChange={(e) => {
                            if (e.target.checked) {
                              var new_array = JSON.parse(
                                JSON.stringify(allBeauticians)
                              );
                              var all_beauticians_id = [];
                              new_array.map((beautician) => {
                                all_beauticians_id.push(beautician._id);
                                beautician.is_checked = true;
                              });
                              setAllBeauticians([...new_array]);
                              setSelectedBeauticians(all_beauticians_id);
                              setIsSelectAll(true);
                            } else {
                              var new_array = JSON.parse(
                                JSON.stringify(allBeauticians)
                              );

                              new_array.map((beautician) => {
                                beautician.is_checked = false;
                              });
                              setAllBeauticians([...new_array]);
                              setSelectedBeauticians([]);
                              setIsSelectAll(false);
                            }
                          }}
                        />
                        <label for="select_all"> {props.t("All")}</label>
                      </div>
                      {allBeauticians &&
                        allBeauticians.map((beaut) => {
                          return (
                            <>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  className="is-untouched is-pristine av-valid form-check-input"
                                  key={beaut._id}
                                  name={beaut._id}
                                  // label={beaut.name}
                                  value={beaut._id}
                                  checked={beaut.is_checked}
                                  onChange={(e, v) => {
                                    var new_array = JSON.parse(
                                      JSON.stringify(allBeauticians)
                                    );
                                    new_array.map((beautician) => {
                                      if (beautician._id === e.target.name) {
                                        beautician.is_checked =
                                          e.target.checked;
                                      }
                                    });
                                    setAllBeauticians([...new_array]);
                                    if (e.target.checked) {
                                      setSelectedBeauticians([
                                        ...selectedBeauticians,
                                        e.target.name,
                                      ]);
                                      // console.log(e.target.name);
                                    } else {
                                      var filtered = selectedBeauticians.filter(
                                        function (value, index, arr) {
                                          return value !== e.target.name;
                                        }
                                      );
                                      setSelectedBeauticians(filtered);
                                    }
                                  }}
                                />
                                <label for={beaut._id}> {beaut.name}</label>
                              </div>
                            </>
                          );
                          // }
                        })}
                    </AvForm>
                  </Col>
                )}
                <Col lg={!props.readOnly ? 9 : 12}>
                  <FullCalendar
                    height="auto"
                    //viewClassNames={handleViewMount}
                    //eventColor="#926e6f"
                    slotEventOverlap={false}
                    navLinks={true}
                    nowIndicator={true}
                    businessHours={
                      shift &&
                      (shift.is_break
                        ? [
                            {
                              daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                              startTime: shift.in_time,
                              endTime: shift.break_start_time,
                            },
                            {
                              daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                              startTime: shift.break_end_time,
                              endTime: shift.out_time,
                            },
                          ]
                        : {
                            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                            startTime: shift.in_time,
                            endTime: shift.out_time,
                          })
                    }
                    datesSet={handleDateSet}
                    allDaySlot={false}
                    slotDuration="00:15:00"
                    slotMinTime={shift ? shift.in_time : "08:00"}
                    slotMaxTime={shift ? shift.out_time : "18:00"}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="timeGridDay"
                    events={appointments && appointments}
                    eventBackgroundColor="rgb(235 248 251)" //"#926e6f" //
                    eventBorderColor="red"
                    eventTextColor="#000000"
                    eventTimeFormat={{
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: "short",
                    }}
                    //eventDidMount={handleEventDidMount}
                    eventDisplay="block"
                    eventClick={
                      !props.readOnly ? handleEventClick : () => false
                    }
                    eventMouseEnter={handleMouseEnterEvent}
                    eventMouseLeave={handleMouseLeaveEvent}
                    //dateClick={handleDateClick}
                    editable={!props.readOnly ? true : false}
                    eventDurationEditable={false}
                    eventDrop={!props.readOnly ? handleDragDrop : () => false}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    selectable={!props.readOnly ? true : false}
                    select={!props.readOnly ? handleSelectRange : () => false}
                  />
                </Col>
              </Row>
              <Draggable>
                <Modal
                  size="lg"
                  style={{
                    width: "785px",
                  }}
                  isOpen={appointmentModal}
                  toggle={() => setAppointmentModal(!appointmentModal)}
                >
                  <ModalHeader
                    toggle={() => setAppointmentModal(!appointmentModal)}
                  >
                    {isUpdate && isUpdate
                      ? selectedAppointment &&
                        (selectedAppointment.status === "Booked" ||
                          selectedAppointment.status === "In Process")
                        ? props.t("Update Appointment")
                        : props.t("Appointment Details")
                      : props.t("Add New Appointment")}
                    <br />{" "}
                    {isUpdate && isUpdate ? (
                      <>
                        <span className="font-size-12 mr-1 mb-1">
                          {props.t("Status") + ": "}
                        </span>
                        <span className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1">
                          {selectedAppointment && selectedAppointment.status}
                        </span>
                      </>
                    ) : null}
                  </ModalHeader>
                  <ModalBody>
                    <>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              {(isUpdate &&
                                isUpdate &&
                                selectedAppointment &&
                                (selectedAppointment.status === "Booked" ||
                                  selectedAppointment.status ===
                                    "In Process")) ||
                              !isUpdate ? (
                                <AvForm
                                  onValidSubmit={(e, v) => {
                                    handleSubmitAppointment(e, v);
                                  }}
                                >
                                  <Row>
                                    <Col lg={4}>
                                      <Label>{props.t("Start Time")}</Label>
                                      <br />
                                      <AvField
                                        type="datetime-local"
                                        name="start_datetime"
                                        value={
                                          appointmentStartTime &&
                                          appointmentStartTime
                                        }
                                        onChange={(e, v) => {
                                          var starttime_old = new Date(
                                            appointmentStartTime
                                          );
                                          var starttime_new = new Date(v);

                                          var new_array_services = JSON.parse(
                                            JSON.stringify(
                                              servicesForAppointment
                                            )
                                          );
                                          for (
                                            var i = 0;
                                            i < new_array_services.length;
                                            i++
                                          ) {
                                            new_array_services[i].start_time =
                                              getEndTime(
                                                new_array_services[i]
                                                  .start_time,
                                                moment(starttime_new).diff(
                                                  moment(starttime_old),
                                                  "minutes"
                                                ),
                                                "add"
                                              );
                                            new_array_services[i].end_time =
                                              getEndTime(
                                                new_array_services[i].end_time,
                                                moment(starttime_new).diff(
                                                  moment(starttime_old),
                                                  "minutes"
                                                ),
                                                "add"
                                              );
                                          }
                                          setServicesForAppointment(
                                            new_array_services
                                          );
                                          // setAppointmentEndTime(
                                          //   getEndTime(
                                          //     appointmentEndTime,
                                          //     moment(starttime_new).diff(
                                          //       moment(starttime_old),
                                          //       "minutes"
                                          //     ),
                                          //     "add"
                                          //   )
                                          // );
                                          setAppointmentEndTime(
                                            new Date(
                                              Math.max(
                                                ...new_array_services.map(
                                                  (e) => new Date(e.end_time)
                                                )
                                              )
                                            )
                                          );
                                          setAppointmentStartTime(v);
                                        }}
                                      />
                                      {/* <span className="text-info font-weight-bold">
                                            <Moment format="DD/MM/YYYY hh:mm a">
                                              {appointmentStartTime &&
                                                appointmentStartTime}
                                            </Moment>
                                            {" - "}
                                            <Moment format="DD/MM/YYYY hh:mm a">
                                              {appointmentEndTime
                                                ? appointmentEndTime
                                                : selectedSlot &&
                                                  selectedSlot.end}
                                            </Moment>
                                          </span>
                                          <br />
                                          <br /> */}
                                    </Col>
                                    <Col lg={3}>
                                      <Label>{props.t("End Time")}</Label>
                                      <br />
                                      <span className="text-info font-weight-bold">
                                        <Moment format="MM/DD/YYYY hh:mm a">
                                          {appointmentEndTime
                                            ? appointmentEndTime
                                            : selectedSlot && selectedSlot.end}
                                        </Moment>
                                      </span>
                                      <br />
                                      <br />
                                    </Col>
                                    {isUpdate &&
                                    isUpdate &&
                                    selectedAppointment.status !==
                                      "In Process" ? (
                                      <>
                                        <Col lg={3}>
                                          <Label>&nbsp;</Label>
                                          <br />
                                          <Link
                                            onClick={() => {
                                              setConfirmCheckIn({
                                                status: true,
                                                appointmentID:
                                                  selectedAppointmentID,
                                              });
                                            }}
                                            className="btn btn-sm btn-info"
                                          >
                                            {props.t("Check-in appointment")}{" "}
                                          </Link>
                                        </Col>
                                        <Col lg={2}>
                                          <Label>&nbsp;</Label>
                                          <br />
                                          <Link
                                            onClick={() => {
                                              setConfirmCancel({
                                                status: true,
                                                appointmentID:
                                                  selectedAppointmentID,
                                              });
                                            }}
                                            className="btn btn-sm btn-danger"
                                          >
                                            <i className="mdi mdi-cancel"></i>{" "}
                                            {props.t("Cancel")}{" "}
                                          </Link>
                                        </Col>
                                      </>
                                    ) : (
                                      <Col lg={6}></Col>
                                    )}
                                    {isUpdate ? (
                                      <Col lg={12} md={12}>
                                        {" "}
                                        <Label>{props.t("Client")}</Label>
                                        <br />
                                        <span className="text-info font-weight-bold">
                                          {selectedClient &&
                                            selectedClient.name}
                                        </span>
                                      </Col>
                                    ) : (
                                      <>
                                        <Col lg={8} md={8}>
                                          {" "}
                                          <Label>{props.t("Client")}</Label>
                                          <Select
                                            value={selectedClient}
                                            onChange={(e) => {
                                              setSelectedClient(e);
                                            }}
                                            placeholder={props.t("Select...")}
                                            options={allClients}
                                            classNamePrefix="select2-selection"
                                            required
                                          />
                                        </Col>
                                        <Col lg={4} md={4}>
                                          {" "}
                                          <Label>&nbsp;</Label>
                                          <br />
                                          <Link
                                            class="btn btn-success waves-effect waves-light btn-sm"
                                            onClick={() => {
                                              setNewClientModal(
                                                !newClientModal
                                              );
                                            }}
                                          >
                                            <i class="mdi mdi-file-plus"></i>{" "}
                                            {props.t("Add New")}
                                          </Link>
                                        </Col>
                                      </>
                                    )}

                                    <Col lg={4} className="mb-4">
                                      <br />
                                      <Label>{props.t("Select Service")}</Label>
                                      <Select
                                        value={
                                          selectedService && selectedService
                                        }
                                        key={selectedService}
                                        onChange={(e) => {
                                          setSelectedService(e);
                                          setSelectedBeauticiansForService([]);
                                        }}
                                        options={allServices}
                                        classNamePrefix="select2-selection"
                                        required
                                      />
                                    </Col>

                                    {selectedService && (
                                      <>
                                        <Col lg={6} className="mb-4">
                                          <br />
                                          <Label>
                                            {props.t("Beauticians")}
                                          </Label>
                                          <Select
                                            value={
                                              selectedBeauticiansForService
                                            }
                                            isMulti={true}
                                            onChange={(selected) => {
                                              setSelectedBeauticiansForService(
                                                selected
                                              );
                                            }}
                                            options={
                                              //selectedService.beauticians

                                              selectedService.beauticians.filter(
                                                (o1) =>
                                                  allBeauticians.some(
                                                    (o2) => o1._id === o2._id
                                                  )
                                              )
                                            }
                                            styles={colourStyles}
                                            classNamePrefix="select2-selection"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            required
                                          />
                                        </Col>
                                        {/* <Col lg={5} className="mb-4">
                                          <AvField
                                            type="datetime-local"
                                            name="service_start_datetime"
                                            label={props.t(
                                              "Service Start Time"
                                            )}
                                            value={
                                              serviceStartTime &&
                                              serviceStartTime
                                            }
                                            onChange={(e, v) => {
                                              // var starttime_old = new Date(
                                              //   appointmentStartTime
                                              // );
                                              // var starttime_new = new Date(v);
                                              // setAppointmentEndTime(
                                              //   getEndTime(
                                              //     appointmentEndTime,
                                              //     moment(starttime_new).diff(
                                              //       moment(starttime_old),
                                              //       "minutes"
                                              //     ),
                                              //     "add"
                                              //   )
                                              // );
                                              setServiceStartTime(v);
                                            }}
                                          />
                                        </Col> */}
                                        <Col lg={2} className="mb-4">
                                          {" "}
                                          <Label>&nbsp;</Label>
                                          <br />
                                          <br />
                                          <Link
                                            class="btn btn-info waves-effect waves-light btn-sm"
                                            onClick={
                                              handleAddServiceForAppointment
                                            }
                                          >
                                            <i class="bx bx-add-to-queue"></i>{" "}
                                            {props.t("Add")}
                                          </Link>
                                        </Col>
                                      </>
                                    )}
                                    {servicesForAppointment &&
                                    servicesForAppointment.length > 0 ? (
                                      <Col lg={12}>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={
                                            servicesForAppointment &&
                                            servicesForAppointment
                                          }
                                          columns={columns}
                                          defaultSorted={defaultSorted}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                      </Col>
                                    ) : null}

                                    <Col lg={12}>
                                      <AvField
                                        name="remarks"
                                        value={remarks}
                                        onChange={(e) => {
                                          setRemarks(e.target.value);
                                        }}
                                        label={props.t("Remarks")}
                                        placeholder={props.t("Enter Remarks")}
                                        type="textarea"
                                      />
                                    </Col>
                                    <Col lg={12}>
                                      <div class="form-check">
                                        <input
                                          type="checkbox"
                                          name="is_homeservice"
                                          className="is-untouched is-pristine av-valid form-check-input"
                                          checked={isHomeService}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setIsHomeService(true);
                                            } else {
                                              setIsHomeService(false);
                                            }
                                          }}
                                        />
                                        <label for="select_all">
                                          {" "}
                                          {props.t("This is a home service.")}
                                        </label>
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <hr />
                                      {isUpdate && isUpdate ? (
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Link
                                              class="btn btn-primary waves-effect waves-light "
                                              onClick={handleUpdateAppointment}
                                            >
                                              {props.t("Update")}
                                            </Link>
                                          </div>
                                        </FormGroup>
                                      ) : (
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Submit")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      )}
                                    </Col>
                                  </Row>
                                </AvForm>
                              ) : (
                                <Row>
                                  <Col lg={8}>
                                    <Label>{props.t("Date & Time")}</Label>
                                    <br />
                                    <span className="text-info font-weight-bold">
                                      <Moment format="MM/DD/YYYY hh:mm a">
                                        {appointmentStartTime &&
                                          appointmentStartTime}
                                      </Moment>
                                      {" - "}
                                      <Moment format="MM/DD/YYYY hh:mm a">
                                        {appointmentEndTime
                                          ? appointmentEndTime
                                          : selectedSlot && selectedSlot.end}
                                      </Moment>
                                    </span>
                                    <br />
                                    <br />
                                  </Col>
                                  {selectedAppointment &&
                                  selectedAppointment.status === "Completed" ? (
                                    <Col lg={4}>
                                      <Label>{props.t("Invoice Number")}</Label>
                                      <br />
                                      <a
                                        href={
                                          selectedAppointment &&
                                          "/invoice?invoice=" +
                                            selectedAppointment.serviceandsale_id
                                        }
                                        target="_blank"
                                        style={{
                                          color: "blue",
                                          textDecorationLine: "underline",
                                        }}
                                      >
                                        {selectedAppointment &&
                                          selectedAppointment.invoice_number}
                                      </a>
                                    </Col>
                                  ) : null}

                                  <Col lg={12} md={12}>
                                    {" "}
                                    <Label>{props.t("Client")}</Label>
                                    <br />
                                    <span className="text-info font-weight-bold">
                                      {selectedClient && selectedClient.label}
                                    </span>
                                  </Col>
                                  <Col lg={12} md={12}>
                                    <br />
                                    <Label>{props.t("Services")}</Label>
                                  </Col>
                                  {servicesForAppointment &&
                                  servicesForAppointment.length > 0 ? (
                                    <Col lg={12}>
                                      <BootstrapTable
                                        bootstrap4
                                        keyField="name"
                                        key="name"
                                        data={
                                          servicesForAppointment &&
                                          servicesForAppointment
                                        }
                                        columns={columns}
                                        noDataIndication={props.t(
                                          "No data to display."
                                        )}
                                        striped
                                        hover
                                        condensed
                                      />
                                    </Col>
                                  ) : null}

                                  {selectedAppointment &&
                                  selectedAppointment.status !== "Cancelled" ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <Label>{props.t("Products")}</Label>
                                      </Col>
                                      {productsForSale &&
                                      productsForSale.length > 0 ? (
                                        <Col lg={12}>
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="name"
                                            key="name"
                                            data={
                                              productsForSale && productsForSale
                                            }
                                            columns={columns_products}
                                            noDataIndication={props.t(
                                              "No data to display."
                                            )}
                                            striped
                                            hover
                                            condensed
                                          />
                                        </Col>
                                      ) : null}
                                    </>
                                  ) : null}

                                  <Col lg={12}>
                                    <Label>{props.t("Remarks")}</Label>
                                    <br />
                                    <span className="text-info font-weight-bold">
                                      {selectedAppointment &&
                                        selectedAppointment.remarks}
                                    </span>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  </ModalBody>
                </Modal>
              </Draggable>
              <Modal
                size="md"
                isOpen={editServiceForAppointmentModal}
                toggle={() =>
                  setEditServiceForAppointmentModal(
                    !editServiceForAppointmentModal
                  )
                }
              >
                <ModalHeader
                  toggle={() =>
                    setEditServiceForAppointmentModal(
                      !editServiceForAppointmentModal
                    )
                  }
                >
                  {selectedServiceForAppointmentEdit &&
                    selectedServiceForAppointmentEdit.name}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <AvForm
                            onValidSubmit={(e, v) => {
                              handleUpdateServiceForAppointment(e, v);
                            }}
                          >
                            <Row>
                              <Col lg={12}>
                                <AvField
                                  type="datetime-local"
                                  name="start_time"
                                  labe={props.t("Start Time") + " *"}
                                  value={
                                    selectedServiceForAppointmentEdit &&
                                    moment(
                                      selectedServiceForAppointmentEdit.start_time
                                    ).format("YYYY-MM-DDTHH:mm")
                                  }
                                />
                              </Col>
                              <Col lg={12}>
                                <AvField
                                  name="cost"
                                  label={props.t("Price") + " *"}
                                  placeholder={props.t("Enter Service Price")}
                                  type="text"
                                  value={
                                    selectedServiceForAppointmentEdit &&
                                    selectedServiceForAppointmentEdit.cost
                                  }
                                  // disabled={
                                  //   selectedServiceForAppointmentEdit &&
                                  //   !selectedServiceForAppointmentEdit.is_cost_updatable
                                  // }
                                  // title={
                                  //   selectedServiceForAppointmentEdit &&
                                  //   selectedServiceForAppointmentEdit.is_cost_updatable
                                  //     ? props.t("Edit price")
                                  //     : props.t(
                                  //         "Cannot edit price of this service."
                                  //       )
                                  // }
                                  errorMessage="Price cannot be empty."
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^\\d+(\\.\\d{1,2})?$",
                                      errorMessage: props.t(
                                        "Only numbers and decimals upto two places are allowed."
                                      ),
                                    },
                                  }}
                                />
                              </Col>
                              <Col lg={12}>
                                <AvField
                                  name="duration"
                                  label={props.t("Duration (minutes)") + " *"}
                                  placeholder={props.t(
                                    "Enter Service Duration"
                                  )}
                                  type="text"
                                  value={
                                    selectedServiceForAppointmentEdit &&
                                    selectedServiceForAppointmentEdit.duration
                                  }
                                  disabled={
                                    selectedServiceForAppointmentEdit &&
                                    !selectedServiceForAppointmentEdit.is_duration_updatable
                                  }
                                  title={
                                    selectedServiceForAppointmentEdit &&
                                    selectedServiceForAppointmentEdit.is_duration_updatable
                                      ? props.t("Edit duration")
                                      : props.t(
                                          "Cannot edit duration of this service."
                                        )
                                  }
                                  errorMessage="Duration cannot be empty."
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^\\d+(\\.\\d{1,2})?$",
                                      errorMessage: props.t(
                                        "Only numbers and decimals upto two places are allowed."
                                      ),
                                    },
                                  }}
                                />
                              </Col>
                              <Col lg={12}>
                                <Label>{props.t("Beauticians") + " *"}</Label>
                                <Select
                                  value={selectedBeauticiansForServiceEdit}
                                  isMulti={true}
                                  onChange={(selected) => {
                                    setSelectedBeauticiansForServiceEdit(
                                      selected
                                    );
                                  }}
                                  options={allBeauticiansForServiceEdit}
                                  styles={colourStyles}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  required
                                />
                              </Col>
                              <Col lg={12}>
                                <hr />
                                <FormGroup className="mb-0 text-center">
                                  <div>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1"
                                    >
                                      {props.t("Save")}
                                    </Button>{" "}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal
                size="md"
                isOpen={transactionPasswordModal}
                toggle={() =>
                  setTransactionPasswordModal(!transactionPasswordModal)
                }
              >
                <ModalHeader
                  toggle={() =>
                    setTransactionPasswordModal(!transactionPasswordModal)
                  }
                >
                  {props.t("Enter transaction password to update")}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <AvForm onValidSubmit={handleUpdateAppointment}>
                            <Row>
                              <Col lg={12}>
                                <AvField
                                  name="transaction_password"
                                  label={props.t("Transaction Password") + " *"}
                                  placeholder={props.t(
                                    "Enter Transaction Password"
                                  )}
                                  onChange={(e) => {
                                    setTransactionPassword(e.target.value);
                                  }}
                                  type="password"
                                  errorMessage="Password cannot be empty."
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </Col>
                              <Col lg={12}>
                                <hr />
                                <FormGroup className="mb-0 text-center">
                                  <div>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1"
                                    >
                                      {props.t("Submit & Update")}
                                    </Button>{" "}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal
                size="md"
                isOpen={newClientModal}
                toggle={() => setNewClientModal(!newClientModal)}
              >
                <ModalHeader toggle={() => setNewClientModal(!newClientModal)}>
                  {props.t("Add New Client")}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <AvForm
                            onValidSubmit={(e, v) => {
                              handleAddNewClient(e, v);
                            }}
                          >
                            <Row>
                              <Col lg={12}>
                                <AvField
                                  name="name"
                                  label={props.t("Name") + " *"}
                                  placeholder={props.t("Enter Client Name")}
                                  type="text"
                                  errorMessage="Client name cannot be empty."
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </Col>
                              <Col lg={12}>
                                <AvField
                                  name="mobile"
                                  label={props.t("Mobile") + " *"}
                                  placeholder={props.t("Enter Mobile Number")}
                                  type="text"
                                  errorMessage="Mobile number cannot be empty."
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </Col>
                              <Col lg={12}>
                                <hr />
                                <FormGroup className="mb-0 text-center">
                                  <div>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1"
                                    >
                                      {props.t("Submit")}
                                    </Button>{" "}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              {confirmCheckIn && confirmCheckIn.status ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  custom
                  showCancel
                  iconWidth="600"
                  iconHeight="600"
                  customIcon={confirmCkeckInImage}
                  cancelBtnText={props.t("No")}
                  confirmBtnText={props.t("Yes")}
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setConfirmCheckIn(false);
                    handleCheckInAppointment(confirmCheckIn.appointmentID);
                  }}
                  onCancel={() => {
                    setConfirmCheckIn(false);
                  }}
                >
                  {props.t(
                    "Once you check-in this appointment, it cannot be revoked."
                  )}
                </SweetAlert>
              ) : null}
              {confirmCancel && confirmCancel.status ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  cancelBtnText={props.t("No")}
                  confirmBtnText={props.t("Yes")}
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setConfirmCancel(false);
                    handleCancelAppointment(confirmCancel.appointmentID);
                  }}
                  onCancel={() => {
                    setConfirmCancel(false);
                  }}
                >
                  {props.t(
                    "Once you cancel this appointment, it cannot be revoked."
                  )}
                </SweetAlert>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddAppointment)));

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postSubmitForm } from "../../helpers/forms_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const AllShifts = (props) => {
  useEffect(() => {
    loadShifts();
  }, []);
  const [allShifts, setAllShifts] = useState([]);
  const loadShifts = async () => {
    let url = process.env.REACT_APP_BASEURL + "shifts/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllShifts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const handleShiftStatusUpdate = async (shift) => {
    let url = process.env.REACT_APP_BASEURL + "shifts/activate";
    console.log(shift);
    const response = await postSubmitForm(url, {
      id: shift._id,
    });
    if (response && response.status === 1) {
      loadShifts();
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showStatusFormatter(cell, row) {
    return (
      <>
        {!row.is_active ? (
          <div className="custom-control custom-switch mb-2" dir="ltr">
            <input
              type="checkbox"
              title="Click to change status."
              className="custom-control-input"
              id={"customSwitch1" + row._id}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ "font-weight": "100", cursor: "pointer" }}
              onClick={() => {
                handleShiftStatusUpdate(row);
              }}
            ></label>
          </div>
        ) : (
          <div className="custom-control custom-switch mb-2" dir="ltr">
            <input
              type="checkbox"
              title="Click to change status."
              className="custom-control-input"
              id={"customSwitch1" + row._id}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
            ></label>
          </div>
        )}

        {row.is_active ? (
          <span class="font-size-12 badge-soft-success badge badge-success badge-pill">
            Active
          </span>
        ) : (
          <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
            Inactive
          </span>
        )}
      </>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "in_time",
      text: props.t("In Time"),
      sort: true,
    },
    {
      dataField: "out_time",
      text: props.t("Out Time"),
      sort: true,
    },
    {
      dataField: "break_start_time",
      text: props.t("Break Start Time"),
      sort: true,
    },
    {
      dataField: "break_end_time",
      text: props.t("Break End Time"),
      sort: true,
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
    },
    {
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Shifts")}
            breadcrumbItem={props.t("All Shifts")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Shifts")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing shifts here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allShifts && allShifts}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AllShifts)));

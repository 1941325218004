import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  CardTitle,
  Card,
  CardBody,
  Media,
  UncontrolledAlert,
} from "reactstrap";

import moment from "moment";

import { AvForm, AvField } from "availity-reactstrap-validation";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import swal from "sweetalert2";
import Moment from "react-moment";
import { Link } from "react-router-dom";
// Pages Components
import WelcomeComp from "./WelcomeComp";
import Scheduler from "../Appointments/Scheduler";

import { postSubmitForm } from "../../helpers/forms_helper";
//i18n
import { withNamespaces } from "react-i18next";

import LineBarChart from "../AllCharts/echart/linebarchart";

const Dashboard = (props) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [monthFrom, setMonthFrom] = useState();
  const [monthTo, setMonthTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    const month_from = moment().subtract(11, "months").format("YYYY-MM");
    //setDateFrom(formatDate(firstDay));
    setDateFrom(formatDate(currentDay));
    setDateTo(formatDate(currentDay));
    setMonthFrom(month_from);
    setMonthTo(formatDate(currentDay).slice(0, 7));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadDashData();
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (monthFrom && monthTo) {
      loadDashData();
    }
  }, [monthFrom, monthTo]);
  const [expiryDateEmployees, setExpiryDateEmployees] = useState([]);
  const [dashData, setDashData] = useState();
  const [isClosedYesterday, setIsClosedYesterday] = useState();
  const [allAppointments, setAllAppointments] = useState([]);
  const loadDashData = async () => {
    let url = process.env.REACT_APP_BASEURL + "dashboard";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
      month_from: monthFrom,
      month_to: monthTo,
    });
    if (response && response.status === 1) {
      setDashData(response.data);
      setExpiryDateEmployees(response.data.employees);
      setIsClosedYesterday(response.data.is_closed_yesterday);
      setAllAppointments(response.data.three_appointments);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  function showBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.beautician_details.map((beautician) => {
          if (beautician.type === "Secondary") {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          }
        })}
      </>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Code"),
      //formatter: employeeFormatter,
      dataField: "code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Medical ID Expiry"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        return <Moment format="DD/MM/YYYY">{row.medicalid_expirydate}</Moment>;
      },
    },
    {
      text: props.t("Qatar ID Expiry"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.qatarid_expirydate) {
          return <Moment format="DD/MM/YYYY">{row.qatarid_expirydate}</Moment>;
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
    },
    // {
    //   text: props.t("Action"),
    //   formatter: editFormatter,
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "7%" };
    //   },
    // },
  ];

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Start"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        return moment(row.start).format("DD-MM-YYYY HH:mm");
      },
      style: { cursor: "pointer" },
      sort: true,
    },

    {
      text: props.t("Check In"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.check_in) {
          return moment(row.check_in).format("DD-MM-YYYY HH:mm");
        } else {
          return props.t("NA");
        }
      },
      style: { cursor: "pointer" },
      sort: true,
    },
    {
      text: props.t("Check Out"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.check_out) {
          return moment(row.check_out).format("DD-MM-YYYY HH:mm");
        } else {
          return props.t("NA");
        }
      },
      style: { cursor: "pointer" },
      sort: true,
    },

    {
      dataField: "client_details.client_name",
      text: props.t("Client"),
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
      style: { cursor: "pointer" },
      sort: true,
    },
    {
      dataField: "client_details.client_mobile",
      text: props.t("Client Mobile"),
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
      style: { cursor: "pointer" },
      sort: true,
    },
    {
      dataField: "final_amount",
      text: props.t("Final Amount"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      style: { cursor: "pointer" },
      sort: true,
    },
    {
      dataField: "is_homeservice",
      text: props.t("Home Service?"),
      formatter: (cell, row) => {
        return row.is_homeservice ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      style: { cursor: "pointer" },
      sort: true,
    },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: true,
      formatter: (cell, row) => {
        if (row.status === "Completed") {
          return (
            <span>
              {row.status} <br />
              <a
                href={"/invoice?invoice=" + row.serviceandsale_id}
                target="_blank"
                style={{
                  color: "blue",
                  textDecorationLine: "underline",
                }}
                className="font-size-11"
              >
                ({row.invoice_number})
              </a>
            </span>
          );
        } else {
          return row.status;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
    },
  ];

  const columns_internal = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Service Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "duration",
      text: props.t("Duration (minutes)"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Beauticians"),
      formatter: showBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.service_details}
          columns={columns_internal}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {!isClosedYesterday && role !== "beautician" && (
            <>
              <CardTitle>{props.t("DASHBOARD")}</CardTitle>
              <Row>
                <Col lg={12}>
                  <UncontrolledAlert
                    color="warning"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    <i className="mdi mdi-alert-outline me-2"></i> Closure has
                    not been submitted for yesterday.
                  </UncontrolledAlert>
                </Col>
              </Row>
            </>
          )}
          <Row>
            {role === "admin" && (
              <Col xl="4">
                <WelcomeComp />
              </Col>
            )}
            {role !== "beautician" && (
              <Col className={role === "admin" ? "col-lg-6" : "col-lg-4"}>
                <Card>
                  <CardBody>
                    <AvForm>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            name="dateFrom"
                            label={props.t("From Date")}
                            placeholder={props.t("Select")}
                            type="date"
                            value={dateFrom}
                            onChange={(e, v) => {
                              setDateFrom(formatDate(e.target.value));
                            }}
                            errorMessage={props.t("Date cannot be empty.")}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                        <Col lg={6}>
                          <AvField
                            name="dateTo"
                            label={props.t("To Date")}
                            placeholder={props.t("Select")}
                            type="date"
                            value={dateTo}
                            onChange={(e, v) => {
                              setDateTo(formatDate(e.target.value));
                            }}
                            errorMessage={props.t("Date cannot be empty.")}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            )}
            {role === "admin" && (
              <Col sm="2">
                <div class="mini-stats-wid card">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Total Clients")}
                        </p>
                        <h4 class="mb-0">
                          {dashData && dashData.total_clients}
                        </h4>
                      </div>
                      <div
                        style={{ height: "30px", width: "30px" }}
                        class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                      >
                        <span
                          class="avatar-title"
                          // style={{ backgroundColor: "#ec4561" }}
                        >
                          <i class="mdi mdi-clipboard-account font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {role === "admin" && (
              <>
                <Col sm="4">
                  <div class="mini-stats-wid card">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <p class="text-muted font-weight-bold">
                            {props.t("Total Appointments")}
                          </p>
                          <h4 class="mb-0">
                            {dashData && dashData.total_appointments}
                          </h4>
                        </div>
                        <div
                          style={{ height: "30px", width: "30px" }}
                          class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        >
                          <span
                            class="avatar-title"
                            // style={{ backgroundColor: "#ec4561" }}
                          >
                            <i class="bx bx-timer font-size-20"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="2">
                  <div class="mini-stats-wid card">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <p class="text-muted font-weight-bold">
                            {props.t("Booked")}
                          </p>
                          <h4 class="mb-0">
                            {dashData && dashData.booked_appointments}
                          </h4>
                        </div>
                        <div
                          style={{ height: "30px", width: "30px" }}
                          class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        >
                          <span
                            class="avatar-title"
                            style={{ backgroundColor: "#9ec3ff" }}
                          >
                            <i class="bx bx-timer font-size-20"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="2">
                  <div class="mini-stats-wid card">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <p class="text-muted font-weight-bold">
                            {props.t("In Process")}
                          </p>
                          <h4 class="mb-0">
                            {dashData && dashData.in_process_appointments}
                          </h4>
                        </div>
                        <div
                          style={{ height: "30px", width: "30px" }}
                          class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        >
                          <span
                            class="avatar-title"
                            style={{ backgroundColor: "#fffd9e" }}
                          >
                            <i class="bx bx-timer font-size-20"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="2">
                  <div class="mini-stats-wid card">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <p class="text-muted font-weight-bold">
                            {props.t("Cancelled")}
                          </p>
                          <h4 class="mb-0">
                            {dashData && dashData.cancelled_appointments}
                          </h4>
                        </div>
                        <div
                          style={{ height: "30px", width: "30px" }}
                          class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        >
                          <span
                            class="avatar-title"
                            style={{ backgroundColor: "#ff9e9e" }}
                          >
                            <i class="bx bx-timer font-size-20"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="2">
                  <div class="mini-stats-wid card">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <p class="text-muted font-weight-bold">
                            {props.t("Completed")}
                          </p>
                          <h4 class="mb-0">
                            {dashData && dashData.completed_appointments}
                          </h4>
                        </div>
                        <div
                          style={{ height: "30px", width: "30px" }}
                          class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        >
                          <span
                            class="avatar-title"
                            style={{ backgroundColor: "#b0ff9e" }}
                          >
                            <i class="bx bx-timer font-size-20"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
            {role === "manager" && (
              <>
                <Col sm="8">
                  <div class="mini-stats-wid card">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <Row className="mb-4">
                            <Col>
                              <p class="text-muted font-weight-bold">
                                {props.t("Total Appt.")}
                              </p>
                              <h4 class="mb-0">
                                {dashData && dashData.total_appointments}
                              </h4>
                            </Col>
                            <Col>
                              <p class="text-muted font-weight-bold">
                                {props.t("Booked")}
                              </p>
                              <h4 class="mb-0">
                                {dashData && dashData.booked_appointments}
                              </h4>
                            </Col>
                            <Col>
                              <p class="text-muted font-weight-bold">
                                {props.t("In Process")}
                              </p>
                              <h4 class="mb-0">
                                {dashData && dashData.in_process_appointments}
                              </h4>
                            </Col>
                            <Col>
                              <p class="text-muted font-weight-bold">
                                {props.t("Cancelled")}
                              </p>
                              <h4 class="mb-0">
                                {dashData && dashData.cancelled_appointments}
                              </h4>
                            </Col>
                            <Col>
                              <p class="text-muted font-weight-bold">
                                {props.t("Completed")}
                              </p>
                              <h4 class="mb-0">
                                {dashData && dashData.completed_appointments}
                              </h4>
                            </Col>
                          </Row>
                        </div>
                        <div
                          style={{ height: "30px", width: "30px" }}
                          class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        >
                          <span
                            class="avatar-title"
                            // style={{ backgroundColor: "#ec4561" }}
                          >
                            <i class="bx bx-timer font-size-20"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
            {expiryDateEmployees &&
            role === "admin" &&
            expiryDateEmployees.length > 0 ? (
              <Col className="col-sm-6">
                <Card>
                  <CardBody>
                    <CardTitle className="font-size-13">
                      {props.t(
                        "Employees with Qatar/Medical ID expiring in next 15 days"
                      )}
                      :
                    </CardTitle>
                    <hr />
                    <BootstrapTable
                      bootstrap4
                      keyField="_id"
                      data={expiryDateEmployees && expiryDateEmployees}
                      columns={columns}
                      noDataIndication="No data to display."
                      striped
                      hover
                      condensed
                    />
                    <Link
                      to="all_employees"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      {props.t("View/Edit Employees")}{" "}
                      <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            ) : null}
            {role === "admin1" && (
              <>
                <Col md="2">
                  <Link to="/arrear_deduction">
                    <Card className="mini-stats-wid bg-danger">
                      <CardBody>
                        <Media>
                          <Media body className="text-center">
                            <p
                              className="fw-medium"
                              style={{ color: "white" }}
                            ></p>
                            <h4 className="mb-3" style={{ color: "white" }}>
                              Update Incentives
                            </h4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col md="2">
                  <Link to="/charity_report">
                    <Card className="mini-stats-wid bg-info">
                      <CardBody>
                        <Media>
                          <Media body className="text-center">
                            <p
                              className="fw-medium"
                              style={{ color: "white" }}
                            ></p>
                            <h4 className="mb-3" style={{ color: "white" }}>
                              Submit Charity
                            </h4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col md="2">
                  <Link to="/submit_closure">
                    <Card className="mini-stats-wid bg-success">
                      <CardBody>
                        <Media>
                          <Media body className="text-center">
                            <p
                              className="fw-medium"
                              style={{ color: "white" }}
                            ></p>
                            <h4 className="mb-3" style={{ color: "white" }}>
                              Submit Closure
                            </h4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </>
            )}
            {role === "admin" && (
              <>
                <Col md="6">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <AvForm>
                        <Row>
                          <Col lg={12}>
                            <h5>Monthwise Income</h5>
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="monthFrom"
                              label={props.t("From Month")}
                              type="month"
                              value={monthFrom}
                              onChange={(e, v) => {
                                setMonthFrom(e.target.value);
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="monthTo"
                              label={props.t("To Month")}
                              type="month"
                              value={monthTo}
                              onChange={(e, v) => {
                                setMonthTo(e.target.value);
                              }}
                            />
                          </Col>
                          <Col lg={12}>
                            <div id="line-chart" className="e-chart">
                              <LineBarChart
                                line_data={dashData && dashData.monthly_income}
                                type="monthly_income_trend"
                                yAxisLabel="Income"
                              />
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <h5>Today's Appointments for three</h5>
                        </Col>
                      </Row>

                      <ToolkitProvider
                        keyField="_id"
                        data={allAppointments && allAppointments}
                        columns={columns_external}
                        noDataIndication={props.t("No data to display.")}
                        bootstrap4
                      >
                        {(props) => (
                          <div>
                            <BootstrapTable
                              striped
                              hover
                              condensed
                              expandRow={expandRow}
                              rowStyle={rowStyle}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
          {role === "manager" && <Scheduler />}
          {role === "beautician" && <Scheduler readOnly={true} />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(Dashboard);

import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";

const Navbar = (props) => {
  // const [dashboard, setdashboard] = useState(false);
  const [appointment, setAppointment] = useState(false);
  const [inventory, setInventory] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [productSales, setProductSales] = useState(false);
  const [masterData, setMasterData] = useState(false);
  const [employees, setEmployees] = useState(false);
  const [users, setUsers] = useState(false);
  const [clients, setClients] = useState(false);
  const [services, setServices] = useState(false);
  const [products, setProducts] = useState(false);
  const [designations, setDesignations] = useState(false);
  const [salary, setSalary] = useState(false);
  const [shifts, setShifts] = useState(false);
  const [serviceCategories, setServiceCategories] = useState(false);
  const [productCategories, setProductCategories] = useState(false);
  const [expenses, setExpenses] = useState(false);
  const [sources, setSources] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="dashboard">
                    <i className="bx bx-home-circle mr-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="appointments" className="nav-link">
                    <i className="bx bx-timer mr-2"></i>
                    {props.t("Appointments")}
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: appointment,
                    })}
                  >
                    <Link to="add_appointment" className="dropdown-item">
                      {props.t("Book Appointment")}
                    </Link>
                    <Link to="/#" className="dropdown-item">
                      {props.t("Today's Appointments")}
                    </Link>
                    <Link to="/#" className="dropdown-item">
                      {props.t("All Appointments")}
                    </Link>
                  </div>
                </li> */}
                {role !== "beautician" && (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setAppointment(!appointment);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-timer mr-2"></i>
                        {props.t("Appointments")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: appointment,
                        })}
                      >
                        <Link to="appointments" className="dropdown-item">
                          {props.t("Scheduler")}
                        </Link>

                        <Link
                          to="ongoing_appointments"
                          className="dropdown-item"
                        >
                          {props.t("Check-out")}
                        </Link>
                        <Link to="all_appointments" className="dropdown-item">
                          {props.t("All Appointments")}
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setProductSales(!productSales);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-cart mr-2"></i>
                        {props.t("Product Sales")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: productSales,
                        })}
                      >
                        <Link to="add_new_order" className="dropdown-item">
                          {props.t("Add New Order")}
                        </Link>
                        <Link to="all_orders" className="dropdown-item">
                          {props.t("View All Orders")}
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setInventory(!inventory);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="mdi mdi-cart-plus mr-2"></i>
                        {props.t("Inventory")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: inventory,
                        })}
                      >
                        <Link to="update_inventory" className="dropdown-item">
                          {props.t("Add Inventory")}
                        </Link>
                        <Link to="view_inventory" className="dropdown-item">
                          {props.t("View Inventory")}
                        </Link>
                        <Link to="used_inventory" className="dropdown-item">
                          {props.t("Used Inventory")}
                        </Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setMasterData(!masterData);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-book mr-2"></i>
                        {props.t("Master Data")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: masterData,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEmployees(!employees);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Employees")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: employees,
                            })}
                          >
                            <Link to="add_employee" className="dropdown-item">
                              {props.t("Add New Employee")}
                            </Link>
                            <Link to="all_employees" className="dropdown-item">
                              {props.t("View Employees")}
                            </Link>
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSalary(!salary);
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Salary")}
                                </span>{" "}
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: salary,
                                })}
                              >
                                <Link
                                  to="generate_salary"
                                  className="dropdown-item"
                                >
                                  {props.t("Generate Salary")}
                                </Link>
                                <Link
                                  to="view_salary"
                                  className="dropdown-item"
                                >
                                  {props.t("View Salaries")}
                                </Link>
                                <Link
                                  to="arrear_deduction"
                                  className="dropdown-item"
                                >
                                  {props.t("Incentives")}
                                </Link>
                              </div>
                            </div>

                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDesignations(!designations);
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Designations")}
                                </span>{" "}
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: designations,
                                })}
                              >
                                <Link
                                  to="add_designation"
                                  className="dropdown-item"
                                >
                                  {props.t("Add Designation")}
                                </Link>
                                <Link
                                  to="all_designations"
                                  className="dropdown-item"
                                >
                                  {props.t("View Designations")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setUsers(!users);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Users")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: users,
                            })}
                          >
                            <Link to="add_user" className="dropdown-item">
                              {props.t("Add New User")}
                            </Link>
                            <Link to="all_users" className="dropdown-item">
                              {props.t("View Users")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setClients(!clients);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Clients")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: clients,
                            })}
                          >
                            <Link to="add_client" className="dropdown-item">
                              {props.t("Add New Client")}
                            </Link>
                            <Link to="all_clients" className="dropdown-item">
                              {props.t("View Clients")}
                            </Link>
                            <Link
                              to="wallet_transaction"
                              className="dropdown-item"
                            >
                              {props.t("Wallet Transaction")}
                            </Link>
                            <Link to="wallet_history" className="dropdown-item">
                              {props.t("Wallet History")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setServices(!services);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Services")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: services,
                            })}
                          >
                            <Link to="add_service" className="dropdown-item">
                              {props.t("Add New Service")}
                            </Link>
                            <Link to="all_services" className="dropdown-item">
                              {props.t("View Services")}
                            </Link>
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setServiceCategories(!serviceCategories);
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Service Categories")}
                                </span>{" "}
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: serviceCategories,
                                })}
                              >
                                <Link
                                  to="add_service_category"
                                  className="dropdown-item"
                                >
                                  {props.t("Add Category")}
                                </Link>
                                <Link
                                  to="all_service_categories"
                                  className="dropdown-item"
                                >
                                  {props.t("View Categories")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setExpenses(!expenses);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Expenses")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: expenses,
                            })}
                          >
                            <Link to="add_expense" className="dropdown-item">
                              {props.t("Add New Expense")}
                            </Link>
                            <Link to="all_expenses" className="dropdown-item">
                              {props.t("View Expenses")}
                            </Link>
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSources(!sources);
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Sources")}
                                </span>{" "}
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: sources,
                                })}
                              >
                                <Link to="add_source" className="dropdown-item">
                                  {props.t("Add New Source")}
                                </Link>
                                <Link
                                  to="all_sources"
                                  className="dropdown-item"
                                >
                                  {props.t("View Sources")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setProducts(!products);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Products")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: products,
                            })}
                          >
                            <Link to="add_product" className="dropdown-item">
                              {props.t("Add New Product")}
                            </Link>
                            <Link to="all_products" className="dropdown-item">
                              {props.t("View Products")}
                            </Link>
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductCategories(!productCategories);
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Product Categories")}
                                </span>{" "}
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: productCategories,
                                })}
                              >
                                <Link
                                  to="add_product_category"
                                  className="dropdown-item"
                                >
                                  {props.t("Add Category")}
                                </Link>
                                <Link
                                  to="all_product_categories"
                                  className="dropdown-item"
                                >
                                  {props.t("View Categories")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setShifts(!shifts);
                            }}
                          >
                            <span key="t-email-templates">
                              {props.t("Shifts")}
                            </span>{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: shifts,
                            })}
                          >
                            <Link to="add_shift" className="dropdown-item">
                              {props.t("Add Shift")}
                            </Link>
                            <Link to="all_shifts" className="dropdown-item">
                              {props.t("View Shifts")}
                            </Link>
                          </div>
                        </div>
                        {(role === "manager" || role === "admin") && (
                          <Link to="submit_closure" className="dropdown-item">
                            {props.t("Submit Closure")}
                          </Link>
                        )}
                        {role === "admin" && (
                          <Link to="app_settings" className="dropdown-item">
                            {props.t("App Settings")}
                          </Link>
                        )}
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setAttendance(!attendance);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-calendar-check mr-2"></i>
                        {props.t("Attendance")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        <Link to="add_attendance" className="dropdown-item">
                          {props.t("Add Attendance")}
                        </Link>
                        <Link to="view_attendance" className="dropdown-item">
                          {props.t("View Attendance")}
                        </Link>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Navbar))
);

import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AddClient = (props) => {
  useEffect(() => {
    loadClients();
  }, []);
  const [qatarID, setQatarID] = useState();
  const [passport, setPassport] = useState();
  const [allClients, setAllClients] = useState([]);
  const loadClients = async () => {
    let url = process.env.REACT_APP_BASEURL + "clients/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllClients(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const refContainer = useRef(null);
  const handleValidSubmit = async (e, v) => {
    v.qatarID = qatarID;
    v.passport = passport;
    let url = process.env.REACT_APP_BASEURL + "clients/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadClients();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
    refContainer.current.reset();
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "qatarid",
      text: props.t("Qatar ID"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "passport",
      text: props.t("Passport"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "email",
      text: props.t("Email"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Clients")}
            breadcrumbItem={props.t("Add Client")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Client")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new client"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name") + " *"}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          errorMessage="Name cannot be empty."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="mobile"
                          label={props.t("Mobile") + " *"}
                          placeholder={props.t("Enter Mobile")}
                          errorMessage="Mobile cannot be empty."
                          type="text"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                            maxLength: {
                              value: 8,
                              errorMessage: props.t("Enter valid mobile."),
                            },
                            minLength: {
                              value: 8,
                              errorMessage: props.t("Enter valid mobile."),
                            },
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          placeholder={props.t("Enter Email")}
                          errorMessage={props.t("Enter valid email.")}
                          type="email"
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="qatarid"
                          label={props.t("Qatar ID")}
                          placeholder={props.t("Enter Qatar ID")}
                          errorMessage="Qatar ID cannot be empty."
                          type="text"
                          onChange={(e) => {
                            setQatarID(e.target.value);
                          }}
                          validate={{
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                            maxLength: {
                              value: 11,
                              errorMessage: props.t("Enter valid qatar id."),
                            },
                            minLength: {
                              value: 11,
                              errorMessage: props.t("Enter valid qatar id."),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="passport"
                          label={props.t("Passport Number")}
                          placeholder={props.t("Enter Passport Number")}
                          errorMessage="Passport Number cannot be empty."
                          type="text"
                          onChange={(e) => {
                            setPassport(e.target.value);
                          }}
                          validate={{
                            maxLength: {
                              value: 25,
                              errorMessage: props.t("Enter valid passport."),
                            },
                            minLength: {
                              value: 6,
                              errorMessage: props.t("Enter valid passport."),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="remarks"
                          label={props.t("Remarks")}
                          placeholder={props.t("Enter Remarks")}
                          type="textarea"
                        />
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Clients")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing clients here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allClients && allClients}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AddClient))
);

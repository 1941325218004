import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const animatedComponents = makeAnimated();

const AddService = (props) => {
  useEffect(() => {
    loadServices();
    loadAllServiceCategories();
    loadAllBeauticians();
  }, []);
  const [allServices, setAllServices] = useState([]);
  const loadServices = async () => {
    let url = process.env.REACT_APP_BASEURL + "services/get_grouped"; //"services/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServices(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBeauticians, setAllBeauticians] = useState([]);
  const [allPrimaryBeauticians, setAllPrimaryBeauticians] = useState([]);
  const [allSecondaryBeauticians, setAllSecondaryBeauticians] = useState([]);
  const loadAllBeauticians = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/get_beauticians";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllBeauticians(response.data);
      setAllPrimaryBeauticians(response.data);
      setAllSecondaryBeauticians(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const refContainer = useRef(null);
  const [isCostUpdatable, setIsCostUpdatable] = useState(false);
  const [isDurationUpdatable, setIsDurationUpdatable] = useState(false);
  const handleValidSubmit = async (e, v) => {
    try {
      if (selectedPrimaryBeauticians && selectedPrimaryBeauticians.length > 0) {
        let data_to_send = {};
        data_to_send.is_cost_updatable = isCostUpdatable;
        data_to_send.service_category_details = {
          service_id: selectedCategoryID,
          name: selectedCategoryName,
        };
        data_to_send.name = v.name;
        data_to_send.cost = v.cost;
        data_to_send.duration = v.duration;
        data_to_send.is_duration_updatable = isDurationUpdatable;

        data_to_send.primary_beautician_list =
          selectedPrimaryBeauticians.slice();
        data_to_send.secondary_beautician_list =
          selectedSecondaryBeauticians.slice();

        data_to_send.remarks = v.remarks;

        let url = process.env.REACT_APP_BASEURL + "services/insert";
        const response = await postSubmitForm(url, data_to_send);
        if (response && response.status === 1) {
          loadServices();
          setAllPrimaryBeauticians(allBeauticians);
          setAllSecondaryBeauticians(allBeauticians);
          showNotification(response.message, "Success");
        } else {
          showNotification(response.message, "Error");
        }
        setSelectedPrimaryBeauticians([]);
        setSelectedSecondaryBeauticians([]);
        refContainer.current.reset();
      } else {
        showNotification("Select at least one primary beautician!", "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [selectedCategoryID, setSelectedCategoryID] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [allServiceCategories, setAllServiceCategories] = useState();
  const loadAllServiceCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServiceCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [selectedPrimaryBeauticians, setSelectedPrimaryBeauticians] = useState(
    []
  );
  const [selectedSecondaryBeauticians, setSelectedSecondaryBeauticians] =
    useState([]);
  const [isNewCategoryModal, setNewCategoryModal] = useState();
  const handleAddNewCategory = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllServiceCategories();
      setSelectedCategoryID(response.data._id);
      setSelectedCategoryName(response.data.name);
      setNewCategoryModal(!isNewCategoryModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showPrimaryBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.primary_beautician_list.map((beautician) => {
          return (
            <>
              <span
                class="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                title={props.t("Mobile:") + " " + beautician.mobile}
              >
                {beautician.name}
              </span>
            </>
          );
        })}
      </>
    );
  }
  function showSecondaryBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.secondary_beautician_list.map((beautician) => {
          return (
            <>
              <span
                class="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                title={props.t("Mobile:") + " " + beautician.mobile}
              >
                {beautician.name}
              </span>
            </>
          );
        })}
      </>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "duration",
      text: props.t("Duration (minutes)"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    // {
    //   dataField: "service_category_details.name",
    //   text: props.t("Service Category"),
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" };
    //   },
    // },
    {
      text: props.t("Primary Beauticians"),
      formatter: showPrimaryBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Secondary Beauticians"),
      formatter: showSecondaryBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category Name"),
      dataField: "category_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "total_services",
      text: props.t("Total Services"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      style: { cursor: "pointer" },
    },
  ];
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.services}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Services")}
            breadcrumbItem={props.t("Add Service")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Service")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new service"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={9}>
                            <AvField
                              name="service_categories"
                              label={props.t("Category") + " *"}
                              type="select"
                              value={selectedCategoryID}
                              onChange={(e, v) => {
                                const selectedIndex =
                                  e.target.options.selectedIndex;
                                setSelectedCategoryID(e.target.value);
                                setSelectedCategoryName(
                                  e.target.options[selectedIndex].getAttribute(
                                    "name"
                                  )
                                );
                              }}
                              required
                              errorMessage={props.t("Select Category")}
                            >
                              <option value="" name="">
                                -- {props.t("Select Category")} --
                              </option>
                              {allServiceCategories &&
                                allServiceCategories.map((item) => {
                                  return (
                                    <option value={item._id} name={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </AvField>
                          </Col>
                          <Col lg={3}>
                            <Label>&nbsp;</Label>
                            <br />
                            <Link
                              class="btn btn-success waves-effect waves-light btn-sm"
                              onClick={() => {
                                setNewCategoryModal(!isNewCategoryModal);
                              }}
                            >
                              <i class="mdi mdi-file-plus"></i> Add New
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name") + " *"}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          errorMessage="Name cannot be empty."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={5}>
                            <Label>
                              {props.t("Is Price Updatable?") + " *"}
                            </Label>
                            <AvRadioGroup
                              inline
                              name="is_cost_updatable"
                              validate={{ required: { value: true } }}
                              errorMessage={props.t("Select an option.")}
                            >
                              <AvRadio
                                label={props.t("Yes")}
                                value="true"
                                onChange={(e, v) => {
                                  setIsCostUpdatable(true);
                                }}
                              />

                              <AvRadio
                                label={props.t("No")}
                                value="false"
                                onChange={(e, v) => {
                                  setIsCostUpdatable(false);
                                }}
                              />
                            </AvRadioGroup>
                          </Col>
                          <Col lg={7}>
                            <AvField
                              name="cost"
                              label={props.t("Price") + " *"}
                              placeholder={props.t("Enter Price")}
                              errorMessage="Price cannot be empty."
                              type="text"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: "^\\d+(\\.\\d{1,2})?$",
                                  errorMessage: props.t(
                                    "Only numbers and decimals upto two places are allowed."
                                  ),
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={5}>
                            <Label>
                              {props.t("Is Duration Updatable?") + " *"}
                            </Label>
                            <AvRadioGroup
                              inline
                              name="is_duration_updatable"
                              validate={{ required: { value: true } }}
                              errorMessage={props.t("Select an option.")}
                            >
                              <AvRadio
                                label={props.t("Yes")}
                                value="true"
                                onChange={(e, v) => {
                                  setIsDurationUpdatable(true);
                                }}
                              />

                              <AvRadio
                                label={props.t("No")}
                                value="false"
                                onChange={(e, v) => {
                                  setIsDurationUpdatable(false);
                                }}
                              />
                            </AvRadioGroup>
                          </Col>
                          <Col lg={7}>
                            <AvField
                              name="duration"
                              label={props.t("Duration (minutes)") + " *"}
                              placeholder={props.t("Enter Duration")}
                              errorMessage="Duration cannot be empty."
                              type="text"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: "^[0-9]+$",
                                  errorMessage: props.t(
                                    "Only numbers are allowed."
                                  ),
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Label>{props.t("Primary Beauticians")}</Label>
                        <Select
                          value={selectedPrimaryBeauticians}
                          isMulti={true}
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              const newArray = allBeauticians
                                .slice()
                                .filter((objFromA) => {
                                  return !selected.find((objFromB) => {
                                    return objFromA._id === objFromB._id;
                                  });
                                });

                              setAllSecondaryBeauticians(newArray);
                            } else {
                              setAllSecondaryBeauticians(
                                allBeauticians.slice()
                              );
                            }
                            setSelectedPrimaryBeauticians(selected);
                          }}
                          options={allPrimaryBeauticians}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          required
                        />
                      </Col>
                      <Col lg={6}>
                        <Label>{props.t("Secondary Beauticians")}</Label>
                        <Select
                          value={selectedSecondaryBeauticians}
                          isMulti={true}
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              const newArray = allBeauticians
                                .slice()
                                .filter((objFromA) => {
                                  return !selected.find((objFromB) => {
                                    return objFromA._id === objFromB._id;
                                  });
                                });
                              setAllPrimaryBeauticians(newArray);
                            } else {
                              setAllPrimaryBeauticians(allBeauticians.slice());
                            }
                            setSelectedSecondaryBeauticians(selected);
                          }}
                          options={allSecondaryBeauticians}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          required
                        />
                      </Col>
                      <Col lg={6}>
                        <br />
                        <AvField
                          name="remarks"
                          label={props.t("Remarks")}
                          placeholder={props.t("Enter Remarks")}
                          type="textarea"
                        />
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                  <Modal
                    size="md"
                    isOpen={isNewCategoryModal}
                    toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                    >
                      Add New Service Category
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category Name") + " *"}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      errorMessage="Category name cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <AvField
                                      name="remarks"
                                      label={props.t("Remarks")}
                                      placeholder={props.t("Enter Remarks")}
                                      type="textarea"
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Services")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing services here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allServices && allServices}
                    columns={columns_external}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                    expandRow={expandRow}
                  />{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AddService))
);

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import Moment from "react-moment";

import { withNamespaces } from "react-i18next";

//Import Image
import logo from "../assets/images/logo-light.png";
const Invoice = (props) => {
  function printInvoice() {
    window.print();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <h4 className="float-right font-size-16">
                      {props.t("Invoice ")}#{" "}
                      {props.order && props.order.invoice_number}
                    </h4>
                    <div className="mb-4">
                      <img src={logo} alt="logo" height="35" />
                    </div>
                  </div>
                  <hr />
                  <Row>
                    <Col xs="6">
                      <address>
                        <p>{props.t("Billed To")}</p>
                        <strong>
                          {" "}
                          {props.order &&
                            props.order.client_details.client_name}
                        </strong>
                        <br />
                        {props.t("Mobile")}
                        {": "}
                        {props.order &&
                          props.order.client_details.client_mobile}
                      </address>
                    </Col>
                    <Col xs="6" className="text-right">
                      <address>
                        <p>{props.t("Invoice Date & Time")}</p>
                        <strong>
                          <Moment format="DD/MM/YYYY hh:mm a">
                            {props.order && props.order.createdAt}
                          </Moment>
                        </strong>
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  {props.order &&
                  props.order.service_details &&
                  props.order.service_details.length > 0 ? (
                    <>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {props.t("Services")}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th>{props.t("Name")}</th>
                              <th>
                                {props.t("Duration")} ({props.t("minutes")})
                              </th>
                              <th className="text-right">
                                {props.t("Price") + " (QR)"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.order &&
                              props.order.service_details.map(
                                (service, key) => (
                                  <tr>
                                    <td>{service.name}</td>
                                    <td>{service.duration}</td>
                                    <td className="text-right">
                                      {service.cost}
                                    </td>
                                  </tr>
                                )
                              )}
                            <tr>
                              <td colSpan="2" className="border-0 text-right">
                                <strong>{props.t("Subtotal")}</strong>
                              </td>
                              <td className="border-0 text-right">
                                {props.order && props.order.service_amount}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : null}
                  {props.order &&
                  props.order.sold_product_details.length > 0 ? (
                    <>
                      {" "}
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {props.t("Products")}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th>{props.t("Name")}</th>
                              <th>{props.t("Batch")}</th>
                              <th className="text-right">
                                {props.t("Quantity")}
                              </th>
                              <th className="text-right">
                                {props.t("Price") + " (QR)"}
                              </th>
                              <th className="text-right">
                                {props.t("Total Amount") + " (QR)"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.order &&
                              props.order.sold_product_details.map(
                                (product, key) => (
                                  <tr>
                                    <td>{product.name}</td>
                                    <td>{product.batch}</td>
                                    <td className="text-right">
                                      {product.quantity}
                                    </td>
                                    <td className="text-right">
                                      {product.selling_price}
                                    </td>
                                    <td className="text-right">
                                      {product.total_price}
                                    </td>
                                  </tr>
                                )
                              )}
                            <tr>
                              <td colSpan="4" className="border-0 text-right">
                                <strong>{props.t("Subtotal")}</strong>
                              </td>
                              <td className="border-0 text-right">
                                {props.order && props.order.sold_product_amount}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : null}
                  <hr />
                  <Table className="table-nowrap">
                    <tbody>
                      <tr>
                        <td colSpan="4" className="border-0 text-right">
                          <strong>{props.t("Total")}</strong>
                        </td>
                        <td className="border-0 text-right">
                          {props.order && props.order.total_amount}
                        </td>
                      </tr>
                      {props.order && props.order.discount > 0 ? (
                        <tr>
                          <td colSpan="4" className="border-0 text-right">
                            <strong>{props.t("Discount")}</strong>
                          </td>
                          <td className="border-0 text-right">
                            {props.order && props.order.discount}
                          </td>
                        </tr>
                      ) : null}
                      {props.order && props.order.discount > 0 ? (
                        <tr>
                          <td colSpan="4" className="border-0 text-right">
                            <strong>{props.t("Price (after discount)")}</strong>
                          </td>
                          <td className="border-0 text-right">
                            {props.order && props.order.final_amount}
                          </td>
                        </tr>
                      ) : null}
                      {props.order && props.order.from_wallet > 0 ? (
                        <tr>
                          <td colSpan="4" className="border-0 text-right">
                            <strong>{props.t("Paid from Wallet")}</strong>
                          </td>
                          <td className="border-0 text-right">
                            {props.order && props.order.from_wallet}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td colSpan="4" className="border-0 text-right">
                          <strong>{props.t("Paid by Cash")}</strong>
                        </td>
                        <td className="border-0 text-right">
                          {props.order && props.order.paid_by_cash}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="border-0 text-right">
                          <strong>{props.t("Paid by Card")}</strong>
                        </td>
                        <td className="border-0 text-right">
                          {props.order && props.order.paid_by_card}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-print-none">
                    <div className="float-right">
                      <Link
                        to="#"
                        onClick={() => {
                          printInvoice();
                        }}
                        className="btn btn-success waves-effect waves-light mr-2"
                      >
                        <i className="fa fa-print"></i> {props.t("Print")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(Invoice);

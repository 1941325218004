import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import classnames from "classnames";

//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import swal from "sweetalert2";
import LineBarChart from "../AllCharts/echart/linebarchart";

import { AvForm, AvField, AvRadio } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//DataTable
import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import infograph1 from "../../assets/images/1.png";
import infograph2 from "../../assets/images/2.png";
import infograph3 from "../../assets/images/3.png";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";
const AccountsReport = (props) => {
  const { SearchBar } = Search;
  const [dates, setDates] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    const datefrom = formatDate(firstDay);
    const dateto = formatDate(currentDay);

    setDates({
      date_from: datefrom,
      date_to: dateto,
    });

    loadAccountsReport({
      date_from: datefrom,
      date_to: dateto,
    });
  }, []);

  useEffect(() => {
    console.log(dates);
    if (dates) {
      console.log(dates);
      loadAccountsReport(dates);
    }
  }, [dates]);

  const [totalIncome, setTotalIncome] = useState();
  const [incomeDetails, setIncomeDetails] = useState();
  const [incomeServices, setIncomeServices] = useState([]);
  const [incomeTrend, setIncomeTrend] = useState([]);
  const [incomeProducts, setIncomeProducts] = useState([]);

  const [totalExpense, setTotalExpense] = useState();
  const [expenseDetails, setExpenseDetails] = useState();
  const [expenseProducts, setExpenseProducts] = useState([]);
  const [expenseOthers, setExpenseOthers] = useState([]);
  const [expenseTrend, setExpenseTrend] = useState([]);

  const [totalProfit, setTotalProfit] = useState();
  const [profitTrend, setProfitTrend] = useState([]);
  const [profitDetails, setProfitDetails] = useState();
  const [profitUsedProducts, setProfitUsedProducts] = useState([]);
  const loadAccountsReport = async (dates) => {
    let url = process.env.REACT_APP_BASEURL + "reports/accounts";
    const response = await postSubmitForm(url, dates);
    if (response && response.status === 1) {
      setTotalIncome(response.data.total_income);
      setIncomeDetails(response.data.income_details);
      setIncomeServices(response.data.income_details.income_service);
      setIncomeProducts(response.data.income_details.income_product);
      setIncomeTrend(response.data.income_details.income_trend);

      setTotalExpense(response.data.total_expense);
      setExpenseDetails(response.data.expense_Details);
      setExpenseProducts(response.data.expense_Details.expense_product);
      setExpenseOthers(response.data.expense_Details.expense_other);
      setExpenseTrend(response.data.expense_Details.expense_trend);

      setTotalProfit(response.data.total_profit);
      setProfitTrend(response.data.profit_details.profit_trend);
      //setProfitDetails(response.data.profit_details);
      //setProfitUsedProducts(response.data.profit_details.used_product);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [activeTab, setactiveTab] = useState("0");
  const [activeTabIncome, setActiveTabIncome] = useState("1");
  const [activeTabExpense, setActiveTabExpense] = useState("1");
  const [activeTabProfit, setActiveTabProfit] = useState("1");
  const pagination_options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: props.enquiries && props.enquiries.length,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 2, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
  };

  const columnsIncomeService = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "invoice_number",
      text: props.t("Invoice #"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "client_details.client_name",
      text: props.t("Client Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "client_details.client_mobile",
      text: props.t("Client Mobile"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Service Amount"),
      dataField: "service_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "discount",
      text: props.t("Discount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Amount"),
      formatter: (cell, row) => {
        return +row.service_amount - +row.discount;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
  ];
  const columnsIncomeProduct = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "invoice_number",
      text: props.t("Invoice #"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "client_details.client_name",
      text: props.t("Client Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "client_details.client_mobile",
      text: props.t("Client Mobile"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      sort: true,

      style: { cursor: "pointer" },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Product Amount"),
      dataField: "sold_product_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      style: { cursor: "pointer" },
    },
  ];

  const columnsExpenseProduct = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Product Name"),
      dataField: "product_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "product_details.product_category_details.name",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "batch",
      text: props.t("Batch"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "quantity_for_sale",
      text: props.t("Quantity (Sale)"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "quantity_for_use",
      text: props.t("Quantity (Use)"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "cost_price",
      text: props.t("Total"),
      formatter: (cell, row) => {
        return (
          +row.cost_price * +row.quantity_for_sale +
          +row.cost_price * +row.quantity_for_use
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const columnsExpenseOthers = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Source Name"),
      dataField: "source.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },

    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "date_of_transaction",
      text: props.t("Date"),
      sort: true,
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD-MM-YYYY");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const columnsProfitUsedProducts = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      text: props.t("Product Name"),
      dataField: "product_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "19%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "product_details.product_category_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "sold_product_details.total_price",
      text: props.t("Total Amount"),
      formatter: (cell, row) => {
        return row.quantity * row.cost_price;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const handleDownloadIncomeServices = async () => {
    const fileName = "income_services";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(incomeServices));
    data.forEach(function (v) {
      v.client_name = v.client_details.client_name;
      v.client_mobile = v.client_details.client_mobile;

      delete v._id;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sold_product_details;
      delete v.appointment_id;
      delete v.client_details;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  const handleDownloadIncomeProducts = async () => {
    const fileName = "income_products";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(incomeProducts));
    data.forEach(function (v) {
      v.client_name = v.client_details.client_name;
      v.client_mobile = v.client_details.client_mobile;

      delete v._id;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sold_product_details;
      delete v.appointment_id;
      delete v.client_details;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  const handleDownloadExpenses = async () => {
    const fileName = "expenses";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(expenseOthers));
    data.forEach(function (v) {
      v.source_name = v.source.name;
      v.source_description = v.source.description;
      v.date_of_transaction = moment(v.date_of_transaction).format(
        "DD/MM/YYYY"
      );

      delete v._id;
      delete v.source;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sold_product_details;
      delete v.appointment_id;
      delete v.client_details;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  function showBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.beautician_details.map((beautician) => {
          if (beautician.type === "Secondary") {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          }
        })}
      </>
    );
  }
  const column_service_details = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Service Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "duration",
      text: props.t("Duration (minutes)"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Beauticians"),
      formatter: showBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
    },
  ];
  const expandRowServices = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.service_details}
          columns={column_service_details}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };

  const column_product_details = [
    {
      dataField: "product_id",
      hidden: true,
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "total_price",
      text: props.t("Total Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const expandRowProducts = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.sold_product_details}
          columns={column_product_details}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Accounts Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col lg={12}>
                    <AvForm>
                      <Row>
                        <Col lg={6}>
                          {" "}
                          <AvField
                            name="datefrom"
                            label={props.t("From")}
                            type="date"
                            value={dates && dates.date_from}
                            onChange={(e, v) => {
                              setDates((prevalue) => {
                                return {
                                  ...prevalue,
                                  date_from: formatDate(e.target.value),
                                };
                              });
                            }}
                          />
                        </Col>
                        <Col lg={6}>
                          <AvField
                            name="dateto"
                            label={props.t("To")}
                            type="date"
                            value={dates && dates.date_to}
                            onChange={(e, v) => {
                              setDates((prevalue) => {
                                return {
                                  ...prevalue,
                                  date_to: formatDate(e.target.value),
                                };
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="checkout-tabs">
            <Row>
              <Col lg="12">
                <Nav className="flex-column" pills>
                  <Row>
                    <Col lg={4}>
                      <NavItem>
                        <NavLink
                          style={{ backgroundImage: `url(${infograph1})` }}
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            setactiveTab("1");
                          }}
                        >
                          <i className="mdi mdi-cash-usd-outline d-block check-nav-icon mt-4 mb-2"></i>
                          <p className="font-weight-bold mb-4">
                            {props.t("Income")} <br />
                            QR {totalIncome && totalIncome}
                          </p>
                        </NavLink>
                      </NavItem>
                    </Col>
                    <Col lg={4}>
                      <NavItem>
                        <NavLink
                          style={{ backgroundImage: `url(${infograph2})` }}
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            setactiveTab("2");
                          }}
                        >
                          <i className="mdi mdi-cash-minus d-block check-nav-icon mt-4 mb-2"></i>
                          <p className="font-weight-bold mb-4">
                            {props.t("Expenses")} <br />
                            QR {totalExpense && totalExpense}
                          </p>
                        </NavLink>
                      </NavItem>
                    </Col>
                    <Col lg={4}>
                      <NavItem>
                        <NavLink
                          style={{ backgroundImage: `url(${infograph3})` }}
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => {
                            setactiveTab("3");
                          }}
                        >
                          <i className="mdi mdi-cash-plus d-block check-nav-icon mt-4 mb-2"></i>
                          <p className="font-weight-bold mb-4">
                            {props.t("Profit")} <br />
                            QR {totalProfit && totalProfit}
                          </p>
                        </NavLink>
                      </NavItem>
                    </Col>
                  </Row>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Card className="shadow-none border mb-0">
                          <CardBody>
                            <div id="line-chart" className="e-chart mb-4">
                              <LineBarChart
                                line_data={incomeTrend && incomeTrend}
                                type="income_trend"
                                yAxisLabel="Income"
                              />
                            </div>
                            <Nav pills className="navtab-bg nav-justified">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      activeTabIncome &&
                                      activeTabIncome === "1",
                                  })}
                                  onClick={() => {
                                    setActiveTabIncome("1");
                                  }}
                                >
                                  Income from Services:
                                  <b>
                                    {" QR "}
                                    {incomeDetails &&
                                      incomeDetails.total_income_service}
                                  </b>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      activeTabIncome &&
                                      activeTabIncome === "2",
                                  })}
                                  onClick={() => {
                                    setActiveTabIncome("2");
                                  }}
                                >
                                  Income from Products:
                                  <b>
                                    {" QR "}
                                    {incomeDetails &&
                                      incomeDetails.total_income_product}
                                  </b>
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                              activeTab={activeTabIncome && activeTabIncome}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId="1">
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <Button
                                      type="submit"
                                      className="btn btn-sm btn-secondary"
                                      onClick={handleDownloadIncomeServices}
                                    >
                                      <i className="mdi mdi-file-export"></i>{" "}
                                      {props.t("Export CSV")}
                                    </Button>
                                    <div className="search-box mr-2 mb-2 d-inline-block">
                                      <div className="position-relative"></div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <ToolkitProvider
                                      keyField="_id"
                                      data={incomeServices && incomeServices}
                                      columns={columnsIncomeService}
                                      noDataIndication={props.t(
                                        "No data to display."
                                      )}
                                      bootstrap4
                                      search
                                    >
                                      {(propst) => (
                                        <div>
                                          <SearchBar
                                            {...propst.searchProps}
                                            style={{ width: "300px" }}
                                          />

                                          <BootstrapTable
                                            striped
                                            keyField="_id"
                                            expandRow={expandRowServices}
                                            hover
                                            condensed
                                            {...propst.baseProps}
                                          />
                                        </div>
                                      )}
                                    </ToolkitProvider>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="2">
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <Button
                                      type="submit"
                                      className="btn btn-sm btn-secondary"
                                      onClick={handleDownloadIncomeProducts}
                                    >
                                      <i className="mdi mdi-file-export"></i>{" "}
                                      {props.t("Export CSV")}
                                    </Button>
                                    <div className="search-box mr-2 mb-2 d-inline-block">
                                      <div className="position-relative"></div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <ToolkitProvider
                                      keyField="_id"
                                      data={incomeProducts && incomeProducts}
                                      columns={columnsIncomeProduct}
                                      noDataIndication={props.t(
                                        "No data to display."
                                      )}
                                      bootstrap4
                                      search
                                    >
                                      {(propst) => (
                                        <div>
                                          <SearchBar
                                            {...propst.searchProps}
                                            style={{ width: "300px" }}
                                          />
                                          <BootstrapTable
                                            keyField="_id"
                                            striped
                                            hover
                                            expandRow={expandRowProducts}
                                            condensed
                                            {...propst.baseProps}
                                          />{" "}
                                        </div>
                                      )}
                                    </ToolkitProvider>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane
                        tabId="2"
                        id="v-pills-payment"
                        role="tabpanel"
                        aria-labelledby="v-pills-payment-tab"
                      >
                        <Card className="shadow-none border mb-0">
                          <CardBody>
                            <div id="line-chart" className="e-chart mb-4">
                              <LineBarChart
                                line_data={expenseTrend && expenseTrend}
                                type="expense_trend"
                                yAxisLabel="Expense"
                              />
                            </div>
                            <Row className="mb-2">
                              <Col sm="4">
                                <Button
                                  type="submit"
                                  className="btn btn-sm btn-secondary"
                                  onClick={handleDownloadExpenses}
                                >
                                  <i className="mdi mdi-file-export"></i>{" "}
                                  {props.t("Export CSV")}
                                </Button>
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative"></div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <ToolkitProvider
                                  keyField="_id"
                                  data={expenseOthers && expenseOthers}
                                  columns={columnsExpenseOthers}
                                  noDataIndication={props.t(
                                    "No data to display."
                                  )}
                                  bootstrap4
                                  search
                                >
                                  {(propst) => (
                                    <div>
                                      <SearchBar
                                        {...propst.searchProps}
                                        style={{ width: "300px" }}
                                      />
                                      <BootstrapTable
                                        keyField="_id"
                                        striped
                                        hover
                                        condensed
                                        {...propst.baseProps}
                                      />{" "}
                                    </div>
                                  )}
                                </ToolkitProvider>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
                        <Card className="shadow-none border mb-0">
                          <CardBody>
                            <Row>
                              <Col sm="12">
                                <CardTitle className="mb-4">
                                  {props.t("Profit ") +
                                    " = " +
                                    props.t("Income") +
                                    " - " +
                                    props.t("Expenses")}{" "}
                                  <br />
                                  {totalProfit && totalProfit} ={" "}
                                  {totalIncome && totalIncome} -{" "}
                                  {totalExpense && totalExpense}
                                </CardTitle>
                                <div id="line-chart" className="e-chart mb-4">
                                  <LineBarChart
                                    line_data={profitTrend && profitTrend}
                                    type="profit_trend"
                                    yAxisLabel="Profit"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(AccountsReport)));

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import moment from "moment";
import { postSubmitForm } from "../../helpers/forms_helper";

const InventoryReport = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadProducts();
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProducts(response.data);
      setFilteredProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [inventoryType, setInventoryType] = useState("all");
  const [belowThreshold, setBelowThreshold] = useState(false);
  const filterProducts = async () => {
    if (belowThreshold) {
      let arr = allProducts.filter((p) =>
        inventoryType === "all"
          ? (p.is_for_sale || p.is_for_use) &&
            (p.available_quantity_for_sale < p.threshold_quantity_for_sale ||
              p.available_quantity_for_use < p.threshold_quantity_for_use)
          : inventoryType === "for_sale"
          ? p.is_for_sale &&
            p.available_quantity_for_sale < p.threshold_quantity_for_sale
          : p.is_for_use &&
            p.available_quantity_for_use < p.threshold_quantity_for_use
      );
      setFilteredProducts(arr);
    } else {
      let arr = allProducts.filter((p) =>
        inventoryType === "all"
          ? p.is_for_sale || p.is_for_use
          : inventoryType === "for_sale"
          ? p.is_for_sale
          : p.is_for_use
      );
      setFilteredProducts(arr);
    }
  };
  useEffect(() => {
    filterProducts();
  }, [inventoryType, belowThreshold]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "product_category_details.name",
      text: props.t("Product Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "available_quantity_for_sale",
      text: props.t(""),
      headerFormatter: () => {
        return (
          <span>
            Available
            <br />
            (for sale)
          </span>
        );
      },
      sort: true,
      formatter: (cell, row) => {
        return row.is_for_sale ? (
          row.available_quantity_for_sale < row.threshold_quantity_for_sale ? (
            <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
              {row.available_quantity_for_sale}
            </span>
          ) : (
            row.available_quantity_for_sale
          )
        ) : (
          props.t("NA")
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      hidden:
        inventoryType === "for_sale" || inventoryType === "all" ? false : true,
    },
    {
      // dataField: "threshold_quantity_for_sale",
      text: props.t(""),
      headerFormatter: () => {
        return (
          <span>
            Threshold
            <br />
            (for sale)
          </span>
        );
      },
      formatter: (cell, row) => {
        return row.is_for_sale
          ? row.threshold_quantity_for_sale
          : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      hidden:
        inventoryType === "for_sale" || inventoryType === "all" ? false : true,
    },

    {
      dataField: "available_quantity_for_use",
      text: props.t(""),
      headerFormatter: () => {
        return (
          <span>
            Available <br />
            (for use)
          </span>
        );
      },
      sort: true,
      formatter: (cell, row) => {
        return row.is_for_use ? (
          row.available_quantity_for_use < row.threshold_quantity_for_use ? (
            <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
              {row.available_quantity_for_use}
            </span>
          ) : (
            row.available_quantity_for_use
          )
        ) : (
          props.t("NA")
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      hidden:
        inventoryType === "for_use" || inventoryType === "all" ? false : true,
    },
    {
      text: props.t(""),
      headerFormatter: () => {
        return (
          <span>
            Threshold
            <br />
            (for use)
          </span>
        );
      },
      formatter: (cell, row) => {
        return row.is_for_use ? row.threshold_quantity_for_use : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      hidden:
        inventoryType === "for_use" || inventoryType === "all" ? false : true,
    },

    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];

  const handleDownload = () => {
    const fileName = "inventory";
    const exportType = "xls";
    if (filteredProducts) {
      var data = JSON.parse(JSON.stringify(filteredProducts));
      data.forEach(function (v) {
        v.name = v.name;
        v.available_quantity_for_sale = v.is_for_sale
          ? v.available_quantity_for_sale
          : props.t("NA");
        v.threshold_quantity_for_sale = v.is_for_sale
          ? v.threshold_quantity_for_sale
          : props.t("NA");
        v.available_quantity_for_use = v.is_for_use
          ? v.available_quantity_for_use
          : props.t("NA");
        v.threshold_quantity_for_use = v.is_for_use
          ? v.threshold_quantity_for_use
          : props.t("NA");
        v.available_quantity_for_use = v.available_quantity_for_use;
        v.is_for_sale = v.is_for_sale ? props.t("Yes") : props.t("No");
        v.is_for_use = v.is_for_use ? props.t("Yes") : props.t("No");

        v.category = v.product_category_details.name;

        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.product_category_details;
      });
      exportFromJSON({ data, fileName, exportType });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Inventory")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Inventory Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={filteredProducts && filteredProducts}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={8} className="text-right">
                            <Row>
                              <Col lg={7} className="text-right">
                                <div className="form-check text-right">
                                  <input
                                    type="checkbox"
                                    name="belowThreshold"
                                    id="belowThreshold"
                                    className="is-untouched is-pristine av-valid form-check-input"
                                    checked={belowThreshold}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setBelowThreshold(true);
                                      } else {
                                        setBelowThreshold(false);
                                      }
                                    }}
                                  />
                                  <label for="belowThreshold">
                                    Only products below threshold quantity.
                                  </label>
                                </div>
                              </Col>
                              <Col lg={5}>
                                <AvForm>
                                  <AvRadioGroup
                                    inline
                                    name="inventory_type"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Inventory type cannot be empty"
                                    value={inventoryType && inventoryType}
                                  >
                                    <AvRadio
                                      label="All"
                                      value="all"
                                      onChange={(e, v) => {
                                        setInventoryType(e.target.value);
                                      }}
                                    />
                                    <AvRadio
                                      label="For Sale"
                                      value="for_sale"
                                      onChange={(e, v) => {
                                        setInventoryType(e.target.value);
                                      }}
                                    />
                                    <AvRadio
                                      label="For Use"
                                      value="for_use"
                                      onChange={(e, v) => {
                                        setInventoryType(e.target.value);
                                      }}
                                    />
                                  </AvRadioGroup>
                                </AvForm>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(InventoryReport))
);

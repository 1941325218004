import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Employees
import AddEmployee from "../pages/Employees/AddEmployee";
import AllEmployees from "../pages/Employees/AllEmployees";
import GenerateSalary from "../pages/Employees/GenerateSalary";
import ArrearDeduction from "../pages/Employees/ArrearDeduction";
import ViewSalary from "../pages/Employees/ViewSalary";
import Incentives from "../pages/Employees/Incentives";

//Sources
import AddSource from "../pages/Sources/AddSource";
import AllSources from "../pages/Sources/AllSources";

// Expenses
import AddExpense from "../pages/Expenses/AddExpense";
import AllExpenses from "../pages/Expenses/AllExpenses";

// Designations
import AddDesignation from "../pages/Designations/AddDesignation";
import AllDesignations from "../pages/Designations/AllDesignations";

// Shifts
import AddShift from "../pages/Shifts/AddShift";
import AllShifts from "../pages/Shifts/AllShifts";

// Clients
import AddClient from "../pages/Clients/AddClient";
import AllClients from "../pages/Clients/AllClients";
import WalletTransaction from "../pages/Clients/WalletTransaction";
import WalletHistory from "../pages/Clients/WalletHistory";

// Service Categories
import AddServiceCategory from "../pages/ServiceCategories/AddServiceCategory";
import AllServiceCategories from "../pages/ServiceCategories/AllServiceCategories";

// Services
import AddService from "../pages/Services/AddService";
import AllServices from "../pages/Services/AllServices";

// Product Categories
import AddProductCategory from "../pages/ProductCategories/AddProductCategory";
import AllProductCategories from "../pages/ProductCategories/AllProductCategories";

// Products
import AddProduct from "../pages/Products/AddProduct";
import AllProducts from "../pages/Products/AllProducts";

//Inventory
import UpdateInventory from "../pages/Inventory/UpdateInventory";
import ViewInventory from "../pages/Inventory/ViewInventory";
import UsedInventory from "../pages/Inventory/UsedInventory";

// Appointments
import AddAppointment from "../pages/Appointments/AddAppointment";
import OngoingAppointments from "../pages/Appointments/OngoingAppointments";
import AllAppointments from "../pages/Appointments/AllAppointments";

// Invoice
import SaleInvoice from "../pages/SaleInvoice";
import ClosurePrint from "../pages/ClosurePrint";

// Product Sales
import AddOrder from "../pages/ProductSales/AddOrder";
import AllOrders from "../pages/ProductSales/AllOrders";

// Users
import AddUser from "../pages/Users/AddUser";
import AllUsers from "../pages/Users/AllUsers";
import ChangePassword from "../pages/ChangePassword";

// Attendance
import AddAttendance from "../pages/Attendance/AddAttendance";
import ViewAttendance from "../pages/Attendance/ViewAttendance";

// Report
import AppointmentsReport from "../pages/Reports/AppointmentsReport";
import ServiceSalesChart from "../pages/Reports/ServiceSalesChart";
import CharityReport from "../pages/Reports/CharityReport";
import ExpiredProductsReport from "../pages/Reports/ExpiredProductsReport";
import ExpiringProductsReport from "../pages/Reports/ExpiringProductsReport";
import WastedProductsReport from "../pages/Reports/WastedProductsReport";
import AccountsReport from "../pages/Reports/AccountsReport";
import LoyaltyReport from "../pages/Reports/LoyaltyReport";
import SubmitClosure from "../pages/Reports/Closure";
import ClosureReport from "../pages/Reports/ClosureReport";
import EmployeeReport from "../pages/Reports/EmployeeReport";
import InventoryTransfer from "../pages/Reports/InventoryTransfer";
import ServiceSalesReport from "../pages/Reports/ServiceSalesReport";
import ProductSalesReport from "../pages/Reports/ProductSalesReport";
import InventoryReport from "../pages/Reports/InventoryReport";

import AppSettings from "../pages/Settings/AppSettings";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
const role = localStorage.getItem("role");

let routes = [];
if (role === "admin") {
  routes = [
    { path: "/dashboard", component: Dashboard },

    { path: "/add_employee", component: AddEmployee },
    { path: "/all_employees", component: AllEmployees },
    { path: "/generate_salary", component: GenerateSalary },
    { path: "/arrear_deduction", component: ArrearDeduction },
    { path: "/view_salary", component: ViewSalary },
    { path: "/incentives", component: Incentives },

    { path: "/add_source", component: AddSource },
    { path: "/all_sources", component: AllSources },
    { path: "/add_expense", component: AddExpense },
    { path: "/all_expenses", component: AllExpenses },

    { path: "/add_designation", component: AddDesignation },
    { path: "/all_designations", component: AllDesignations },

    { path: "/add_shift", component: AddShift },
    { path: "/all_shifts", component: AllShifts },

    { path: "/add_user", component: AddUser },
    { path: "/all_users", component: AllUsers },

    { path: "/add_client", component: AddClient },
    { path: "/all_clients", component: AllClients },
    { path: "/wallet_transaction", component: WalletTransaction },
    { path: "/wallet_history", component: WalletHistory },

    { path: "/add_service_category", component: AddServiceCategory },
    { path: "/all_service_categories", component: AllServiceCategories },
    { path: "/add_service", component: AddService },
    { path: "/all_services", component: AllServices },

    { path: "/add_product_category", component: AddProductCategory },
    { path: "/all_product_categories", component: AllProductCategories },
    { path: "/add_product", component: AddProduct },
    { path: "/all_products", component: AllProducts },

    { path: "/appointments", component: AddAppointment },
    { path: "/ongoing_appointments", component: OngoingAppointments },
    { path: "/all_appointments", component: AllAppointments },

    { path: "/update_inventory", component: UpdateInventory },
    { path: "/view_inventory", component: ViewInventory },
    { path: "/used_inventory", component: UsedInventory },

    // { path: "/invoice", component: SaleInvoice },
    { path: "/add_new_order", component: AddOrder },
    { path: "/all_orders", component: AllOrders },

    { path: "/add_attendance", component: AddAttendance },
    { path: "/view_attendance", component: ViewAttendance },

    { path: "/appointments_report", component: AppointmentsReport },
    { path: "/service_sales_chart", component: ServiceSalesChart },
    { path: "/charity_report", component: CharityReport },
    { path: "/expired_products_report", component: ExpiredProductsReport },
    { path: "/expiring_products_report", component: ExpiringProductsReport },
    { path: "/wasted_products_report", component: WastedProductsReport },
    { path: "/accounts_report", component: AccountsReport },
    { path: "/loyalty_report", component: LoyaltyReport },
    { path: "/submit_closure", component: SubmitClosure },
    { path: "/closure_report", component: ClosureReport },
    { path: "/employee_report", component: EmployeeReport },
    { path: "/inventory_transfer_report", component: InventoryTransfer },
    { path: "/service_sales_report", component: ServiceSalesReport },
    { path: "/product_sales_report", component: ProductSalesReport },
    { path: "/inventory_report", component: InventoryReport },

    { path: "/app_settings", component: AppSettings },

    { path: "/user-changepassword", component: ChangePassword },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ];
} else if (role === "manager") {
  routes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/add_employee", component: AddEmployee },
    { path: "/all_employees", component: AllEmployees },
    { path: "/generate_salary", component: GenerateSalary },
    { path: "/arrear_deduction", component: ArrearDeduction },
    { path: "/view_salary", component: ViewSalary },
    { path: "/incentives", component: Incentives },

    { path: "/add_source", component: AddSource },
    { path: "/all_sources", component: AllSources },
    { path: "/add_expense", component: AddExpense },
    { path: "/all_expenses", component: AllExpenses },

    { path: "/add_designation", component: AddDesignation },
    { path: "/all_designations", component: AllDesignations },

    { path: "/add_shift", component: AddShift },
    { path: "/all_shifts", component: AllShifts },

    { path: "/add_user", component: AddUser },
    { path: "/all_users", component: AllUsers },

    { path: "/add_client", component: AddClient },
    { path: "/all_clients", component: AllClients },
    { path: "/wallet_transaction", component: WalletTransaction },
    { path: "/wallet_history", component: WalletHistory },

    { path: "/add_service_category", component: AddServiceCategory },
    { path: "/all_service_categories", component: AllServiceCategories },
    { path: "/add_service", component: AddService },
    { path: "/all_services", component: AllServices },

    { path: "/add_product_category", component: AddProductCategory },
    { path: "/all_product_categories", component: AllProductCategories },
    { path: "/add_product", component: AddProduct },
    { path: "/all_products", component: AllProducts },

    { path: "/appointments", component: AddAppointment },
    { path: "/ongoing_appointments", component: OngoingAppointments },
    { path: "/all_appointments", component: AllAppointments },

    { path: "/update_inventory", component: UpdateInventory },
    { path: "/view_inventory", component: ViewInventory },
    { path: "/used_inventory", component: UsedInventory },

    // { path: "/invoice", component: SaleInvoice },
    { path: "/add_new_order", component: AddOrder },
    { path: "/all_orders", component: AllOrders },

    { path: "/add_attendance", component: AddAttendance },
    { path: "/view_attendance", component: ViewAttendance },

    { path: "/appointments_report", component: AppointmentsReport },
    { path: "/service_sales_chart", component: ServiceSalesChart },
    { path: "/charity_report", component: CharityReport },
    { path: "/expired_products_report", component: ExpiredProductsReport },
    { path: "/expiring_products_report", component: ExpiringProductsReport },
    { path: "/wasted_products_report", component: WastedProductsReport },
    { path: "/accounts_report", component: AccountsReport },
    { path: "/loyalty_report", component: LoyaltyReport },
    { path: "/submit_closure", component: SubmitClosure },
    { path: "/closure_report", component: ClosureReport },
    { path: "/employee_report", component: EmployeeReport },
    { path: "/inventory_transfer_report", component: InventoryTransfer },
    { path: "/service_sales_report", component: ServiceSalesReport },
    { path: "/product_sales_report", component: ProductSalesReport },
    { path: "/inventory_report", component: InventoryReport },

    { path: "/user-changepassword", component: ChangePassword },
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ];
} else if (role === "beautician") {
  routes = [
    { path: "/dashboard", component: Dashboard },
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ];
} else {
  routes = [
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ];
}
const userRoutes = routes;

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/invoice", component: SaleInvoice },
  { path: "/closure_print", component: ClosurePrint },
];

export { userRoutes, authRoutes };

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";

const LoyaltyReport = (props) => {
  const { SearchBar } = Search;

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadLoyaltyReport();
    }
  }, [dateFrom, dateTo]);

  const [loyaltyReport, setLoyaltyReport] = useState([]);

  const loadLoyaltyReport = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/loyalty";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      setLoyaltyReport(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const pagination_options = {
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: loyaltyReport && loyaltyReport.length,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 20, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 2, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
  };
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "12341",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return +rowIndex + 1;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Client Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "date_of_transaction",
      text: props.t("Date"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
    },
  ];

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "name",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        console.log(rowNumber);
        return rowNumber;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Client Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "total_visits",
      text: props.t("Total Visits"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "total_paid_amount",
      text: props.t("Total Amount"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };
  const handleDownload = async () => {
    const fileName = "loyalty_report";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(loyaltyReport));
    data.forEach(function (v) {
      // v.source_name = v.source.name;
      // v.source_description = v.source.description;
      // v.date_of_transaction = moment(v.date_of_transaction).format(
      //   "DD/MM/YYYY"
      // );

      delete v._id;
      delete v.details;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sold_product_details;
      delete v.appointment_id;
      delete v.client_details;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  // const handleDownload = async () => {
  //   const fileName = "appointments";
  //   const exportType = "xls";
  //   let data_to_send_api = [];
  //   allAppointments.map((appointment) => {
  //     data_to_send_api.push(appointment._id);
  //   });
  //   let data_to_export = [];
  //   let url = process.env.REACT_APP_BASEURL + "appointments/toexcel"; //"services/getall";
  //   const response = await postSubmitForm(url, {
  //     appointment_ids: data_to_send_api,
  //   });
  //   if (response && response.status === 1) {
  //     data_to_export = response.data;
  //   } else {
  //     showNotification(response.message, "Error");
  //   }
  //   if (data_to_export) {
  //     var data = JSON.parse(JSON.stringify(data_to_export));
  //     data.forEach(function (v) {
  //       v.client_name = v.client_details.client_name;
  //       v.client_mobile = v.client_details.client_mobile;

  //       v.service_name = v.service_details.name;
  //       v.service_duration = v.service_details.duration;
  //       v.service_cost = v.service_details.cost;

  //       delete v.is_active;
  //       delete v._id;
  //       delete v.createdAt;
  //       delete v.updatedAt;
  //       delete v.__v;
  //       delete v.sold_product_details;
  //       delete v.title;
  //       delete v.client_details;
  //       delete v.service_details;
  //       delete v.invoice_number;
  //       delete v.serviceandsale_id;
  //     });
  //     console.log(data);
  //     exportFromJSON({ data, fileName, exportType });
  //   }
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Client Loyalty Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Client Loyalty Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    keyField="_id"
                    data={loyaltyReport && loyaltyReport}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(pagination_options)}
                          expandRow={expandRow}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(LoyaltyReport)));

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../helpers/forms_helper";

const AllExpenses = (props) => {
  useEffect(() => {
    loadExpensesGrouped();
  }, []);
  const [expenseGrouped, setExpensesGrouped] = useState([]);
  const loadExpensesGrouped = async () => {
    let url = process.env.REACT_APP_BASEURL + "expenses/get_grouped";
    const response = await postSubmitForm(url, {});

    if (response && response.status === 1) {
      setExpensesGrouped(response.data);
    } else {
      showNotification(props.t(response.message), "Error");
    }
  };

  const [selectedExpense, setselectedExpense] = useState();
  const [selectedExpenseTransations, setselectedExpenseTransactions] = useState(
    []
  );
  const [isModal, setModal] = useState();

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Source Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
    },
    {
      text: props.t("Total Amount"),
      //formatter: employeeFormatter,
      dataField: "total_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="mdi mdi-text-box-search font-size-15"
              title="View Details"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setselectedExpense(row);
                setselectedExpenseTransactions(row.details);

                setModal(!isModal);
              }}
            ></i>
          </span>
        );
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  const columns_details = [
    {
      text: props.t("Source Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Amount"),
      //formatter: employeeFormatter,
      dataField: "amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Date of Transaction"),
      //formatter: employeeFormatter,
      dataField: "date_of_transaction",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },

    {
      text: props.t("Remarks"),
      //formatter: employeeFormatter,
      dataField: "remarks",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Description"),
      //formatter: employeeFormatter,
      dataField: "description",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const pagination_options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: selectedExpenseTransations && selectedExpenseTransations,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Expenses")}
            breadcrumbItem={props.t("All Expenses")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Expenses")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing expenses here")}
                  </CardSubtitle>

                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={expenseGrouped && expenseGrouped}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                  <Modal
                    size="lg"
                    isOpen={isModal}
                    toggle={() => setModal(!isModal)}
                  >
                    <ModalHeader toggle={() => setModal(!isModal)}>
                      {props.t("Expense History")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedExpense && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedExpenseTransations &&
                                          selectedExpenseTransations
                                        }
                                        columns={columns_details}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              pagination={paginationFactory(
                                                pagination_options
                                              )}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AllExpenses)));

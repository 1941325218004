import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import swal from "sweetalert2";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postSubmitForm } from "../../helpers/forms_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import moment from "moment";

const ArrearDeduction = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const [currentDate, setCurrentDate] = useState(() => {
    var date = new Date();
    //var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return moment(date).format("YYYY-MM-DD");
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    var date = new Date();
    //var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return moment(date).format("YYYY-MM-DD");
  });
  useEffect(() => {
    loadAllEmployeesForSalary();
  }, [selectedDate]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [totalTarget, setTotalTarget] = useState();

  const loadAllEmployeesForSalary = async () => {
    preloader(true);
    let url =
      process.env.REACT_APP_BASEURL +
      "employees/get_achievedtarget_incentive_deduction";
    const response = await postSubmitForm(url, {
      date_of_transaction: selectedDate,
    });
    if (response && response.status === 1) {
      preloader(false);
      setAllEmployees(response.data.employee_details);
      setTotalTarget(response.data.total_achieved_target);
    } else {
      preloader(false);
      showNotification(props.t(response.message), props.t("Error"));
    }
  };
  const handleSubmitArrearDeduction = async (e, v) => {
    let data_to_send = {};
    data_to_send.date_of_transaction = selectedDate;
    data_to_send.arreardeductions = allEmployees;
    data_to_send.total_achieved_target = totalTarget;

    try {
      let url = process.env.REACT_APP_BASEURL + "arreardeductions/insert";
      const response = await postSubmitForm(url, data_to_send);

      if (response && response.status === 1) {
        showNotification(props.t(response.message), props.t("Success"));
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };

  const columns = [
    {
      dataField: "employee_id",
      hidden: true,
    },
    {
      dataField: "employee_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
    },
    // {
    //   dataField: "mobile",
    //   text: props.t("Mobile"),
    //   sort: true,
    // },
    // {
    //   dataField: "designation",
    //   text: props.t("Desig."),
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "9%" };
    //   },
    //   editable: false,
    // },
    {
      dataField: "salary",
      text: props.t("Salary"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "target",
      text: props.t("Target"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
    {
      dataField: "achieved_target_till_yesterday",
      text: props.t(),
      headerFormatter: () => {
        return (
          <>
            Achieved Target <br />
            <span style={{ fontSize: "10px" }}>
              (Till{" "}
              {moment(new Date(selectedDate)).add(-1, "day").format("DD MMM.")})
            </span>
          </>
        );
      },
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "11%" };
      },
    },
    {
      dataField: "achieved_target",
      text: props.t(),
      sort: true,
      headerFormatter: () => {
        return (
          <>
            Achieved Target <br />
            <span style={{ fontSize: "10px" }}>
              (On {moment(new Date(selectedDate)).format("DD MMM.")})
            </span>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "11%" };
      },
      editable: true,
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = { title: "Click to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = { cursor: "pointer" };
        return cur_style;
      },
    },
    {
      dataField: "incentive_till_yesterday",
      text: props.t(),
      headerFormatter: () => {
        return (
          <>
            Incentive <br />
            <span style={{ fontSize: "10px" }}>
              (Till{" "}
              {moment(new Date(selectedDate)).add(-1, "day").format("DD MMM.")})
            </span>
          </>
        );
      },
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "incentive",
      text: props.t("Incentive (Today)"),
      sort: true,
      headerFormatter: () => {
        return (
          <>
            Incentive <br />
            <span style={{ fontSize: "10px" }}>
              (On {moment(new Date(selectedDate)).format("DD MMM.")})
            </span>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = { title: "Click to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = { cursor: "pointer" };
        return cur_style;
      },
      editable: true,
    },
    {
      dataField: "deduction_till_yesterday",
      headerFormatter: () => {
        return (
          <>
            Deduction <br />
            <span style={{ fontSize: "10px" }}>
              (Till{" "}
              {moment(new Date(selectedDate)).add(-1, "day").format("DD MMM.")})
            </span>
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "deduction",
      text: props.t(),
      headerFormatter: () => {
        return (
          <>
            Deduction <br />
            <span style={{ fontSize: "10px" }}>
              (On {moment(new Date(selectedDate)).format("DD MMM.")})
            </span>
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = { title: "Click to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = { cursor: "pointer" };
        return cur_style;
      },
      editable: true,
    },
  ];

  function showNotification(message, type) {
    var title = type;
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const handleDownload = async () => {
    const fileName = "incentive_report";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(allEmployees));
    data.forEach(function (v) {
      delete v._id;
      delete v.employee_id;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sales_transactions;
      delete v.use_transactions;
      delete v.status;
      delete v.quantity;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem={props.t("Incentives")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    {props.t("Incentives, Targets and Deductions")}{" "}
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t(
                      "View and edit employees' incentive, target and deduction here"
                    )}
                  </CardSubtitle>
                  <AvForm onValidSubmit={handleSubmitArrearDeduction}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col lg={3} sm={6}>
                                <AvField
                                  name="salary_date"
                                  label={props.t("Select Date")}
                                  type="date"
                                  value={selectedDate}
                                  onChange={(e, v) => {
                                    setSelectedDate(e.target.value);
                                  }}
                                  max={currentDate}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-2">
                              <Col sm="4">
                                <a
                                  className="btn btn-sm btn-secondary"
                                  onClick={handleDownload}
                                >
                                  <i className="mdi mdi-file-export"></i>{" "}
                                  {props.t("Export CSV")}
                                </a>
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative"></div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <BootstrapTable
                                bootstrap4
                                keyField="employee_id"
                                data={allEmployees && allEmployees}
                                columns={columns}
                                cellEdit={cellEditFactory({
                                  mode: "click",
                                  blurToSave: true,
                                })}
                                noDataIndication="No data to display."
                                striped
                                hover
                                condensed
                              />
                            </Row>
                            <Row>
                              <Col lg={3} sm={6}>
                                <div>
                                  <Label>&nbsp;</Label>
                                  <br />
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="mr-1"
                                  >
                                    {props.t("Submit")}
                                  </Button>{" "}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(ArrearDeduction)));

import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

import { AvForm, AvField, AvRadio } from "availity-reactstrap-validation";

import { postSubmitForm } from "../../helpers/forms_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import swal from "sweetalert2";
// Charts

import Label from "reactstrap/lib/Label";

const Closure = (props) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const [currentDate, setCurrentDate] = useState(() => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });
  const [dates, setDates] = useState();

  const [cashInHand, setCashInHand] = useState();
  const [remarks, setRemarks] = useState();
  const [closureDetails, setClosureDetails] = useState();
  const loadClosureDetails = async (dates) => {
    try {
      preloader(true);
      let url = process.env.REACT_APP_BASEURL + "closures/get";
      const response = await postSubmitForm(url, dates);

      if (response && response.status === 1) {
        preloader(false);
        setClosureDetails(response.data);
        setCashInHand(response.data.cash_in_hand);
        setRemarks(response.data.remarks);
      } else {
        preloader(false);
        showNotification("Something went wrong!", "Error");
      }
    } catch (error) {
      preloader(false);
      showNotification(error, "Error");
    }
  };
  const handleValidSubmit = async (e, v) => {
    let values_to_send = {};
    values_to_send.date_of_closure = dates.date_of_closure;
    values_to_send.total_income = closureDetails.total_income;
    values_to_send.total_expense = closureDetails.total_expense;
    values_to_send.total_profit = closureDetails.total_profit;
    values_to_send.in_salon_services = closureDetails.in_salon_services;
    values_to_send.home_services = closureDetails.home_services;
    values_to_send.completed_appointments =
      closureDetails.completed_appointments;
    values_to_send.cancelled_appointments =
      closureDetails.cancelled_appointments;
    values_to_send.customers_visited = closureDetails.customers_visited;
    values_to_send.paid_by_cash = closureDetails.paid_by_cash;
    values_to_send.paid_by_card = closureDetails.paid_by_card;
    values_to_send.wallet_transactions = closureDetails.wallet_transactions;
    values_to_send.products_sold = closureDetails.products_sold;
    values_to_send.charity_expense = closureDetails.charity_expense;
    values_to_send.cash_in_hand = cashInHand;
    values_to_send.remarks = remarks;

    let url = process.env.REACT_APP_BASEURL + "closures/insert";
    const response = await postSubmitForm(url, values_to_send);

    if (response && response.status === 1) {
      showNotification(props.t(response.message), props.t("Success"));
      setRemarks();
      setCashInHand();
      loadClosureDetails(dates);
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
  };
  useEffect(() => {
    const currentDay = new Date();
    const date_of_closure = formatDate(currentDay);

    setDates({
      date_of_closure,
    });

    loadClosureDetails({
      date_of_closure,
    });

    //console.log(props.sales_output);
  }, []);

  useEffect(() => {
    if (dates) {
      loadClosureDetails(dates);
    }
  }, [dates]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Closure")}
            breadcrumbItem={props.t("Submit Closure")}
          />
          <AvForm
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v);
            }}
          >
            <Row>
              <Col className="col-lg-6 col-sm-12">
                <Card>
                  <CardBody>
                    <Col lg={12}>
                      <Row>
                        <Col lg={7}>
                          <AvField
                            name="dateto"
                            label={props.t("Date")}
                            type="date"
                            max={currentDate}
                            value={dates && dates.date_of_closure}
                            onChange={(e, v) => {
                              setDates((prevalue) => {
                                return {
                                  ...prevalue,
                                  date_of_closure: formatDate(e.target.value),
                                };
                              });
                            }}
                          />
                        </Col>
                        <Col lg={5}>
                          {closureDetails && closureDetails.is_closed ? (
                            <>
                              {" "}
                              <Label>&nbsp;</Label> <br />
                              <a
                                className="btn btn-primary mr-1"
                                href={
                                  "/closure_print?closure=" +
                                  closureDetails.closure_id
                                }
                                target="_blank"
                              >
                                <i className="mdi mdi-printer"></i>
                                {props.t(" Print")}
                              </a>
                            </>
                          ) : (
                            <>
                              <Label>&nbsp;</Label> <br />
                              <Button
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                {props.t("Submit Closure")}
                              </Button>{" "}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>

              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#ffd4d4" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Total Income")}
                        </p>
                        <h4 class="mb-0">
                          QR {closureDetails && closureDetails.total_income}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-cash-plus font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#ffd4d4" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Total Expense")}
                        </p>
                        <h4 class="mb-0">
                          QR {closureDetails && closureDetails.total_expense}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-cash-minus font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#ffd4d4" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Profit")}
                        </p>
                        <h4 class="mb-0">
                          QR {closureDetails && closureDetails.total_profit}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-cash-multiple font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#fff4d4" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Cash Payment")}
                        </p>
                        <h4 class="mb-0">
                          QR {closureDetails && closureDetails.paid_by_cash}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-cash-100 font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#fff4d4" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Card Payment")}
                        </p>
                        <h4 class="mb-0">
                          QR {closureDetails && closureDetails.paid_by_card}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-credit-card font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#d4e6ff" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Client Visits")}
                        </p>
                        <h4 class="mb-0">
                          {closureDetails && closureDetails.customers_visited}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span
                          class="avatar-title"
                          // style={{ backgroundColor: "#ec4561" }}
                        >
                          <i class="mdi mdi-clipboard-account font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#d4fffb" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("In Salon Services")}
                        </p>
                        <h4 class="mb-0">
                          {closureDetails && closureDetails.in_salon_services}
                        </h4>
                      </div>
                      <div
                        style={{ height: "30px", width: "30px" }}
                        class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                      >
                        <span
                          class="avatar-title"
                          style={{ backgroundColor: "#3c4ccf" }}
                        >
                          <i class="mdi mdi-cart font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#d4fffb" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Home Services")}
                        </p>
                        <h4 class="mb-0">
                          {closureDetails && closureDetails.home_services}
                        </h4>
                      </div>
                      <div
                        style={{ height: "30px", width: "30px" }}
                        class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                      >
                        <span
                          class="avatar-title"
                          style={{ backgroundColor: "#02a499" }}
                        >
                          <i class="mdi mdi-cart font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#d4fffb" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Completed Appt.")}
                        </p>
                        <h4 class="mb-0">
                          {closureDetails &&
                            closureDetails.completed_appointments}
                        </h4>
                      </div>
                      <div
                        style={{ height: "30px", width: "30px" }}
                        class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                      >
                        <span
                          class="avatar-title"
                          style={{ backgroundColor: "#b0ff9e" }}
                        >
                          <i class="bx bx-timer font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#d4fffb" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Cancelled Appt.")}
                        </p>
                        <h4 class="mb-0">
                          {closureDetails &&
                            closureDetails.cancelled_appointments}
                        </h4>
                      </div>
                      <div
                        style={{ height: "30px", width: "30px" }}
                        class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                      >
                        <span
                          class="avatar-title"
                          style={{ backgroundColor: "#ff9e9e" }}
                        >
                          <i class="bx bx-timer font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#f5d4ff" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Wallet Debits")}
                        </p>
                        <h4 class="mb-0">
                          QR{" "}
                          {closureDetails &&
                            closureDetails.wallet_transactions.debit}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-wallet font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#f5d4ff" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Wallet Credits")}
                        </p>
                        <h4 class="mb-0">
                          QR{" "}
                          {closureDetails &&
                            closureDetails.wallet_transactions.credit}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-wallet font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#ffd4e6" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Products Sold")}
                        </p>
                        <h4 class="mb-0">
                          {closureDetails && closureDetails.products_sold}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="bx bx-cart font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="3">
                <div
                  class="mini-stats-wid card"
                  style={{ background: "#a7ffda" }}
                >
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-bold">
                          {props.t("Charity Expense")}
                        </p>
                        <h4 class="mb-0">
                          QR {closureDetails && closureDetails.charity_expense}
                        </h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span class="avatar-title">
                          <i class="mdi mdi-charity font-size-20"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="6">
                <div class="mini-stats-wid card">
                  <div class="card-body">
                    <Row>
                      <Col lg={10}>
                        <div class="media">
                          <div class="media-body">
                            <Row>
                              <Col lg={5}>
                                {closureDetails && closureDetails.is_closed ? (
                                  <>
                                    <p class="text-muted font-weight-bold">
                                      {props.t("Cash in Hand")}
                                    </p>
                                    <h4 class="mb-0">
                                      QR {cashInHand && cashInHand}
                                    </h4>
                                  </>
                                ) : (
                                  <AvField
                                    type="text"
                                    label={props.t("Cash in Hand")}
                                    name="cash_in_hand"
                                    value={cashInHand}
                                    onChange={(e) => {
                                      setCashInHand(e.target.value);
                                    }}
                                  />
                                )}
                              </Col>
                              <Col lg={7}>
                                {closureDetails && closureDetails.is_closed ? (
                                  <>
                                    <p class="text-muted font-weight-bold">
                                      {props.t("Remarks")}
                                    </p>
                                    <span>{remarks}</span>
                                  </>
                                ) : (
                                  <AvField
                                    type="textarea"
                                    label={props.t("Remarks")}
                                    name="remarks"
                                    value={remarks}
                                    onChange={(e) => {
                                      setRemarks(e.target.value);
                                    }}
                                    required={
                                      closureDetails
                                        ? +cashInHand ===
                                          +closureDetails.cash_in_hand
                                          ? false
                                          : true
                                        : true
                                    }
                                    errorMessage="Remarks cannot be empty."
                                  />
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div class="media">
                          <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span class="avatar-title">
                              <i class="mdi mdi-account-cash font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(Closure)));

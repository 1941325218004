import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
} from "reactstrap";
import swal from "sweetalert2";
import Moment from "react-moment";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../helpers/forms_helper";

const WalletHistory = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadClients();
  }, []);
  const [allClients, setAllClients] = useState([]);
  const loadClients = async () => {
    let url = process.env.REACT_APP_BASEURL + "clients/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      const clients = response.data.filter(function (client) {
        return client.is_wallet_active;
      });
      setAllClients(clients);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [walletModal, setWalletModal] = useState();
  const [selectedClient, setSelectedClient] = useState();
  function walletFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="mdi mdi-text-box-search font-size-15"
          title="Click to View"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWalletModal(!walletModal);
            setSelectedClient(row);
          }}
        ></i>
      </span>
    );
  }
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "qatarid",
      text: props.t("Qatar ID"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "passport",
      text: props.t("Passport"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "wallet",
      text: props.t("Wallet"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: walletFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  const wallet_columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Amount"),
      dataField: "transaction_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "transaction_type",
      text: props.t("Transaction Type"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "transaction_remarks",
      text: props.t("Remarks"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Transaction Date"),
      dataField: "transaction_date",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Clients")}
            breadcrumbItem={props.t("Wallet History")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Wallet History")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View wallet history of your clients here")}
                  </CardSubtitle>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={allClients && allClients}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          {...props.searchProps}
                          style={{ width: "300px" }}
                        />

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={walletModal}
                    toggle={() => setWalletModal(!walletModal)}
                  >
                    <ModalHeader toggle={() => setWalletModal(!walletModal)}>
                      Client Name: {selectedClient && selectedClient.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedClient && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="_id"
                                    data={
                                      selectedClient &&
                                      selectedClient.wallet_transactions
                                    }
                                    columns={wallet_columns}
                                    noDataIndication="No data to display."
                                    striped
                                    hover
                                    condensed
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(WalletHistory))
);

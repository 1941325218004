import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AddUser = (props) => {
  useEffect(() => {
    loadEmployees();
  }, []);
  const [employees, setEmployees] = useState();
  const loadEmployees = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setEmployees(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const refContainer = useRef(null);
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "attendances/insert";

    const selected_employee = employees.filter(
      (employee) => employee._id === v.employee_id
    );
    v.employee_details = {
      name: selected_employee[0].name,
      code: selected_employee[0].code,
    };
    if (v.out_time === "") {
      v.out_time = null;
    }
    delete v.employee_id;

    const response = await postSubmitForm(url, v);

    if (response && response.status === 1) {
      showNotification(response.message, props.t("Success"));
    } else {
      showNotification(response.message, props.t("Error"));
    }
    refContainer.current.reset();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Attendance")}
            breadcrumbItem={props.t("Add Attendance")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Attendance")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add attendance for an employee"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="employee_id"
                          label={props.t("Employee") + " *"}
                          placeholder={props.t("Enter Employee")}
                          type="select"
                          //onChange={handleChange}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Select Employee")}
                        >
                          <option value="">
                            -- {props.t("Select Employee")} --
                          </option>
                          {employees &&
                            employees.map((r) => (
                              <option key={r._id} value={r._id}>
                                {r.name}
                              </option>
                            ))}
                        </AvField>
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="date_of_attendance"
                          label={props.t("Date of Attendance") + " *"}
                          placeholder={props.t("Enter Date")}
                          type="date"
                          errorMessage="Date cannot be empty."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <AvField
                          name="in_time"
                          label={props.t("In Time") + " *"}
                          placeholder={props.t("Enter In Time")}
                          type="time"
                          errorMessage={props.t("In time cannot be empty")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <AvField
                          name="out_time"
                          label={props.t("Out Time")}
                          placeholder={props.t("Enter Out Time")}
                          type="time"
                          // errorMessage={props.t("Out time cannot be empty")}
                          // validate={{
                          //   required: { value: true },
                          // }}
                        />
                      </Col>

                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(AddUser)));

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Button,
} from "reactstrap";
import swal from "sweetalert2";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AllServiceCategories = (props) => {
  useEffect(() => {
    loadAllServiceCategories();
  }, []);
  const [allServiceCategories, setAllServiceCategories] = useState([]);
  const loadAllServiceCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServiceCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const handleValidUpdate = async (e, v) => {
    v.id = selectedServiceCategory._id;
    let url = process.env.REACT_APP_BASEURL + "servicecategories/update";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      showNotification(response.message, "Success");
      loadAllServiceCategories();
      setEditModal(!editModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [editModal, setEditModal] = useState();
  const [selectedServiceCategory, setSelectedServiceCategory] = useState();
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit font-size-15"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal);
            setSelectedServiceCategory(row);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: false,
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Service Categories")}
            breadcrumbItem={props.t("All Service Categories")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Service Categories")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing service categories here")}
                  </CardSubtitle>
                  <hr />
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allServiceCategories && allServiceCategories}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                  <Modal
                    size="md"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Service Category")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedServiceCategory && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <AvField
                                          name="name"
                                          value={selectedServiceCategory.name}
                                          label={props.t("Name") + " *"}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage="Name cannot be empty."
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="remarks"
                                          value={
                                            selectedServiceCategory.remarks
                                          }
                                          label={props.t("Remarks")}
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AllServiceCategories))
);

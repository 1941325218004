import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";
import Moment from "react-moment";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../helpers/forms_helper";
import success_image from "../../assets/images/tick.png";

const OngoingAppointments = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadOngoingAppointments();
    loadProducts();
  }, []);

  const [confirmCheckout, setConfirmCheckout] = useState(false);
  const [checkOutSuccess, setCheckOutSuccess] = useState(false);
  const [checkOutResponse, setCheckOutResponse] = useState();
  const [allProducts, setAllProducts] = useState();
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/get_for_sale";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [productBatches, setProductBatches] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const loadBatches = async (product_details) => {
    let url = process.env.REACT_APP_BASEURL + "inventories/get_for_sale";
    console.log(product_details);
    const response = await postSubmitForm(url, {
      product_id: product_details.value,
    });
    if (response && response.status === 1) {
      setProductBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allOngoingAppointments, setAllOngoingAppointments] = useState([]);
  const loadOngoingAppointments = async () => {
    let url = process.env.REACT_APP_BASEURL + "appointments/get_in_process";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllOngoingAppointments(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [appointmentModal, setAppointmentModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedAppointmentServices, setSelectedAppointmentServices] =
    useState();
  const [totalServiceAmount, setTotalServiceAmount] = useState();

  const [selectedProduct, setSelectedProduct] = useState();
  const [quantity, setQuantity] = useState(0);
  const [productsForSale, setProductsForSale] = useState([]);
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const handleAddProductForSale = async () => {
    if (!selectedBatch) {
      showNotification(
        props.t("Please select product batch."),
        props.t("Error")
      );
    } else if (!quantity || quantity <= 0) {
      showNotification(props.t("Please add quantity."), props.t("Error"));
    } else {
      var new_array = JSON.parse(JSON.stringify(productsForSale));
      const existing_product = new_array.filter(function (product) {
        return product.batch === selectedBatch.batch;
      });
      if (existing_product.length > 0) {
        showNotification(
          props.t("This product batch is already added."),
          "Error"
        );
      } else {
        if (selectedBatch.quantity_for_sale < quantity) {
          showNotification(props.t("Quantity not in stock."), "Error");
        } else {
          setProductsForSale([
            ...productsForSale,
            {
              batch: selectedBatch.batch,
              name: selectedProduct.label,
              product_id: selectedProduct.value,
              quantity: quantity,
              cost_price: selectedBatch.cost_price,
              selling_price: selectedBatch.selling_price,
              total_price: +selectedBatch.selling_price * +quantity,
            },
          ]);
          setTotalProductAmount(
            totalProductAmount + +selectedBatch.selling_price * +quantity
          );
          setQuantity(0);
          setSelectedProduct();
        }
      }
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <span className="text-danger">
        <i
          className="bx bxs-trash font-size-15"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            let arr = extraData.filter((item) => item.batch !== row.batch);
            setTotalProductAmount(totalProductAmount - row.total_price);
            setProductsForSale(arr);
          }}
        ></i>
      </span>
    );
  }
  const columns_products = [
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Price"),
      sort: false,
      editable: true,
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = { title: "Click to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = { cursor: "pointer" };
        return cur_style;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    // {
    //   text: props.t("Price"),
    //   dataField: "selling_price",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "9%" };
    //   },
    // },
    {
      text: props.t("Quantity"),
      dataField: "quantity",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Total Price"),
      dataField: "total_price",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForSale,
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];
  const [clientWallet, setClientWallet] = useState();
  async function getClientDetails(clientID) {
    let url = process.env.REACT_APP_BASEURL + "clients/getbyid";
    const response = await postSubmitForm(url, { id: clientID });
    if (response && response.status === 1) {
      setClientWallet(response.data.wallet);
    } else {
      showNotification(response.message, "Error");
    }
  }
  function detailsFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-user-check font-size-18"
          title="Check-out"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedAppointment(row);
            getClientDetails(row.client_details.client_id);
            setSelectedAppointmentServices(row.service_details);
            setAppointmentModal(true);
            let tota_service_cost = 0;
            row.service_details.map((service) => {
              tota_service_cost += +service.cost;
            });
            setTotalServiceAmount(tota_service_cost);
            setDiscount("0");
            setFromWallet("0");
            setPaidCard("0");
            setPaidCash("0");
            setRemarks("");
            setSelectedProduct();
            setSelectedBatch();
            setCheckOutSuccess(false);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Check-in Time"),
      dataField: "check_in",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Client Name"),
      dataField: "client_details.client_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Client Mobile"),
      dataField: "client_details.client_mobile",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "start",
      text: props.t("Start Time"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "end",
      text: props.t("End Time"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "is_homeservice",
      text: props.t("Home Service?"),
      formatter: (cell, row) => {
        return row.is_homeservice ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Check-out"),
      formatter: detailsFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  function showBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.beautician_details.map((beautician) => {
          return (
            <>
              {beautician.type === "Primary" ? (
                <span
                  className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              ) : (
                <span
                  class="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              )}
            </>
          );
        })}
      </>
    );
  }
  const columns_services = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Service Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
    },

    {
      dataField: "duration",
      text: props.t("Duration (minutes)"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
    },
    {
      text: props.t("Beauticians"),
      formatter: showBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "35%" };
      },
      editable: false,
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_cost_updatable;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_cost_updatable
          ? { title: "Click to Edit" }
          : { title: "Cannot Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_cost_updatable
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  const [discount, setDiscount] = useState("0");
  const [paidCash, setPaidCash] = useState("0");
  const [paidCard, setPaidCard] = useState("0");
  const [fromWallet, setFromWallet] = useState("0");
  const [amountPaid, setAmountPaid] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  useEffect(() => {
    setTotalAmount(totalServiceAmount + totalProductAmount);
  }, [totalServiceAmount, totalProductAmount]);
  useEffect(() => {
    setAmountPaid(+totalAmount - +discount - +fromWallet);
    //setPaidCash(+totalAmount - +discount - +fromWallet - +paidCard);
  }, [totalAmount, discount, fromWallet, paidCard]);
  useEffect(() => {
    setFinalAmount(+totalAmount - +discount);
  }, [totalAmount, discount]);
  // useEffect(() => {
  //   setPaidCard(+totalAmount - +discount - +fromWallet - +paidCash);
  // }, [paidCash]);

  const checkOutClicked = async () => {
    if (totalAmount === +paidCash + +paidCard + +discount + +fromWallet) {
      let data_to_send = {
        client_details: selectedAppointment.client_details,
        appointment_id: selectedAppointment._id,
        service_details: selectedAppointment.service_details,
        sold_product_details: productsForSale,
        service_amount: totalServiceAmount,
        sold_product_amount: totalProductAmount,
        total_amount: totalAmount,
        final_amount: finalAmount,
        paid_amount: amountPaid,
        discount: discount,
        from_wallet: fromWallet,
        paid_by_cash: paidCash,
        paid_by_card: paidCard,
        remarks: remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "serviceandsales/check_out";

      const response = await postSubmitForm(url, data_to_send);
      //console.log(data_to_send);
      if (response && response.status === 1) {
        setCheckOutSuccess(true);
        setCheckOutResponse(response.data);
        loadOngoingAppointments();
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      showNotification("Total amount does not match entered amount.", "Error");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Appointments")}
            breadcrumbItem={props.t("Check-out")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Check-out")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all ongoing appointments here")}
                  </CardSubtitle>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={allOngoingAppointments && allOngoingAppointments}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          {...props.searchProps}
                          style={{ width: "300px" }}
                        />

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={appointmentModal}
                    toggle={() => setAppointmentModal(!appointmentModal)}
                  >
                    <ModalHeader
                      toggle={() => setAppointmentModal(!appointmentModal)}
                    >
                      {props.t("Check-out")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                {!checkOutSuccess ? (
                                  <Row>
                                    <Col lg={6}>
                                      <p class="text-muted mb-2">
                                        {props.t("Date & Time")}
                                      </p>
                                      <span className="text-info font-weight-bold">
                                        <Moment format="DD/MM/YYYY hh:mm a">
                                          {selectedAppointment &&
                                            selectedAppointment.start}
                                        </Moment>
                                        {" - "}
                                        <Moment format="DD/MM/YYYY hh:mm a">
                                          {selectedAppointment &&
                                            selectedAppointment.end}
                                        </Moment>
                                      </span>
                                      <br />
                                      <br />
                                    </Col>
                                    <Col lg={3}>
                                      <div>
                                        <p class="text-muted mb-2">
                                          <b> {props.t("Amount to Pay")}</b>
                                        </p>
                                        <h5>QR {amountPaid && amountPaid}</h5>
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <div class="button-items">
                                        <p class="text-muted mb-2"></p>
                                        <button
                                          type="button"
                                          class="btn  btn-dark waves-effect btn-label waves-light"
                                          onClick={() => {
                                            setConfirmCheckout(true);
                                          }}
                                        >
                                          <i class="bx bx-cart  font-size-16 align-middle me-2"></i>{" "}
                                          {props.t("Check-out")}
                                        </button>
                                      </div>
                                    </Col>
                                    <Col lg={2}>
                                      <div>
                                        <p class="text-muted mb-2">
                                          {props.t("Total Amount")}
                                        </p>
                                        <h5>QR {totalAmount && totalAmount}</h5>
                                      </div>
                                    </Col>
                                    <Col lg={5}>
                                      <AvForm>
                                        <p class="text-muted mb-2">
                                          {props.t("Paid by Cash")}
                                        </p>
                                        <AvField
                                          name="paid_by_cash"
                                          placeholder={props.t("Paid by Cash")}
                                          type="text"
                                          value={paidCash}
                                          onChange={(e) => {
                                            setPaidCash(e.target.value);
                                          }}
                                          validate={{
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </AvForm>
                                    </Col>
                                    <Col lg={5}>
                                      <AvForm>
                                        <p class="text-muted mb-2">
                                          {props.t("Paid by Card")}
                                        </p>
                                        <AvField
                                          name="paid_by_card"
                                          placeholder={props.t("Paid by Card")}
                                          type="text"
                                          value={paidCard}
                                          onChange={(e) => {
                                            setPaidCard(e.target.value);
                                          }}
                                          validate={{
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </AvForm>
                                    </Col>

                                    <Col lg={4}>
                                      <AvForm>
                                        <p class="text-muted mb-2">
                                          {props.t("Discount")}
                                        </p>
                                        <AvField
                                          name="discount"
                                          placeholder={props.t(
                                            "Enter Discount"
                                          )}
                                          type="text"
                                          value={discount}
                                          onChange={(e) => {
                                            setDiscount(e.target.value);
                                          }}
                                          validate={{
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </AvForm>
                                    </Col>
                                    <Col lg={4}>
                                      <AvForm>
                                        <p class="text-muted mb-2">
                                          {props.t("From Wallet")}{" "}
                                          {"(" +
                                            props.t("Avail.") +
                                            ": " +
                                            clientWallet +
                                            ")"}
                                        </p>
                                        <AvField
                                          name="from_wallet"
                                          placeholder={props.t(
                                            "Amount from Wallet"
                                          )}
                                          type="text"
                                          value={fromWallet}
                                          onChange={(e) => {
                                            setFromWallet(e.target.value);
                                          }}
                                          validate={{
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </AvForm>
                                    </Col>

                                    <Col lg={4}>
                                      <AvForm>
                                        <p class="text-muted mb-2">
                                          {props.t("Remarks")}
                                        </p>
                                        <AvField
                                          name="remarks"
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                          value={remarks}
                                          onChange={(e) => {
                                            setRemarks(e.target.value);
                                          }}
                                        />
                                      </AvForm>
                                    </Col>
                                    {/* <Col lg={3}>
                                    <AvForm>
                                      <p class="text-muted mb-2">
                                        {props.t("Amount to Pay")}
                                      </p>
                                      <AvField
                                        name="amount_paid"
                                        placeholder={props.t("Amount to Pay")}
                                        type="text"
                                        value={amountPaid}
                                        onChange={(e) => {
                                          setAmountPaid(e.target.value);
                                        }}
                                        validate={{
                                          pattern: {
                                            value: "^[0-9]+$",
                                            errorMessage: props.t(
                                              "Only numbers are allowed."
                                            ),
                                          },
                                        }}
                                      />
                                    </AvForm>
                                  </Col> */}

                                    <Col lg={3} className="mb-4">
                                      <p class="text-muted mb-2">
                                        {props.t("Add Product")}
                                      </p>
                                      <Select
                                        value={
                                          selectedProduct && selectedProduct
                                        }
                                        key={selectedProduct}
                                        placeholder={props.t("Select...")}
                                        onChange={(e) => {
                                          setSelectedProduct(e);
                                          loadBatches(e);
                                          setSelectedBatch();
                                        }}
                                        options={allProducts}
                                        classNamePrefix="select2-selection"
                                        required
                                      />
                                    </Col>
                                    {selectedProduct ? (
                                      selectedProduct.available_quantity_for_sale >
                                      0 ? (
                                        <>
                                          <Col lg={3} className="mb-4">
                                            <p class="text-muted mb-2">
                                              {props.t("Batch")}
                                            </p>
                                            <Select
                                              value={
                                                selectedBatch && selectedBatch
                                              }
                                              key={selectedBatch}
                                              onChange={(e) => {
                                                setSelectedBatch(e);
                                              }}
                                              options={
                                                productBatches && productBatches
                                              }
                                              classNamePrefix="select2-selection"
                                              required
                                            />
                                          </Col>

                                          <Col lg={3} className="mb-4">
                                            <p class="text-muted mb-2">
                                              {props.t("Quantity")}
                                            </p>
                                            <AvForm>
                                              <AvField
                                                name="quantity"
                                                placeholder={props.t(
                                                  "Enter Quantity"
                                                )}
                                                type="text"
                                                onChange={(e) => {
                                                  setQuantity(e.target.value);
                                                }}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage: props.t(
                                                      "Quantity cannot be empty."
                                                    ),
                                                  },
                                                  pattern: {
                                                    value:
                                                      "^\\d+(\\.\\d{1,2})?$",
                                                    errorMessage: props.t(
                                                      "Only numbers and decimals upto two places are allowed."
                                                    ),
                                                  },
                                                }}
                                              />
                                            </AvForm>
                                          </Col>
                                          <Col lg={2} className="mb-4">
                                            {" "}
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-info waves-effect waves-light btn-sm"
                                              onClick={handleAddProductForSale}
                                            >
                                              <i class="bx bx-add-to-queue"></i>{" "}
                                              Add
                                            </Link>
                                          </Col>
                                        </>
                                      ) : (
                                        <Col lg={6}>
                                          <p class="text-muted mb-2">&nbsp;</p>
                                          <div
                                            class="alert alert-danger fade show text-center"
                                            role="alert"
                                          >
                                            {props.t(
                                              "This product is out of stock."
                                            )}
                                          </div>
                                        </Col>
                                      )
                                    ) : null}

                                    {productsForSale &&
                                    productsForSale.length > 0 ? (
                                      <>
                                        <Col lg={12} md={12}>
                                          <br />
                                          <p class="text-muted mb-2">
                                            <b>{props.t("Products")} </b>
                                            <i>
                                              ({props.t("Total")}:{" "}
                                              {totalProductAmount &&
                                                totalProductAmount}
                                              )
                                            </i>
                                          </p>
                                        </Col>
                                        <Col lg={12}>
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="name"
                                            key="name"
                                            data={
                                              productsForSale && productsForSale
                                            }
                                            columns={columns_products}
                                            cellEdit={cellEditFactory({
                                              mode: "click",
                                              blurToSave: true,
                                              afterSaveCell: (
                                                oldValue,
                                                newValue,
                                                row,
                                                column
                                              ) => {
                                                let total_product_cost = 0;
                                                // productsForSale &&
                                                //   productsForSale.map(
                                                //     (product) => {
                                                //       total_product_cost +=
                                                //         +product.selling_price *
                                                //         +product.quantity;
                                                //     }
                                                //   );
                                                let arr = [...productsForSale];
                                                for (
                                                  let i = 0;
                                                  i < arr.length;
                                                  i++
                                                ) {
                                                  arr[i].total_price =
                                                    +arr[i].selling_price *
                                                    +arr[i].quantity;
                                                  total_product_cost +=
                                                    +arr[i].selling_price *
                                                    +arr[i].quantity;
                                                }
                                                setProductsForSale(arr);
                                                setTotalProductAmount(
                                                  total_product_cost
                                                );
                                              },
                                            })}
                                            noDataIndication="No data to display."
                                            striped
                                            hover
                                            condensed
                                          />
                                        </Col>
                                      </>
                                    ) : null}
                                    <Col lg={12} md={12}>
                                      <br />
                                      <p class="text-muted mb-2">
                                        <b>{props.t("Services")} </b>
                                        <i>
                                          ({props.t("Total")}:{" "}
                                          {totalServiceAmount &&
                                            totalServiceAmount}
                                          )
                                        </i>
                                      </p>
                                    </Col>

                                    <Col lg={12}>
                                      <BootstrapTable
                                        bootstrap4
                                        keyField="name"
                                        key="name"
                                        data={
                                          selectedAppointmentServices &&
                                          selectedAppointmentServices
                                        }
                                        columns={columns_services}
                                        cellEdit={cellEditFactory({
                                          mode: "click",
                                          blurToSave: true,
                                          afterSaveCell: (
                                            oldValue,
                                            newValue,
                                            row,
                                            column
                                          ) => {
                                            let tota_service_cost = 0;
                                            selectedAppointmentServices &&
                                              selectedAppointmentServices.map(
                                                (service) => {
                                                  tota_service_cost +=
                                                    +service.cost;
                                                }
                                              );
                                            setTotalServiceAmount(
                                              tota_service_cost
                                            );
                                          },
                                        })}
                                        noDataIndication="No data to display."
                                        striped
                                        hover
                                        condensed
                                      />
                                    </Col>
                                    <Col lg={12}>
                                      <br />
                                      <p class="text-muted mb-2">
                                        {props.t("Remarks")}
                                      </p>
                                      <span className="text-info font-weight-bold">
                                        {selectedAppointment &&
                                          selectedAppointment.remarks}
                                      </span>
                                    </Col>
                                  </Row>
                                ) : (
                                  <div className="text-center">
                                    <Row className="justify-content-center">
                                      <Col lg="10">
                                        <h4 className="mt-4 font-weight-semibold">
                                          {props.t("Check-out Successful.")}
                                        </h4>

                                        <Row className="justify-content-center mt-3 mb-2">
                                          <Col sm="12" xs="12">
                                            <div>
                                              <img
                                                src={success_image}
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <div className="mt-4">
                                          {/* button triggers modal */}
                                          <Button
                                            className="mr-2"
                                            type="button"
                                            color="warning"
                                            onClick={() => {
                                              setAppointmentModal(
                                                !appointmentModal
                                              );
                                            }}
                                          >
                                            {props.t("Close")}
                                          </Button>
                                          <a
                                            className="btn btn-info"
                                            href={
                                              checkOutResponse &&
                                              "/invoice?invoice=" +
                                                checkOutResponse._id
                                            }
                                            target="_blank"
                                          >
                                            <i className="bx bx-doc"></i>{" "}
                                            {props.t("View Invoice")}
                                          </a>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                  {confirmCheckout && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmCheckout(false);
                        checkOutClicked();
                      }}
                      onCancel={() => {
                        setConfirmCheckout(false);
                      }}
                    >
                      {props.t(
                        "Once you check-out this appointment, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(OngoingAppointments))
);

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Label,
  Button,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

import moment from "moment";

const ExpiringProductsReport = (props) => {
  const [noOfDays, setNoOfDays] = useState(15);
  useEffect(() => {
    loadExpiredProducts();
  }, [noOfDays]);
  const [expiredProducts, setExpiredProducts] = useState([]);
  const loadExpiredProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/expiring_products";
    const response = await postSubmitForm(url, { n_days: noOfDays });
    if (response && response.status === 1) {
      setExpiredProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
    {
      text: props.t("Product Name"),
      dataField: "product_details.name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "product_details.product_category_details.name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },

    {
      dataField: "quantity_for_sale",
      text: props.t("Quantity (for sale)"),
      sort: true,
      formatter: (cell, row) => {
        if (row.quantity_for_sale || row.quantity_for_sale === 0) {
          return row.quantity_for_sale;
        } else {
          return "NA";
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "quantity_for_use",
      text: props.t("Quantity (for use)"),
      sort: true,
      formatter: (cell, row) => {
        if (row.quantity_for_use || row.quantity_for_use === 0) {
          return row.quantity_for_use;
        } else {
          return row.quantity;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Expiry Date"),
      dataField: "client_details.client_name",
      formatter: (cell, row) => {
        return moment(row.expiry_date).format("DD/MM/YYYY");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  const handleDownload = async () => {
    const fileName = "expiring_products";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(expiredProducts));
    data.forEach(function (v) {
      v.product_name = v.product_details.name;
      v.product_category = v.product_details.product_category_details.name;
      v.expiry_date = moment(v.expiry_date).format("DD/MM/YYYY");

      delete v._id;
      delete v.product_details;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sales_transactions;
      delete v.use_transactions;
      delete v.status;
      delete v.quantity;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Expiring Products Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <Label>Products expiring in </Label>
                      </Col>
                      <Col lg={5}>
                        <AvField
                          name="no_of_days"
                          placeholder={props.t("Enter Number of Days")}
                          type="text"
                          value={noOfDays}
                          onChange={(e, v) => {
                            setNoOfDays(e.target.value);
                          }}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t("Days cannot be empty."),
                            },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Label>days. </Label>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Expiring Products Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={expiredProducts && expiredProducts}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ExpiringProductsReport))
);

import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//DataTable
import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AddSource = (props) => {
  useEffect(() => {
    loadSources();
  }, []);
  const refContainer = useRef(null);
  const [allSources, setAllSources] = useState([]);
  const loadSources = async () => {
    let url = process.env.REACT_APP_BASEURL + "sources/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllSources(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  async function handleValidSubmit(e, v) {
    let url = process.env.REACT_APP_BASEURL + "sources/insert";
    const response = await postSubmitForm(url, v);
    if (response.status === 1) {
      showNotification(props.t(response.message), props.t("Success"));
      loadSources();
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
    //props.submitSource(v, props.history);
    refContainer.current.reset();
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
    },
    {
      dataField: "description",
      text: props.t("Description"),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Sources of Expense")}
            breadcrumbItem={props.t("Add Source")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Source")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new source of expense"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name") + " *"}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          errorMessage={props.t("Name cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="description"
                          label={props.t("Description")}
                          placeholder={props.t("Enter Description")}
                          type="textarea"
                        />
                      </Col>

                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Existing Sources")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing sources of expense here")}
                  </CardSubtitle>

                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allSources && allSources}
                    columns={columns}
                    noDataIndication={props.t("There is no data to display")}
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddSource)));

export const SUBMIT_ADDUSER = "SUBMIT_ADDUSER";
export const SUBMIT_ADDUSER_SUCCESS = "SUBMIT_ADDUSER_SUCCESS";
export const LOAD_ADDUSER = "LOAD_ADDUSER";
export const LOAD_ADDUSER_SUCCESS = "LOAD_ADDUSER_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const ACTIVATE_DEACTIVATE_USER = "ACTIVATE_DEACTIVATE_USER";
export const ACTIVATE_DEACTIVATE_USER_SUCCESS =
  "ACTIVATE_DEACTIVATE_USER_SUCCESS";
export const ADDUSER_API_ERROR = "ADDUSER_API_ERROR";
export const LOAD_GETBYROLE = "LOAD_GETBYROLE";
export const LOAD_GETBYROLE_SUCCESS = "LOAD_GETBYROLE_SUCCESS";

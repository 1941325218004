import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AllProducts = (props) => {
  useEffect(() => {
    loadProducts();
    loadAllProductCategories();
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/get_grouped";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [editModal, setEditModal] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit font-size-15"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedProduct(row);
            setSelectedCategoryID(
              row.product_category_details.product_category_id
            );
            setSelectedCategoryName(row.product_category_details.name);
            setForSale(row.is_for_sale);
            setForUse(row.is_for_use);

            setEditModal(!editModal);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      // dataField: "threshold_quantity_for_sale",
      text: props.t("Threshold (for sale)"),
      formatter: (cell, row) => {
        return row.is_for_sale
          ? row.threshold_quantity_for_sale
          : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      //dataField: "threshold_quantity_for_use",
      text: props.t("Threshold (for use)"),
      formatter: (cell, row) => {
        return row.is_for_use ? row.threshold_quantity_for_use : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "product_category_details.name",
      text: props.t("Product Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  //Update related functions
  const [forUse, setForUse] = useState(false);
  const [forSale, setForSale] = useState(false);
  const [selectedCategoryID, setSelectedCategoryID] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [allProductCategories, setAllProductCategories] = useState();
  const loadAllProductCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProductCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [isNewCategoryModal, setNewCategoryModal] = useState();
  const handleAddNewCategory = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllProductCategories();
      setSelectedCategoryID(response.data._id);
      setSelectedCategoryName(response.data.name);
      setNewCategoryModal(!isNewCategoryModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleValidUpdate = async (e, v) => {
    try {
      let data_to_send = {};
      data_to_send.product_category_details = {
        product_category_id: selectedCategoryID,
        name: selectedCategoryName,
      };
      data_to_send.name = v.name;
      data_to_send.is_for_sale = forSale;
      data_to_send.threshold_quantity_for_sale = forSale
        ? v.threshold_quantity_for_sale
        : 0;
      data_to_send.is_for_use = forUse;
      data_to_send.threshold_quantity_for_use = forUse
        ? v.threshold_quantity_for_use
        : 0;

      data_to_send.remarks = v.remarks;
      data_to_send.id = selectedProduct._id;

      let url = process.env.REACT_APP_BASEURL + "products/update";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadProducts();
        setEditModal(!editModal);
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };

  const handleDownload = async () => {
    const fileName = "products";
    const exportType = "xls";
    let data_to_export = [];
    let url = process.env.REACT_APP_BASEURL + "products/getall"; //"services/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      data_to_export = response.data;
    } else {
      showNotification(response.message, "Error");
    }
    if (data_to_export) {
      var data = JSON.parse(JSON.stringify(data_to_export));
      data.forEach(function (v) {
        v.name = v.name;
        // v.available_quantity_for_sale = v.is_for_sale
        //   ? v.available_quantity_for_sale
        //   : props.t("NA");
        v.threshold_quantity_for_sale = v.is_for_sale
          ? v.threshold_quantity_for_sale
          : props.t("NA");
        // v.available_quantity_for_use = v.is_for_use
        //   ? v.available_quantity_for_use
        //   : props.t("NA");
        v.threshold_quantity_for_use = v.is_for_use
          ? v.threshold_quantity_for_use
          : props.t("NA");
        v.available_quantity_for_use = v.available_quantity_for_use;
        v.is_for_sale = v.is_for_sale ? props.t("Yes") : props.t("No");
        v.is_for_use = v.is_for_use ? props.t("Yes") : props.t("No");

        v.category = v.product_category_details.name;

        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.product_category_details;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Category Name"),
      dataField: "category_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "total_products",
      text: props.t("Total Products"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      style: { cursor: "pointer" },
    },
  ];
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.products}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Products")}
            breadcrumbItem={props.t("All Products")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Products")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing products here")}
                  </CardSubtitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allProducts && allProducts}
                    columns={columns_external}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                    expandRow={expandRow}
                  />
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      Product Name: {selectedProduct && selectedProduct.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedProduct && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="product_categories"
                                              label={props.t("Category") + " *"}
                                              type="select"
                                              value={selectedCategoryID}
                                              onChange={(e, v) => {
                                                const selectedIndex =
                                                  e.target.options
                                                    .selectedIndex;
                                                setSelectedCategoryID(
                                                  e.target.value
                                                );
                                                setSelectedCategoryName(
                                                  e.target.options[
                                                    selectedIndex
                                                  ].getAttribute("name")
                                                );
                                              }}
                                              required
                                              errorMessage={props.t(
                                                "Select Category"
                                              )}
                                            >
                                              <option value="" name="">
                                                -- {props.t("Select Category")}{" "}
                                                --
                                              </option>
                                              {allProductCategories &&
                                                allProductCategories.map(
                                                  (item) => {
                                                    return (
                                                      <option
                                                        value={item._id}
                                                        name={item.name}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewCategoryModal(
                                                  !isNewCategoryModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              Add New
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name") + " *"}
                                          placeholder={props.t("Enter Name")}
                                          value={selectedProduct.name}
                                          type="text"
                                          errorMessage="Name cannot be empty."
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={4}>
                                            <Label>{props.t("Purpose")}</Label>
                                            <AvField
                                              type="checkbox"
                                              name="for_sale"
                                              label="For Sale"
                                              value={
                                                selectedProduct.is_for_sale
                                              }
                                              onChange={(e, v) => {
                                                setForSale(v);
                                              }}
                                              required={!forUse}
                                              errorMessage={props.t(
                                                "Select either 'For Sale' or 'For Use.' or both."
                                              )}
                                            />
                                          </Col>
                                          <Col lg={8}>
                                            {forSale ? (
                                              <AvField
                                                name="threshold_quantity_for_sale"
                                                value={
                                                  selectedProduct.threshold_quantity_for_sale
                                                }
                                                placeholder={props.t(
                                                  "Threshold Quantity For Sale"
                                                )}
                                                errorMessage="Threshold quantity cannot be empty."
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                }}
                                              />
                                            ) : null}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg={4}>
                                            <AvField
                                              type="checkbox"
                                              name="for_use"
                                              label="For Use"
                                              value={selectedProduct.is_for_use}
                                              onChange={(e, v) => {
                                                setForUse(v);
                                              }}
                                              required={!forSale}
                                              errorMessage={props.t(
                                                "Select either 'For Sale' or 'For Use.' or both."
                                              )}
                                            />
                                          </Col>
                                          <Col lg={8}>
                                            {forUse ? (
                                              <AvField
                                                name="threshold_quantity_for_use"
                                                value={
                                                  selectedProduct.threshold_quantity_for_use
                                                }
                                                placeholder={props.t(
                                                  "Threshold Quantity For Use"
                                                )}
                                                errorMessage="Threshold quantity cannot be empty."
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                }}
                                              />
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={6}>
                                        <br />
                                        <AvField
                                          name="remarks"
                                          value={selectedProduct.remarks}
                                          label={props.t("Remarks")}
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={isNewCategoryModal}
                    toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                    >
                      Add New Product Category
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category Name") + " *"}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      errorMessage="Category name cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <AvField
                                      name="remarks"
                                      label={props.t("Remarks")}
                                      placeholder={props.t("Enter Remarks")}
                                      type="textarea"
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AllProducts))
);

import React, { useEffect, useRef, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Container,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postSubmitForm } from "../../helpers/forms_helper";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const AppSettings = (props) => {
  const refContainer = useRef(null);
  useEffect(() => {
    loadSettings();
  }, []);
  const [charityPercent, setCharityPercent] = useState();
  const [transactionPassword, setTransactionPassword] = useState();
  const loadSettings = async () => {
    let url = process.env.REACT_APP_BASEURL + "settings/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setCharityPercent(response.data[0].charity_percent);
      setTransactionPassword(response.data[0].transaction_password);
    } else {
      showNotification(response.message, "Error");
    }
  };
  async function handleValidSubmit(e, v) {
    let url = process.env.REACT_APP_BASEURL + "settings/update";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadSettings();
      showNotification(props.t(response.message), props.t("Success"));
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
  }

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Settings")}
            breadcrumbItem={props.t("App Settings")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  {/* <CardTitle>{props.t("Update Charity")}</CardTitle> */}
                  {/* <hr /> */}
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="charity_percent"
                          value={charityPercent}
                          label={props.t("Charity Percentage") + " *"}
                          placeholder={props.t("Enter Charity Percentage")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t("Charity cannot be empty."),
                            },
                            pattern: {
                              value: "^\\d+(\\.\\d{1,2})?$",
                              errorMessage: props.t(
                                "Only numbers and decimals upto two places are allowed."
                              ),
                            },
                          }}
                        />
                        <AvField
                          name="transaction_password"
                          value={transactionPassword}
                          label={props.t("Transaction Password") + " *"}
                          placeholder={props.t("Enter Transaction Password")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Password cannot be empty."
                              ),
                            },
                          }}
                        />
                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(AppSettings)));

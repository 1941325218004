import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Input,
  Label,
} from "reactstrap";
import swal from "sweetalert2";
import csc from "country-state-city";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/forms_helper";

import moment from "moment";
import ReactAvatarEditor from "react-avatar-editor";

// actions
import { loadEmployees } from "../../store/actions";

const AllEmployees = (props) => {
  const { SearchBar } = Search;
  const [qatarID, setQatarID] = useState();
  const [medicalID, setMedicalID] = useState();
  const [passport, setPassport] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [allowZoomOut] = useState(false);
  const [isNewDesignationModal, setNewDesignationModal] = useState();
  const [selectedDesignation, setSelectedDesignation] = useState();
  const handleAddNewDesignation = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "designations/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadDesignations();
      setSelectedDesignation(v.name);
      setNewDesignationModal(!isNewDesignationModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const editorRef = useRef();
  // setEditorRef = (editor) => (this.editor = editor);
  const [rotate] = useState(0);
  const [borderRadius] = useState(0);
  const onPhotoChange = (event) => {
    setSelectedPhoto(event.target.files[0]);
  };
  useEffect(() => {
    setAllCountries(csc.getAllCountries);
    loadDesignations();
    props.loadEmployees();
  }, []);
  const [allDesignations, setAllDesignations] = useState([]);
  const loadDesignations = async () => {
    let url = process.env.REACT_APP_BASEURL + "designations/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllDesignations(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          class="avatar-md rounded-circle img-thumbnail"
        ></img>

        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            // let file = new File(row.image_url)
            setEditModal(false);
            setScale(1);
            setImageModal(!imageModal);
            setSelectedEmployee(row);
            // setSelectedPhoto(
            //   row.image_url + "?" + new Date() + "=" + new Date()
            // );
            setSelectedPhoto();
          }}
          class="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
          <i className="mdi mdi-image-edit mr-1" title="Click to Edit"></i>{" "}
          Change
        </span>
      </>
    );
  }
  const handleEmployeeStatusUpdate = async (employee) => {
    let url = process.env.REACT_APP_BASEURL + "employees/activate_deactivate";
    const response = await postSubmitForm(url, {
      id: employee._id,
      is_active: !employee.is_active,
    });
    if (response && response.status === 1) {
      props.loadEmployees();
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showStatusFormatter(cell, row) {
    // if (row.is_active) {
    return (
      <>
        <div className="custom-control custom-switch mb-2" dir="ltr">
          <input
            type="checkbox"
            title="Click to change status."
            className="custom-control-input"
            id={"customSwitch1" + row._id}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ "font-weight": "100", cursor: "pointer" }}
            onClick={() => {
              handleEmployeeStatusUpdate(row);
            }}
          ></label>
        </div>

        {row.is_active ? (
          <span class="font-size-12 badge-soft-success badge badge-success badge-pill">
            Active
          </span>
        ) : (
          <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
            Inactive
          </span>
        )}
      </>
    );
    // } else {
    //   return (
    //     <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
    //       Inactive
    //     </span>
    //   );
    // }
  }
  const [editModal, setEditModal] = useState();
  const [imageModal, setImageModal] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit font-size-15"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal);
            setSelectedEmployee(row);
            setSelectedDesignation(row.designation);
            setQatarID(row.qatarid);
            setPassport(row.passport);
          }}
        ></i>
      </span>
    );
  }
  function showNotification(message, type) {
    var title = type;
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const handleValidUpdate = async (e, v) => {
    const data_to_send = {};
    data_to_send.id = selectedEmployee._id;
    data_to_send.designation = selectedDesignation;
    data_to_send.email = v.email;
    data_to_send.mobile = v.mobile;
    data_to_send.monthly_target = v.monthly_target;
    data_to_send.name = v.name;
    data_to_send.nationality = v.nationality;
    data_to_send.salary = v.salary;
    data_to_send.remarks = v.remarks;

    if (v.qatarid !== "") {
      data_to_send.qatarid = v.qatarid;
      data_to_send.qatarid_expirydate = v.qatarid_expirydate;
    }
    if (v.passport !== "") {
      data_to_send.passport = v.passport;
    }
    data_to_send.medicalid = v.medicalid;
    data_to_send.medicalid_expirydate = v.medicalid_expirydate;

    let url = process.env.REACT_APP_BASEURL + "employees/update";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      showNotification(response.message, "Success");
      props.loadEmployees();
      setEditModal(!editModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const handleUpdatePhoto = async (e, v) => {
    // console.log(
    //   editorRef.current.getImageScaledToCanvas().toDataURL("image/png")
    // );
    srcToFile(
      editorRef.current.getImageScaledToCanvas().toDataURL("image/png"),
      "new_image.png",
      "image/png"
    ).then(function (file) {
      let url = process.env.REACT_APP_BASEURL + "employees/update_image";
      let formData = new FormData();
      formData.set("id", selectedEmployee._id);
      formData.append("image_file", file);
      postSubmitForm_withformdata(url, formData).then((api_response) => {
        if (api_response.status === 1 && api_response.data) {
          props.loadEmployees();
          setEditModal(false);
          setImageModal(!imageModal);
          setSelectedPhoto();
          showNotification(api_response.message, "Success");
        } else {
          showNotification(api_response.message, "Error");
        }
      });
    });
    // console.log(
    //   editorRef.current.getImageScaledToCanvas().toDataURL("image/png")
    // );
    // console.log(
    //   editorRef.current.getImageScaledToCanvas().toDataURL("image/png")
    // );
    // console.log(
    //   document.write(
    //     '<img src="' +
    //       editorRef.current.getImageScaledToCanvas().toDataURL("image/png") +
    //       '"/>'
    //   )
    // );
    // console.log(
    //   editorRef.current.getImageScaledToCanvas().toDataURL().toBlob()
    // );
    //console.log(editorRef.current.getImageScaledToCanvas().toBlob());
    // var img    = canvas.toDataURL("image/png");
    // document.write('<img src="'+img+'"/>');
  };
  // function employeeFormatter(cell, row) {
  //   return (
  //     <a
  //       class="text-body text-underline font-weight-bold"
  //       onClick={() => {
  //         setEmployeeModal(!employeeModal);
  //       }}
  //     >
  //       {" "}
  //       <u>{row.name}</u>{" "}
  //     </a>
  //   );
  // }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Code"),
      //formatter: employeeFormatter,
      dataField: "code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "designation",
      text: props.t("Designation"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "qatarid",
      text: props.t("Qatar ID"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "passport",
      text: props.t("Passport"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "salary",
      text: props.t("Salary"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "monthly_target",
      text: props.t("Target"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Photo"),
      formatter: showPhotoFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];
  const [currentDate] = useState(() => {
    var d1 = new Date();
    var d = moment(d1).add(15, "days").toDate();
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleDownload = () => {
    const fileName = "employees";
    const exportType = "xls";
    if (props.employees) {
      var data = JSON.parse(JSON.stringify(props.employees));
      data.forEach(function (v) {
        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.image_url;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem={props.t("All Employees")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Employees")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing employees here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={props.employees && props.employees}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          {...props.searchProps}
                          style={{ width: "300px" }}
                        />

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      Employee Name: {selectedEmployee && selectedEmployee.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedEmployee && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          value={selectedEmployee.name}
                                          label={props.t("Name") + " *"}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage="Name cannot be empty."
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="mobile"
                                          value={selectedEmployee.mobile}
                                          label={props.t("Mobile") + " *"}
                                          placeholder={props.t("Enter Mobile")}
                                          errorMessage="Mobile cannot be empty."
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                            maxLength: {
                                              value: 8,
                                              errorMessage: props.t(
                                                "Enter valid mobile."
                                              ),
                                            },
                                            minLength: {
                                              value: 8,
                                              errorMessage: props.t(
                                                "Enter valid mobile."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="nationality"
                                          label={props.t("Nationality") + " *"}
                                          type="select"
                                          value={selectedEmployee.nationality}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Select Nationality"
                                          )}
                                        >
                                          <option value="">
                                            -- {props.t("Select Nationality")}{" "}
                                            --
                                          </option>
                                          {allCountries &&
                                            allCountries.map((item) => {
                                              return (
                                                <option value={item.name}>
                                                  {item.name}
                                                </option>
                                              );
                                            })}
                                        </AvField>
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="email"
                                          value={selectedEmployee.email}
                                          label={props.t("Email")}
                                          placeholder={props.t("Enter Email")}
                                          errorMessage={props.t(
                                            "Please enter valid email."
                                          )}
                                          type="email"
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={8} md={8}>
                                            {" "}
                                            <AvField
                                              name="designation"
                                              label={
                                                props.t("Designation") + " *"
                                              }
                                              type="select"
                                              //value={selectedEmployee.designation}
                                              value={selectedDesignation}
                                              onChange={(e) => {
                                                setSelectedDesignation(
                                                  e.target.value
                                                );
                                              }}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Select Designation"
                                              )}
                                            >
                                              <option value="">
                                                --{" "}
                                                {props.t("Select Designation")}{" "}
                                                --
                                              </option>
                                              {allDesignations &&
                                                allDesignations.map((item) => {
                                                  return (
                                                    <option value={item.name}>
                                                      {item.name}
                                                    </option>
                                                  );
                                                })}
                                            </AvField>
                                          </Col>
                                          <Col lg={4} md={4}>
                                            {" "}
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewDesignationModal(
                                                  !isNewDesignationModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              Add New
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={6}>
                                            <AvField
                                              name="qatarid"
                                              value={selectedEmployee.qatarid}
                                              label={props.t("Qatar ID") + " *"}
                                              placeholder={props.t(
                                                "Enter Qatar ID"
                                              )}
                                              errorMessage={props.t(
                                                "Both Qatar ID and Passport Number cannot be empty."
                                              )}
                                              type="text"
                                              onChange={(e) => {
                                                setQatarID(e.target.value);
                                              }}
                                              validate={{
                                                required: {
                                                  value: passport
                                                    ? false
                                                    : true,
                                                },
                                                pattern: {
                                                  value: "^[0-9]+$",
                                                  errorMessage: props.t(
                                                    "Only numbers are allowed."
                                                  ),
                                                },

                                                maxLength: {
                                                  value: 11,
                                                  errorMessage: props.t(
                                                    "Enter valid qatar id."
                                                  ),
                                                },
                                                minLength: {
                                                  value: 11,
                                                  errorMessage: props.t(
                                                    "Enter valid qatar id."
                                                  ),
                                                },
                                              }}
                                            />
                                          </Col>
                                          <Col lg={6}>
                                            <AvField
                                              name="qatarid_expirydate"
                                              value={
                                                selectedEmployee.qatarid_expirydate
                                                  ? formatDate(
                                                      selectedEmployee.qatarid_expirydate
                                                    )
                                                  : null
                                              }
                                              label={
                                                props.t(
                                                  "Qatar ID Expiry Date"
                                                ) + " *"
                                              }
                                              placeholder={props.t(
                                                "Select Expiry Date"
                                              )}
                                              type="date"
                                              min={currentDate}
                                              validate={{
                                                required: {
                                                  value: qatarID ? true : false,
                                                  errorMessage: props.t(
                                                    "Expiry date cannot be empty"
                                                  ),
                                                },
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="passport"
                                          value={selectedEmployee.passport}
                                          label={
                                            props.t("Passport Number") + " *"
                                          }
                                          placeholder={props.t(
                                            "Enter Passport Number"
                                          )}
                                          errorMessage="Both Qatar ID and Passport Number cannot be empty."
                                          type="text"
                                          onChange={(e) => {
                                            setPassport(e.target.value);
                                          }}
                                          validate={{
                                            required: {
                                              value:
                                                qatarID && qatarID !== ""
                                                  ? false
                                                  : true,
                                            },
                                            // maxLength: {
                                            //   value: 25,
                                            //   errorMessage: props.t(
                                            //     "Enter valid passport number."
                                            //   ),
                                            // },
                                            // minLength: {
                                            //   value: 6,
                                            //   errorMessage: props.t(
                                            //     "Enter valid passport number."
                                            //   ),
                                            // },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={6}>
                                            <AvField
                                              name="medicalid"
                                              value={selectedEmployee.medicalid}
                                              label={
                                                props.t("Medical ID") + " *"
                                              }
                                              placeholder={props.t(
                                                "Enter Medical ID"
                                              )}
                                              type="text"
                                              onChange={(e) => {
                                                setMedicalID(e.target.value);
                                              }}
                                              validate={{
                                                required: {
                                                  value: true,
                                                  errorMessage: props.t(
                                                    "Medical ID cannot be empty."
                                                  ),
                                                },
                                              }}
                                            />
                                          </Col>
                                          <Col lg={6}>
                                            <AvField
                                              name="medicalid_expirydate"
                                              value={
                                                selectedEmployee.medicalid_expirydate
                                                  ? formatDate(
                                                      selectedEmployee.medicalid_expirydate
                                                    )
                                                  : null
                                              }
                                              label={
                                                props.t(
                                                  "Medical ID Expiry Date"
                                                ) + " *"
                                              }
                                              placeholder={props.t(
                                                "Select Expiry Date"
                                              )}
                                              type="date"
                                              min={currentDate}
                                              validate={{
                                                required: {
                                                  value: true,
                                                  errorMessage: props.t(
                                                    "Expiry date cannot be empty"
                                                  ),
                                                },
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="salary"
                                          value={selectedEmployee.salary}
                                          label={
                                            props.t("Monthly Salary") + " *"
                                          }
                                          placeholder={props.t("Enter Salary")}
                                          errorMessage={props.t(
                                            "Salary cannot be empty."
                                          )}
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                            // maxLength: {
                                            //   value: 7,
                                            //   errorMessage: props.t(
                                            //     "Enter valid salary."
                                            //   ),
                                            // },
                                            // minLength: {
                                            //   value: 2,
                                            //   errorMessage: props.t(
                                            //     "Enter valid salary."
                                            //   ),
                                            // },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="monthly_target"
                                          value={
                                            selectedEmployee.monthly_target
                                          }
                                          label={
                                            props.t("Monthly Target") + " *"
                                          }
                                          placeholder={props.t("Enter Target")}
                                          errorMessage="Target cannot be empty."
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                            // maxLength: {
                                            //   value: 7,
                                            //   errorMessage: props.t(
                                            //     "Enter valid monthly target."
                                            //   ),
                                            // },
                                            // minLength: {
                                            //   value: 1,
                                            //   errorMessage: props.t(
                                            //     "Enter valid monthly target."
                                            //   ),
                                            // },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="remarks"
                                          value={selectedEmployee.remarks}
                                          label={props.t("Remarks")}
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={imageModal}
                    toggle={() => setImageModal(!imageModal)}
                  >
                    <ModalHeader toggle={() => setImageModal(!imageModal)}>
                      Employee Name: {selectedEmployee && selectedEmployee.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedEmployee && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleUpdatePhoto(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center d-flex justify-content-center">
                                          <div>
                                            <ReactAvatarEditor
                                              ref={editorRef}
                                              scale={parseFloat(scale)}
                                              width={200}
                                              height={200}
                                              position={position}
                                              onPositionChange={
                                                (position) => {
                                                  setPosition(position);
                                                }
                                                //this.handlePositionChange
                                              }
                                              rotate={rotate}
                                              borderRadius={
                                                100 / (100 / borderRadius)
                                              }
                                              image={
                                                selectedPhoto && selectedPhoto
                                              }
                                              className="editor-canvas"
                                            />
                                            <br />
                                            <br />
                                            <input
                                              name="scale"
                                              type="range"
                                              onChange={handleScale}
                                              min={allowZoomOut ? "0.1" : "1"}
                                              max="2"
                                              step="0.01"
                                              defaultValue="1"
                                            />
                                            <br />
                                            <br />
                                            <Input
                                              type="file"
                                              id="resume"
                                              onChange={onPhotoChange}
                                            />
                                          </div>
                                        </FormGroup>
                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                              disabled={
                                                selectedPhoto && selectedPhoto
                                                  ? false
                                                  : true
                                              }
                                            >
                                              {props.t("Save")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={isNewDesignationModal}
                    toggle={() =>
                      setNewDesignationModal(!isNewDesignationModal)
                    }
                  >
                    <ModalHeader
                      toggle={() =>
                        setNewDesignationModal(!isNewDesignationModal)
                      }
                    >
                      Add New Designation
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewDesignation(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Designation Name") + " *"}
                                      placeholder={props.t(
                                        "Enter Designation Name"
                                      )}
                                      type="text"
                                      errorMessage="Designation name cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { error, message, message_type, employees } = state.Employees;
  return { error, message, message_type, employees };
};
export default withRouter(
  connect(mapStatetoProps, {
    loadEmployees,
  })(withNamespaces()(AllEmployees))
);

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import Line from "../AllCharts/echart/linechart";
import Pie from "../AllCharts/echart/piechart";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";

const AppointmentsReport = (props) => {
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadAppointments();
      loadAppointmentsPieChartData();
    }
  }, [dateFrom, dateTo]);

  const [allAppointments, setAllAppointments] = useState([]);
  const loadAppointments = async () => {
    let url = process.env.REACT_APP_BASEURL + "appointments/get_datewise";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      setAllAppointments(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [appointmentStatus, setAppointmentStatus] = useState("All");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  useEffect(() => {
    if (allAppointments.length > 0) {
      if (appointmentStatus === "All") {
        setFilteredAppointments([...allAppointments]);
      } else {
        let arr = allAppointments.filter(
          (appointment) => appointment.status === appointmentStatus
        );
        setFilteredAppointments(arr);
      }
    }
  }, [allAppointments, appointmentStatus]);

  const [appointmentsPieChartData, setAppointmentsPieChartData] = useState();
  const loadAppointmentsPieChartData = async () => {
    let url =
      process.env.REACT_APP_BASEURL + "appointments/report_daterange_pie";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      setAppointmentsPieChartData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [appointmentsLineChartStatus, setAppointmentsLineChartStatus] =
    useState("Booked");
  const [appointmentsLineChartData, setAppointmentsLineChartData] = useState();
  useEffect(() => {
    if (dateFrom && dateTo && appointmentsLineChartStatus) {
      loadAppointmentsLineChartData();
    }
  }, [dateFrom, dateTo, appointmentsLineChartStatus]);
  const loadAppointmentsLineChartData = async () => {
    let url =
      process.env.REACT_APP_BASEURL + "appointments/report_daterange_line";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
      status: appointmentsLineChartStatus,
    });
    if (response && response.status === 1) {
      setAppointmentsLineChartData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  function showBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.beautician_details.map((beautician) => {
          if (beautician.type === "Secondary") {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          }
        })}
      </>
    );
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Service Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "cost",
      text: props.t("Price"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "duration",
      text: props.t("Duration (minutes)"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Beauticians"),
      formatter: showBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
    },
  ];

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Start"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        return moment(row.start).format("DD-MM-YYYY HH:mm");
      },
      style: { cursor: "pointer" },
    },
    // {
    //   text: props.t("End"),

    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    //   formatter: (cell, row) => {
    //     return moment(row.end).format("DD-MM-YYYY HH:mm");
    //   },
    //   style: { cursor: "pointer" },
    // },
    {
      text: props.t("Check In"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.check_in) {
          return moment(row.check_in).format("DD-MM-YYYY HH:mm");
        } else {
          return props.t("NA");
        }
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Check Out"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.check_out) {
          return moment(row.check_out).format("DD-MM-YYYY HH:mm");
        } else {
          return props.t("NA");
        }
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "client_details.client_name",
      text: props.t("Client"),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "client_details.client_mobile",
      text: props.t("Client Mobile"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "is_homeservice",
      text: props.t("Home Service?"),
      formatter: (cell, row) => {
        return row.is_homeservice ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (cell, row) => {
        if (row.status === "Completed") {
          return (
            <span>
              {row.status} <br />
              <a
                href={"/invoice?invoice=" + row.serviceandsale_id}
                target="_blank"
                style={{
                  color: "blue",
                  textDecorationLine: "underline",
                }}
                className="font-size-11"
              >
                ({row.invoice_number})
              </a>
            </span>
          );
        } else {
          return row.status;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.service_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };
  const handleDownload = async () => {
    const fileName = "appointments";
    const exportType = "xls";
    let data_to_send_api = [];
    allAppointments.map((appointment) => {
      data_to_send_api.push(appointment._id);
    });
    let data_to_export = [];
    let url = process.env.REACT_APP_BASEURL + "appointments/toexcel"; //"services/getall";
    const response = await postSubmitForm(url, {
      appointment_ids: data_to_send_api,
    });
    if (response && response.status === 1) {
      data_to_export = response.data;
    } else {
      showNotification(response.message, "Error");
    }
    if (data_to_export) {
      var data = JSON.parse(JSON.stringify(data_to_export));
      data.forEach(function (v) {
        v.client_name = v.client_details.client_name;
        v.client_mobile = v.client_details.client_mobile;

        v.service_name = v.service_details.name;
        v.service_duration = v.service_details.duration;
        v.service_cost = v.service_details.cost;

        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.sold_product_details;
        delete v.title;
        delete v.client_details;
        delete v.service_details;
        delete v.invoice_number;
        delete v.serviceandsale_id;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Appointments Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <CardTitle>
                        {appointmentsLineChartStatus &&
                          props.t(
                            appointmentsLineChartStatus + " Appointments Chart"
                          )}
                      </CardTitle>
                    </Col>
                    <Col lg={6}>
                      <AvForm>
                        <AvField
                          name="appointmentStatusLineChart"
                          label={""}
                          type="select"
                          value={appointmentsLineChartStatus}
                          onChange={(e, v) => {
                            setAppointmentsLineChartStatus(e.target.value);
                          }}
                        >
                          <option value="Booked">{props.t("Booked")}</option>
                          <option value="In Process">
                            {props.t("In Process")}
                          </option>

                          <option value="Cancelled">
                            {props.t("Cancelled")}
                          </option>
                          <option value="Completed">
                            {props.t("Completed")}
                          </option>
                        </AvField>
                      </AvForm>
                    </Col>
                  </Row>

                  <div id="line-chart" className="e-chart">
                    <Line
                      line_data={
                        appointmentsLineChartData && appointmentsLineChartData
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Appointments Pie")}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      appointments_pie_data={
                        appointmentsPieChartData && appointmentsPieChartData
                      }
                      name={props.t("Appointments Pie")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Appointments")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all appointments here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    keyField="_id"
                    data={filteredAppointments && filteredAppointments}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                          <Col lg={4} className="text-right">
                            <select
                              name="status"
                              className="form-control"
                              value={appointmentStatus}
                              onChange={(e) => {
                                setAppointmentStatus(e.target.value);
                              }}
                            >
                              <option value="All">All</option>
                              <option value="Booked">Booked</option>
                              <option value="In Process">In Process</option>
                              <option value="Cancelled">Cancelled</option>
                              <option value="Completed">Completed</option>
                            </select>
                          </Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          rowStyle={rowStyle}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};

export default withRouter(
  connect(null, {})(withNamespaces()(AppointmentsReport))
);

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";
import { ShowSelectedOnlyButton } from "react-bootstrap-table";

const ClosureReport = (props) => {
  const { SearchBar } = Search;

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadClosureReport();
    }
  }, [dateFrom, dateTo]);

  const [closureReport, setClosureReport] = useState([]);
  const [filteredClosureReport, setFilteredClosureReport] = useState([]);
  const [showOnlyWithCashDifference, setShowOnlyWithCashDifference] =
    useState();
  useEffect(() => {
    if (closureReport) {
      let arr = closureReport.filter((e) =>
        showOnlyWithCashDifference ? e.paid_by_cash !== e.cash_in_hand : true
      );
      setFilteredClosureReport(arr);
    }
  }, [showOnlyWithCashDifference, closureReport]);
  const loadClosureReport = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/closure";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      setClosureReport(response.data);
      let arr = response.data.filter((e) =>
        showOnlyWithCashDifference ? e.paid_by_cash !== e.cash_in_hand : true
      );
      setFilteredClosureReport(arr);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "name",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return +rowIndex + 1;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      dataField: "date_of_closure",
      text: props.t("Date"),
      formatter: (cell, row) => {
        return moment(row.date_of_closure).format("DD/MM/YYYY");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "total_income",
      text: props.t("Income"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "total_expense",
      text: props.t("Expense"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "total_profit",
      text: props.t("Profit"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "in_salon_services",
      text: props.t("Salon Services"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "home_services",
      text: props.t("Home Services"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "completed_appointments",
      text: props.t("Complete Appt."),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "cancelled_appointments",
      text: props.t("Cancelled Appt."),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "customers_visited",
      text: props.t("Customers Visited"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },

    {
      dataField: "products_sold",
      text: props.t("Products Sold"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "charity_expense",
      text: props.t("Charity Expense"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },

    {
      dataField: "paid_by_card",
      text: props.t("Card Payment"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "wallet_transactions.credit",
      text: props.t("Wallet Credits"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "wallet_transactions.debit",
      text: props.t("Wallet Debits"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "paid_by_cash",
      text: props.t("Cash Payment"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "cash_in_hand",
      text: props.t("Cash In Hand"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
  ];
  const handleDownload = async () => {
    const fileName = "closure_report";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(closureReport));
    data.forEach(function (v) {
      v.wallet_credit = v.wallet_transactions.credit;
      v.wallet_debit = v.wallet_transactions.debit;
      v.date_of_closure = moment(v.date_of_closure).format("DD/MM/YYYY");

      delete v.wallet_transactions;
      delete v._id;
      delete v.product_details;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sales_transactions;
      delete v.use_transactions;
      delete v.status;
      delete v.quantity;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Closure Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Closure Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="scrollerTable">
                    <ToolkitProvider
                      keyField="_id"
                      data={filteredClosureReport && filteredClosureReport}
                      columns={columns_external}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4} className="text-right">
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="showOnlyWithCashDifference"
                                  id="showOnlyWithCashDifference"
                                  className="is-untouched is-pristine av-valid form-check-input"
                                  checked={showOnlyWithCashDifference}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShowOnlyWithCashDifference(true);
                                    } else {
                                      setShowOnlyWithCashDifference(false);
                                    }
                                  }}
                                />
                                <label for="showOnlyWithCashDifference">
                                  Only rows that have cash difference.
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(ClosureReport)));

import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import moment from "moment";
import { postSubmitForm } from "../../helpers/forms_helper";

const UpdateInventory = (props) => {
  useEffect(() => {
    loadAllProductCategories();
  }, []);

  const refContainer = useRef(null);
  const [isNoExpiry, setIsNoExpiry] = useState(false);
  const handleValidSubmit = async (e, v) => {
    try {
      let data_to_send = {};

      data_to_send.product_id = selectedProductID;

      data_to_send.expiry_date = isNoExpiry ? null : v.expiry_date;
      data_to_send.expiry_months = isNoExpiry ? v.expiry_months : null;

      data_to_send.qty_for_sale = forSale ? v.qty_for_sale : 0;
      data_to_send.qty_for_use = forUse ? v.qty_for_use : 0;

      data_to_send.cost_price = v.cost_price;
      data_to_send.selling_price = v.selling_price ? v.selling_price : 0;
      data_to_send.remarks = v.remarks;

      let url = process.env.REACT_APP_BASEURL + "inventories/insert";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        setForUse(false);
        setForSale(false);
        showNotification(response.message, "Success");
        refContainer.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [selectedCategoryID, setSelectedCategoryID] = useState();
  const [selectedProductID, setSelectedProductID] = useState();
  const [selectedProductDetails, setSelectedProductDetails] = useState();
  const [allProductCategories, setAllProductCategories] = useState();
  const loadAllProductCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProductCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [categoryWiseProducts, setCategoryWiseProducts] = useState();
  const loadCategoryWiseProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/get_by_category";
    const response = await postSubmitForm(url, {
      product_category_id: selectedCategoryID,
    });
    if (response && response.status === 1) {
      setCategoryWiseProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (selectedCategoryID) {
      loadCategoryWiseProducts();
      setSelectedProductDetails();
      setSelectedProductID("");
    }
  }, [selectedCategoryID]);

  const [forUse, setForUse] = useState(false);
  const [forSale, setForSale] = useState(false);
  const [currentDate] = useState(() => {
    var d1 = new Date();
    var d = moment(d1).add(1, "days").toDate();
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Inventory")}
            breadcrumbItem={props.t("Add Inventory")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Inventory")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add products to inventory"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="product_categories"
                          label={props.t("Category") + " *"}
                          type="select"
                          value={selectedCategoryID}
                          onChange={(e, v) => {
                            setSelectedCategoryID(e.target.value);
                          }}
                          required
                          errorMessage={props.t("Select Category")}
                        >
                          <option value="" name="">
                            -- {props.t("Select Category")} --
                          </option>
                          {allProductCategories &&
                            allProductCategories.map((item) => {
                              return (
                                <option value={item._id} name={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="product_name"
                          label={props.t("Product") + " *"}
                          type="select"
                          value={selectedProductID}
                          onChange={(e, v) => {
                            setSelectedProductID(e.target.value);

                            let arr = categoryWiseProducts.filter(
                              (prod) => prod._id === e.target.value
                            );
                            setSelectedProductDetails(arr[0]);
                          }}
                          required
                          errorMessage={props.t("Select Product")}
                        >
                          <option value="" name="">
                            -- {props.t("Select Product")} --
                          </option>
                          {categoryWiseProducts &&
                            categoryWiseProducts.map((item) => {
                              return (
                                <option value={item._id} name={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </AvField>
                      </Col>
                      {selectedProductDetails && (
                        <Col lg={6}>
                          <Label>{props.t("Quantity") + " *"}</Label>
                          {selectedProductDetails.is_for_sale ? (
                            <Row>
                              <Col lg={3}>
                                <AvField
                                  type="checkbox"
                                  name="for_sale"
                                  label="For Sale"
                                  onChange={(e, v) => {
                                    setForSale(v);
                                  }}
                                  required={!forUse}
                                  errorMessage={props.t(
                                    "Select either 'For Sale' or 'For Use.' or both."
                                  )}
                                />
                              </Col>
                              <Col lg={9}>
                                {forSale ? (
                                  <AvField
                                    name="qty_for_sale"
                                    placeholder={props.t(
                                      "Enter Quantity For Sale"
                                    )}
                                    errorMessage="Quantity cannot be empty."
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[0-9]+$",
                                        errorMessage: props.t(
                                          "Only numbers are allowed."
                                        ),
                                      },
                                    }}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                          ) : null}
                          {selectedProductDetails.is_for_use ? (
                            <Row>
                              <Col lg={3}>
                                <AvField
                                  type="checkbox"
                                  name="for_use"
                                  label="For Use"
                                  onChange={(e, v) => {
                                    setForUse(v);
                                  }}
                                  required={!forSale}
                                  errorMessage={props.t(
                                    "Select either 'For Sale' or 'For Use.' or both."
                                  )}
                                />
                              </Col>
                              <Col lg={9}>
                                {forUse ? (
                                  <AvField
                                    name="qty_for_use"
                                    placeholder={props.t(
                                      "Enter Quantity For Use"
                                    )}
                                    errorMessage="Quantity cannot be empty."
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[0-9]+$",
                                        errorMessage: props.t(
                                          "Only numbers are allowed."
                                        ),
                                      },
                                    }}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                          ) : null}
                          <br />
                        </Col>
                      )}
                      <Col lg={6}>
                        <Row>
                          <Col lg={7}>
                            {isNoExpiry ? (
                              <AvField
                                name="expiry_months"
                                label={props.t("Expiry Months") + " *"}
                                placeholder={props.t("Enter Number of Months")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                }}
                                required
                                errorMessage="Expiry months cannot be empty"
                              />
                            ) : (
                              <AvField
                                name="expiry_date"
                                label={props.t("Expiry Date") + " *"}
                                placeholder={props.t("Select Expiry Date")}
                                type="date"
                                min={currentDate}
                                required
                                errorMessage="Expiry date cannot be empty"
                              />
                            )}
                          </Col>
                          <Col lg={5}>
                            <br />
                            <br />
                            <div class="form-check">
                              <input
                                type="checkbox"
                                name="is_no_expiry"
                                className="is-untouched is-pristine av-valid form-check-input"
                                checked={isNoExpiry}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsNoExpiry(true);
                                  } else {
                                    setIsNoExpiry(false);
                                  }
                                }}
                              />
                              <label for="select_all">
                                {" "}
                                {props.t("There is no expiry date.")}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="cost_price"
                          label={props.t("Cost Price") + " *"}
                          placeholder={props.t("Enter Cost Price")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Cost price cannot be empty"
                              ),
                            },
                            pattern: {
                              value: "^\\d+(\\.\\d{1,2})?$",
                              errorMessage: props.t(
                                "Only numbers and decimals upto two places are allowed."
                              ),
                            },
                          }}
                        />
                      </Col>
                      {selectedProductDetails &&
                        selectedProductDetails.is_for_sale && (
                          <Col lg={6}>
                            <AvField
                              name="selling_price"
                              label={props.t("Selling Price") + " *"}
                              placeholder={props.t("Enter Selling Price")}
                              type="text"
                              validate={{
                                required: {
                                  value: forSale ? true : false,
                                  errorMessage: props.t(
                                    "Selling price cannot be empty"
                                  ),
                                },
                                pattern: {
                                  value: "^\\d+(\\.\\d{1,2})?$",
                                  errorMessage: props.t(
                                    "Only numbers and decimals upto two places are allowed."
                                  ),
                                },
                              }}
                            />
                          </Col>
                        )}

                      <Col lg={6}>
                        <AvField
                          name="remarks"
                          label={props.t("Remarks")}
                          placeholder={props.t("Enter Remarks")}
                          type="textarea"
                        />
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(UpdateInventory))
);

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//DataTable
import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AllSources = (props) => {
  useEffect(() => {
    loadSources();
  }, []);
  const [allSources, setAllSources] = useState([]);
  const loadSources = async () => {
    let url = process.env.REACT_APP_BASEURL + "sources/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllSources(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
    },
    {
      dataField: "description",
      text: props.t("Description"),
    },
  ];

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Sources of Expense")}
            breadcrumbItem={props.t("All Sources")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Sources")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing sources of expense here")}
                  </CardSubtitle>

                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allSources && allSources}
                    columns={columns}
                    noDataIndication={props.t("There is no data to display")}
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AllSources)));

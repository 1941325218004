import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AddServiceCategory = (props) => {
  useEffect(() => {
    loadAllServiceCategories();
  }, []);
  const refContainer = useRef(null);
  const [allServiceCategories, setAllServiceCategories] = useState([]);
  const loadAllServiceCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllServiceCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllServiceCategories();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
    refContainer.current.reset();
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: false,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Service Categories")}
            breadcrumbItem={props.t("Add Service Category")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Service Category")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new service category"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name") + " *"}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          errorMessage="Name cannot be empty."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="remarks"
                          label={props.t("Remarks")}
                          placeholder={props.t("Enter Remarks")}
                          type="textarea"
                        />
                      </Col>

                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    {props.t("Existing Service Categories")}{" "}
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing service categories here")}
                  </CardSubtitle>

                  <hr />
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allServiceCategories && allServiceCategories}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(
  connect(null, {})(withNamespaces()(AddServiceCategory))
);

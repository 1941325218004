import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const CharityReport = (props) => {
  const [charityDate, setCharityDate] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();

    setCharityDate(formatDate(currentDay));
  }, []);
  useEffect(() => {
    loadCharityReport();
  }, [charityDate]);
  const [charityReport, setCharityReport] = useState([]);
  const [totalCharity, setTotalCharity] = useState();
  const loadCharityReport = async () => {
    let url = process.env.REACT_APP_BASEURL + "serviceandsales/charity";
    const response = await postSubmitForm(url, { charity_date: charityDate });
    if (response && response.status === 1) {
      setCharityReport(response.data.serviceandsales);
      setTotalCharity(response.data.total_charity);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleSubmitCharity = async () => {
    let url = process.env.REACT_APP_BASEURL + "expenses/insert_charity";
    const object = {
      amount: totalCharity,
      date_of_transaction: charityDate,
    };
    console.log(object);
    const response = await postSubmitForm(url, object);
    if (response && response.status === 1) {
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  function invoiceFormatter(cell, row) {
    return (
      <a
        href={"/invoice?invoice=" + row._id}
        style={{ color: "blue" }}
        target="_blank"
      >
        {row.invoice_number}
      </a>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Invoice"),
      formatter: invoiceFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Client Name"),
      dataField: "client_details.client_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },

    {
      dataField: "final_amount",
      text: props.t("Final Amount"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      headerAlign: (column, colIndex) => "right",
      align: (cell, row, rowIndex, colIndex) => {
        return "right";
      },
    },
    {
      dataField: "charity_amount",
      text: props.t("Charity Amount"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      headerAlign: (column, colIndex) => "right",
      align: (cell, row, rowIndex, colIndex) => {
        return "right";
      },
    },
  ];
  const handleDownload = () => {
    const fileName = "charity";
    const exportType = "xls";
    console.log(charityReport);
    if (charityReport) {
      var data = JSON.parse(JSON.stringify(charityReport));
      data.forEach(function (v) {
        // v.name = v.name;
        // v.available_quantity_for_sale = v.is_for_sale
        //   ? v.available_quantity_for_sale
        //   : props.t("NA");
        // v.threshold_quantity_for_sale = v.is_for_sale
        //   ? v.threshold_quantity_for_sale
        //   : props.t("NA");
        // v.available_quantity_for_use = v.is_for_use
        //   ? v.available_quantity_for_use
        //   : props.t("NA");
        // v.threshold_quantity_for_use = v.is_for_use
        //   ? v.threshold_quantity_for_use
        //   : props.t("NA");
        // v.available_quantity_for_use = v.available_quantity_for_use;
        // v.is_for_sale = v.is_for_sale ? props.t("Yes") : props.t("No");
        // v.is_for_use = v.is_for_use ? props.t("Yes") : props.t("No");

        // v.category = v.product_category_details.name;
        v.client_name = v.client_details.client_name;
        delete v.appointment_id;
        delete v.service_details;
        delete v.sold_product_details;
        delete v.service_amount;
        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.client_details;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };
  const handlePrint = () => {
    let printContents = document.getElementById("printDiv").innerHTML;
    let originalContents = document.body.innerHTML;
    console.log(document.getElementById("printDiv"));
    document.body.innerHTML = printContents;

    window.print();
    window.location.reload();
    //document.body.innerHTML = originalContents;
    //window.print();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Charity Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="charity_date"
                          label={props.t("Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={charityDate}
                          onChange={(e, v) => {
                            setCharityDate(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Charity Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary mr-2"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handlePrint}
                      >
                        <i className="mdi mdi-printer"></i> {props.t("Print")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                    <Col sm="4"></Col>
                    <Col sm="4" className="text-right">
                      <Label className="mr-2">
                        Total Charity: <b>QR {totalCharity && totalCharity}</b>
                      </Label>
                      <Button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={handleSubmitCharity}
                      >
                        {props.t("Submit to Expense")}
                      </Button>
                    </Col>
                  </Row>
                  <div className="row" id="printDiv">
                    <div className="col-md-12">
                      <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={charityReport && charityReport}
                        columns={columns}
                        noDataIndication={props.t("No data to display.")}
                        striped
                        hover
                        condensed
                      />{" "}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(CharityReport))
);

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const ViewSalary = (props) => {
  useEffect(() => {
    loadSalaries();
  }, []);

  const [allSalaries, setAllSalaries] = useState([]);
  const loadSalaries = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/get_salaries";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllSalaries(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [employeesModal, setEmployeesModal] = useState();
  const [selectedMonth, setSelectedMonth] = useState();

  function viewDetailsFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="mdi mdi-text-box-search font-size-15"
          title="View Details"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedMonth(row);
            setEmployeesModal(!employeesModal);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Salary"),
      dataField: "total_salary",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Incentive"),
      dataField: "total_incentive",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "total_deduction",
      text: props.t("Deduction"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "11%" };
      },
    },
    {
      text: props.t("Total"),
      dataField: "grand_total",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "45%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: viewDetailsFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const columns_employees = [
    {
      dataField: "employee_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "designation",
      text: props.t("Designation"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "salary",
      text: props.t("Salary"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      dataField: "target",
      text: props.t("Target"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "achieved_target",
      text: props.t("Achieved Target"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "17%" };
      },
    },
    {
      dataField: "incentive",
      text: props.t("Incentive"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "deduction",
      text: props.t("Deduction"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "total",
      text: props.t("Total"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem={props.t("Salaries")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("View Salaries")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all generated salaries here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allSalaries && allSalaries}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                  <Modal
                    size="lg"
                    isOpen={employeesModal}
                    toggle={() => setEmployeesModal(!employeesModal)}
                    style={{ minWidth: "900px", width: "1000px" }}
                  >
                    <ModalHeader
                      toggle={() => setEmployeesModal(!employeesModal)}
                    >
                      {selectedMonth && selectedMonth.remarks}
                    </ModalHeader>
                    <ModalBody>
                      {selectedMonth && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <BootstrapTable
                                        bootstrap4
                                        keyField="employee_id"
                                        data={selectedMonth.employee_details}
                                        columns={columns_employees}
                                        noDataIndication="No data to display."
                                        striped
                                        hover
                                        condensed
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ViewSalary))
);

import React from "react";
//i18n
import { withNamespaces } from "react-i18next";
import { Row, Col, Card } from "reactstrap";

const WelcomeComp = (props) => {
  //const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="12">
              <div className="text-primary p-3">
                <h5 className="text-primary">
                  {props.t("Welcome")} {props.t(localStorage.getItem("name"))}
                </h5>
                <p>{props.t("Salon Management")}</p>
              </div>
            </Col>
            {/* <Col xs="5" className="align-self-end">
               <img src={profileImg} alt="" className="img-fluid" /> 
            </Col>*/}
          </Row>
        </div>
        {/* 
        {role && role !== "deliveryagent" && (
          <>
            <CardBody className="pt-0">
              <Row>
                <Col sm="12">
                  <div className="mt-4">
                    <Link
                      to="items-liveitem"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      {props.t("Live Items")}{" "}
                      <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </div>
                  <div className="mt-4">
                    <Link
                      to="items-item"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      {props.t("Add Item")}{" "}
                      <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </div>
                  <div className="mt-4">
                    <Link
                      to="sellers-addseller"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      {props.t("Add Sellers")}{" "}
                      <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </>
        )}
         */}
      </Card>
    </React.Fragment>
  );
};
export default withNamespaces()(WelcomeComp);

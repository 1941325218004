import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import { Container } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Scheduler from "./Scheduler";

const AddAppointment = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Appointments")}
            breadcrumbItem={props.t("Add Appointment")}
          />
          <Scheduler />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddAppointment)));

import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import swal from "sweetalert2";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postSubmitForm } from "../../helpers/forms_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import moment from "moment";

const Incentives = (props) => {
  const refContainer = useRef(null);
  const [currentMonth, setCurrentMonth] = useState();
  const [currentDate, setCurrentDate] = useState(() => {
    var date = new Date();

    var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // month = "" + d.getMonth(), // + 1
    // year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    return moment(d).format("YYYY-MM-DD"); //[year, month, "01"].join("-");
  });
  useEffect(() => {
    loadAllIncentives();
    setSelectedEmployee();
    setAchievedTarget();
    refContainer.current.reset();
    setCurrentMonth(currentDate.substring(0, 7));
  }, [currentDate]);
  useEffect(() => {
    loadAllEmployees();
  }, []);

  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const loadAllEmployees = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall";
    const response = await postSubmitForm(url, {});

    if (response && response.status === 1) {
      setAllEmployees(response.data);
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
  };
  const [achievedTarget, setAchievedTarget] = useState();
  const getAchievedTarget = async (employee_id) => {
    let url = process.env.REACT_APP_BASEURL + "employees/get_targetachieved";
    const response = await postSubmitForm(url, {
      employee_id: employee_id,
      date_of_transaction: currentDate,
    });

    if (response && response.status === 1) {
      setAchievedTarget(response.data.achieved_target);
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
  };

  const [allIncentives, setAllIncentives] = useState([]);
  const loadAllIncentives = async () => {
    let url = process.env.REACT_APP_BASEURL + "incentives/getbydate";
    const response = await postSubmitForm(url, {
      date_of_transaction: currentDate,
    });

    if (response && response.status === 1) {
      setAllIncentives(response.data);
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
  };
  const handleAddIncentive = async (e, v) => {
    try {
      let data_to_send = {};
      data_to_send.employee_id = selectedEmployee._id;
      data_to_send.name = selectedEmployee.name;
      data_to_send.mobile = selectedEmployee.mobile;
      data_to_send.designation = selectedEmployee.designation;
      data_to_send.salary = selectedEmployee.salary;
      data_to_send.target = selectedEmployee.monthly_target;
      data_to_send.achieved_target = achievedTarget;
      data_to_send.date_of_transaction = currentDate;
      data_to_send.amount = v.amount;
      let url = process.env.REACT_APP_BASEURL + "incentives/insert";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllIncentives();
        setSelectedEmployee();
        setAchievedTarget();
        refContainer.current.reset();
        showNotification(props.t(response.message), props.t("Success"));
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };

  const [selectedIncentiveRecord, setSelectedIncentiveRecord] = useState();
  const [editModal, setEditModal] = useState();
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      sort: true,
    },
    {
      dataField: "salary",
      text: props.t("Salary"),
      sort: true,
    },
    {
      dataField: "target",
      text: props.t("Target"),
      sort: true,
    },
    {
      dataField: "achieved_target",
      text: props.t("Achieved Target"),
      sort: true,
    },
    {
      dataField: "amount",
      text: props.t("Incentive"),
      sort: true,
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: (cell, row) => {
        return (
          <>
            <span className="text-info mr-2">
              <i
                className="bx bxs-edit font-size-15"
                title="Click to Edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditModal(!editModal);
                  setSelectedIncentiveRecord(row);
                }}
              ></i>
            </span>
            <span className="text-danger">
              <i
                className="bx bxs-trash font-size-15"
                title="Click to Delete"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleIncentiveDelete(row);
                }}
              ></i>
            </span>
          </>
        );
      },
    },
  ];

  const handleValidUpdate = async (e, v) => {
    try {
      let data_to_send = {};
      data_to_send.id = selectedIncentiveRecord._id;
      data_to_send.employee_id = selectedIncentiveRecord.employee_id;
      data_to_send.name = selectedIncentiveRecord.name;
      data_to_send.mobile = selectedIncentiveRecord.mobile;
      data_to_send.designation = selectedIncentiveRecord.designation;
      data_to_send.salary = selectedIncentiveRecord.salary;
      data_to_send.target = selectedIncentiveRecord.target;
      data_to_send.achieved_target = selectedIncentiveRecord.achieved_target;
      data_to_send.date_of_transaction =
        selectedIncentiveRecord.date_of_transaction;
      data_to_send.amount = v.amount;
      let url = process.env.REACT_APP_BASEURL + "incentives/update";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllIncentives();

        setEditModal(!editModal);
        showNotification(props.t(response.message), props.t("Success"));
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  const handleIncentiveDelete = async (incentive_details) => {
    try {
      let data_to_send = {};
      data_to_send.id = incentive_details._id;
      let url = process.env.REACT_APP_BASEURL + "incentives/delete";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllIncentives();

        showNotification(props.t(response.message), props.t("Success"));
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem={props.t("Incentives")}
          />
          <Row>
            <Col className="col-6">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="salary_date"
                          label={props.t("Select Month")}
                          type="month"
                          value={currentMonth}
                          onChange={(e, v) => {
                            var date = new Date();

                            var d = new Date(
                              v.substring(0, 4),
                              v.substring(5, 7),
                              0
                            );

                            setCurrentDate(moment(d).format("YYYY-MM-DD"));
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Incentives")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View and add incentives here")}
                  </CardSubtitle>
                  <AvForm onValidSubmit={handleAddIncentive} ref={refContainer}>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={3}>
                            <AvField
                              name="employee_id"
                              label={props.t("Select Employee") + " *"}
                              placeholder={props.t("Enter Employee")}
                              type="select"
                              onChange={(e, v) => {
                                getAchievedTarget(e.target.value);
                                let arr = allEmployees.filter(
                                  (employee) => employee._id === e.target.value
                                );
                                setSelectedEmployee(arr[0]);
                              }}
                              validate={{ required: { value: true } }}
                              errorMessage={props.t("Select Employee")}
                            >
                              <option value="">
                                -- {props.t("Select Employee")} --
                              </option>
                              {allEmployees &&
                                allEmployees.map((r) => (
                                  <option key={r._id} value={r._id}>
                                    {r.name}
                                  </option>
                                ))}
                            </AvField>
                          </Col>
                          {selectedEmployee && (
                            <>
                              <Col lg={2}>
                                <AvField
                                  name="amount"
                                  label={props.t("Incentive") + " *"}
                                  placeholder={props.t("Enter Incentive")}
                                  errorMessage={props.t(
                                    "Incentive cannot be empty."
                                  )}
                                  type="text"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^\\d+(\\.\\d{1,2})?$",
                                      errorMessage: props.t(
                                        "Only numbers and decimals upto two places are allowed."
                                      ),
                                    },
                                  }}
                                />
                              </Col>
                              <Col lg={2}>
                                <Label>{props.t("Salary")}</Label> <br />
                                <Label>{selectedEmployee.salary}</Label>
                              </Col>

                              <Col lg={2}>
                                <Label>{props.t("Monthly Target")}</Label>{" "}
                                <br />
                                <Label>{selectedEmployee.monthly_target}</Label>
                              </Col>
                              <Col lg={2}>
                                <Label>{props.t("Target Achieved")}</Label>{" "}
                                <br />
                                <Label>
                                  {achievedTarget && achievedTarget}
                                </Label>
                              </Col>
                            </>
                          )}

                          <Col lg={1} sm={6}>
                            <div>
                              <Label>&nbsp;</Label>
                              <br />
                              <Button
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                {props.t("Add")}
                              </Button>{" "}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </AvForm>
                  <hr />
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allIncentives && allIncentives}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                  <Modal
                    size="md"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Incentive")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedIncentiveRecord && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <AvField
                                          name="amount"
                                          value={selectedIncentiveRecord.amount}
                                          label={props.t("Incentive") + " *"}
                                          placeholder={props.t(
                                            "Enter Incentive"
                                          )}
                                          errorMessage="Incentive cannot be empty."
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value: "^\\d+(\\.\\d{1,2})?$",
                                              errorMessage: props.t(
                                                "Only numbers and decimals upto two places are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(Incentives)));

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";

const ServiceSalesReport = (props) => {
  const { SearchBar } = Search;

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    loadAllCategories();
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadServiceSalesReport();
    }
  }, [dateFrom, dateTo]);

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "servicecategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [filteredServiceSalesReport, setFilteredServiceSalesReport] = useState(
    []
  );
  const [showOnlyHomeServices, setShowOnlyHomeServices] = useState(false);
  const filterServices = async () => {
    let arr = serviceSalesReport.filter(
      (s) =>
        (selectedCategory === "All"
          ? true
          : s.service_category === selectedCategory) &&
        (showOnlyHomeServices ? s.is_homeservice : true)
    );
    setFilteredServiceSalesReport(arr);
  };
  useEffect(() => {
    filterServices();
  }, [showOnlyHomeServices, selectedCategory]);

  const [serviceSalesReport, setServiceSalesReport] = useState([]);
  const loadServiceSalesReport = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/sales";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
      type: "service",
    });
    if (response && response.status === 1) {
      setServiceSalesReport(response.data);
      setFilteredServiceSalesReport(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  function showBeauticiansFormatter(cell, row) {
    return (
      <>
        {row.service_details.beautician_details.map((beautician) => {
          if (beautician.type === "Secondary") {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span
                  className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1"
                  title={props.t("Mobile:") + " " + beautician.mobile}
                >
                  {beautician.name}
                </span>
              </>
            );
          }
        })}
      </>
    );
  }
  const columns_external = [
    {
      dataField: "service_details._id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "name",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return +rowIndex + 1;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "service_category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "service_details.name",
      text: props.t("Service Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "service_details.duration",
      text: props.t("Duration"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "service_details.cost",
      text: props.t("Cost"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "is_homeservice",
      text: props.t("Home Service"),
      formatter: (cell, row) => {
        return row.is_homeservice ? "Yes" : "No";
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },

    {
      dataField: "client_details.client_name",
      text: props.t("Client Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Beauticians"),
      formatter: showBeauticiansFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Billed At"),
      formatter: (cell, row) => {
        return moment(row.createdAt).format("DD/MM/YYYY hh:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];
  const handleDownload = async () => {
    const fileName = "service_report";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(serviceSalesReport));
    data.forEach(function (v) {
      v.client_name = v.client_details.client_name;
      v.client_mobile = v.client_details.client_mobile;
      v.service_name = v.service_details.name;
      v.service_duration = v.service_details.duration;
      v.service_cost = v.service_details.cost;
      v.billed_at = moment(v.createdAt).format("DD/MM/YYYY hh:mm");

      delete v._id;
      delete v.appointment_id;
      delete v.sold_product_details;
      delete v.service_amount;
      delete v.sold_product_amount;
      delete v.total_amount;
      delete v.paid_amount;
      delete v.discount;
      delete v.final_amount;
      delete v.paid_by_cash;
      delete v.paid_by_card;
      delete v.from_wallet;
      delete v.remarks;
      delete v.invoice_number;
      delete v.client_details;
      delete v.product_details;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sales_transactions;
      delete v.use_transactions;
      delete v.status;
      delete v.quantity;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Service Sales Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Service Sales Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    keyField="service_details._id"
                    data={
                      filteredServiceSalesReport && filteredServiceSalesReport
                    }
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}>
                            <select
                              name="service_categories"
                              className="form-control"
                              onChange={(e, v) => {
                                setSelectedCategory(e.target.value);
                              }}
                            >
                              <option value="All">All Categories</option>
                              {allCategories &&
                                allCategories.map((item) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </Col>
                          <Col lg={4}>
                            <div className="form-check text-right">
                              <input
                                type="checkbox"
                                name="showOnlyHomeServices"
                                id="showOnlyHomeServices"
                                className="is-untouched is-pristine av-valid form-check-input"
                                checked={showOnlyHomeServices}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setShowOnlyHomeServices(true);
                                  } else {
                                    setShowOnlyHomeServices(false);
                                  }
                                }}
                              />
                              <label for="showOnlyHomeServices">
                                Show only home services.
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(ServiceSalesReport))
);

import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Closure from "../components/closure";

import { postSubmitForm } from "../helpers/forms_helper";

const ClosurePrint = (props) => {
  const [closureData, setClosureData] = useState();
  const loadClosureData = async (data) => {
    let url = process.env.REACT_APP_BASEURL + "reports/closure_print";
    const response = await postSubmitForm(url, data);
    if (response && response.status === 1) {
      setClosureData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("closure")) {
      console.log(urlParams.get("closure"));
      loadClosureData({ closure_id: urlParams.get("closure") });
    }
  }, []);

  return (
    <React.Fragment>
      <Closure closure_data={closureData && closureData}></Closure>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ClosurePrint))
);

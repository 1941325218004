import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";
import SweetAlert from "react-bootstrap-sweetalert";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import moment from "moment";
import { postSubmitForm } from "../../helpers/forms_helper";
import success_image from "../../assets/images/tick.png";

const AddOrder = (props) => {
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();

  const [allProducts, setAllProducts] = useState();

  const [discount, setDiscount] = useState("0");
  const [paidCash, setPaidCash] = useState("0");
  const [paidCard, setPaidCard] = useState("0");
  const [fromWallet, setFromWallet] = useState("0");
  const [amountPaid, setAmountPaid] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [remarks, setRemarks] = useState("");

  const [selectedProduct, setSelectedProduct] = useState();
  const [quantity, setQuantity] = useState(0);
  const [productsForSale, setProductsForSale] = useState([]);
  const [totalProductAmount, setTotalProductAmount] = useState(0);

  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const loadClients = async () => {
    let url = process.env.REACT_APP_BASEURL + "clients/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllClients(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  useEffect(() => {
    loadProducts();
    loadClients();
  }, []);
  useEffect(() => {
    setTotalAmount(totalProductAmount);
  }, [totalProductAmount]);
  useEffect(() => {
    setAmountPaid(+totalAmount - +discount - +fromWallet);
    // setPaidCash(+totalAmount - +discount - +fromWallet - +paidCard);
  }, [totalAmount, discount, fromWallet, paidCard]);
  useEffect(() => {
    setFinalAmount(+totalAmount - +discount);
  }, [totalAmount, discount]);
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/get_for_sale";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [productBatches, setProductBatches] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const loadBatches = async (product_details) => {
    let url = process.env.REACT_APP_BASEURL + "inventories/get_for_sale";
    //console.log(product_details);
    const response = await postSubmitForm(url, {
      product_id: product_details.value,
    });
    if (response && response.status === 1) {
      setProductBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const refContainer = useRef(null);
  const handleAddProductForSale = async () => {
    if (!selectedBatch) {
      showNotification(
        props.t("Please select product batch."),
        props.t("Error")
      );
    } else if (!quantity || quantity <= 0) {
      showNotification(props.t("Please add quantity."), props.t("Error"));
    } else {
      var new_array = JSON.parse(JSON.stringify(productsForSale));
      const existing_product = new_array.filter(function (product) {
        return product.batch === selectedBatch.batch;
      });
      if (existing_product.length > 0) {
        showNotification(
          props.t("This product batch is already added."),
          "Error"
        );
      } else {
        if (selectedBatch.quantity_for_sale < quantity) {
          showNotification(props.t("Quantity not in stock."), "Error");
        } else {
          setProductsForSale([
            ...productsForSale,
            {
              batch: selectedBatch.batch,
              name: selectedProduct.label,
              product_id: selectedProduct.value,
              quantity: quantity,
              cost_price: selectedBatch.cost_price,
              selling_price: selectedBatch.selling_price,
              total_price: +selectedBatch.selling_price * +quantity,
            },
          ]);
          setTotalProductAmount(
            totalProductAmount + +selectedBatch.selling_price * +quantity
          );
          setQuantity(0);
          setSelectedProduct();
        }
      }
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <span className="text-danger">
        <i
          className="bx bxs-trash font-size-15"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            let arr = extraData.filter((item) => item.batch !== row.batch);
            setTotalProductAmount(totalProductAmount - row.total_price);
            setProductsForSale(arr);
          }}
        ></i>
      </span>
    );
  }
  const columns_products = [
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Price"),
      sort: false,
      editable: true,
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = { title: "Click to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = { cursor: "pointer" };
        return cur_style;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Quantity"),
      dataField: "quantity",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Total Price"),
      dataField: "total_price",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForSale,
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];

  //#region Add new client popup functions
  const [newClientModal, setNewClientModal] = useState();
  const handleAddNewClient = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "clients/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadClients();
      response.data.label =
        response.data.name +
        " (" +
        props.t("Mobile") +
        ": " +
        response.data.mobile +
        ")";
      response.data.value = response.data.mobile;
      setSelectedClient(response.data);
      setNewClientModal(false);
    } else {
      showNotification(response.message, "Error");
    }
  };
  //#endregion Add new client popup functions
  const submitOrderClicked = async () => {
    if (totalAmount === +paidCash + +paidCard + +discount + +fromWallet) {
      let data_to_send = {
        client_details: {
          client_id: selectedClient._id,
          client_name: selectedClient.name,
          client_mobile: selectedClient.mobile,
        },
        sold_product_details: productsForSale,
        sold_product_amount: totalProductAmount,
        total_amount: totalAmount,
        final_amount: finalAmount,
        paid_amount: amountPaid,
        discount: discount,
        from_wallet: fromWallet,
        paid_by_cash: paidCash,
        paid_by_card: paidCard,
        remarks: remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "serviceandsales/sales";

      const response = await postSubmitForm(url, data_to_send);
      //console.log(data_to_send);
      if (response && response.status === 1) {
        setSubmitSuccess(true);
        setSubmitResponse(response.data);
        setDiscount("0");
        setFromWallet("0");
        setAmountPaid();
        setTotalAmount(0);
        setFinalAmount(0);
        setRemarks("");
        setProductsForSale([]);
        setSelectedClient();
        setSelectedProduct();
        setTotalProductAmount(0);
        setQuantity(0);
        refContainer.current.reset();
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    } else {
      showNotification("Total amount does not match entered amount.", "Error");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Product Sales")}
            breadcrumbItem={props.t("Add New Order")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm ref={refContainer}>
                    <CardTitle>{props.t("Product Sales")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add new to order"
                      )}
                    </CardSubtitle>
                    {!submitSuccess ? (
                      <>
                        <Row>
                          <Col lg={4}>
                            {" "}
                            <Label>{props.t("Client")}</Label>
                            <Select
                              value={selectedClient}
                              onChange={(e) => {
                                setSelectedClient(e);
                              }}
                              placeholder={props.t("Select...")}
                              options={allClients}
                              classNamePrefix="select2-selection"
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            {" "}
                            <Label>&nbsp;</Label>
                            <br />
                            <Link
                              class="btn btn-success waves-effect waves-light btn-sm"
                              onClick={() => {
                                setNewClientModal(!newClientModal);
                              }}
                            >
                              <i class="mdi mdi-file-plus"></i>{" "}
                              {props.t("Add New")}
                            </Link>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <p class="text-muted mb-2">
                                <b> {props.t("Amount to Pay")}</b>
                              </p>
                              <h5>QR {amountPaid && amountPaid}</h5>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div class="button-items">
                              <p class="text-muted mb-2"></p>
                              <button
                                type="button"
                                class="btn  btn-dark waves-effect btn-label waves-light"
                                onClick={() => {
                                  if (productsForSale.length <= 0) {
                                    showNotification(
                                      props.t("Please select a product."),
                                      props.t("Error")
                                    );
                                  } else if (!selectedClient) {
                                    showNotification(
                                      props.t("Please select a client."),
                                      props.t("Error")
                                    );
                                  } else {
                                    setConfirmSubmit(true);
                                  }
                                }}
                              >
                                <i class="bx bx-cart  font-size-16 align-middle me-2"></i>{" "}
                                {props.t("Check-out")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col lg={1}>
                            <div>
                              <p class="text-muted mb-2">
                                {props.t("Total Amount")}
                              </p>
                              <h5>QR {totalAmount && totalAmount}</h5>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <AvForm>
                              <p class="text-muted mb-2">
                                {props.t("Paid by Cash")}
                              </p>
                              <AvField
                                name="paid_by_cash"
                                placeholder={props.t("Paid by Cash")}
                                type="text"
                                value={paidCash}
                                onChange={(e) => {
                                  setPaidCash(e.target.value);
                                }}
                                validate={{
                                  pattern: {
                                    value: "^\\d+(\\.\\d{1,2})?$",
                                    errorMessage: props.t(
                                      "Only numbers and decimals upto two places are allowed."
                                    ),
                                  },
                                }}
                              />
                            </AvForm>
                          </Col>
                          <Col lg={2}>
                            <AvForm>
                              <p class="text-muted mb-2">
                                {props.t("Paid by Card")}
                              </p>
                              <AvField
                                name="paid_by_card"
                                placeholder={props.t("Paid by Card")}
                                type="text"
                                value={paidCard}
                                onChange={(e) => {
                                  setPaidCard(e.target.value);
                                }}
                                validate={{
                                  pattern: {
                                    value: "^\\d+(\\.\\d{1,2})?$",
                                    errorMessage: props.t(
                                      "Only numbers and decimals upto two places are allowed."
                                    ),
                                  },
                                }}
                              />
                            </AvForm>
                          </Col>

                          <Col lg={2}>
                            <AvForm>
                              <p class="text-muted mb-2">
                                {props.t("Discount")}
                              </p>
                              <AvField
                                name="discount"
                                placeholder={props.t("Enter Discount")}
                                type="text"
                                value={discount}
                                onChange={(e) => {
                                  setDiscount(e.target.value);
                                }}
                                validate={{
                                  pattern: {
                                    value: "^\\d+(\\.\\d{1,2})?$",
                                    errorMessage: props.t(
                                      "Only numbers and decimals upto two places are allowed."
                                    ),
                                  },
                                }}
                              />
                            </AvForm>
                          </Col>
                          <Col lg={2}>
                            <AvForm>
                              <p class="text-muted mb-2">
                                {props.t("From Wallet")}{" "}
                                {selectedClient &&
                                  "(" +
                                    props.t("Avail.") +
                                    ": " +
                                    selectedClient.wallet +
                                    ")"}{" "}
                                {selectedClient &&
                                selectedClient.wallet < fromWallet ? (
                                  <span className="text-danger">
                                    {selectedClient &&
                                      selectedClient.wallet - fromWallet}
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    {fromWallet}
                                  </span>
                                )}
                              </p>
                              <AvField
                                name="from_wallet"
                                placeholder={props.t("Amount from Wallet")}
                                type="text"
                                value={fromWallet}
                                onChange={(e) => {
                                  setFromWallet(e.target.value);
                                }}
                                validate={{
                                  pattern: {
                                    value: "^\\d+(\\.\\d{1,2})?$",
                                    errorMessage: props.t(
                                      "Only numbers and decimals upto two places are allowed."
                                    ),
                                  },
                                }}
                              />
                            </AvForm>
                          </Col>
                          <Col lg={3}>
                            <AvForm>
                              <p class="text-muted mb-2">
                                {props.t("Remarks")}
                              </p>
                              <AvField
                                name="remarks"
                                placeholder={props.t("Enter Remarks")}
                                type="textarea"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </AvForm>
                          </Col>

                          <Col lg={3} className="mb-4">
                            <p class="text-muted mb-2">
                              {props.t("Add Product")}
                            </p>
                            <Select
                              value={selectedProduct && selectedProduct}
                              key={selectedProduct}
                              placeholder={props.t("Select...")}
                              onChange={(e) => {
                                setSelectedProduct(e);
                                loadBatches(e);
                                setSelectedBatch();
                              }}
                              options={allProducts}
                              classNamePrefix="select2-selection"
                              required
                            />
                          </Col>
                          {selectedProduct ? (
                            selectedProduct.available_quantity_for_sale > 0 ? (
                              <>
                                <Col lg={3} className="mb-4">
                                  <p class="text-muted mb-2">
                                    {props.t("Batch")}
                                  </p>
                                  <Select
                                    value={selectedBatch && selectedBatch}
                                    key={selectedBatch}
                                    onChange={(e) => {
                                      setSelectedBatch(e);
                                    }}
                                    options={productBatches && productBatches}
                                    classNamePrefix="select2-selection"
                                    required
                                  />
                                </Col>

                                <Col lg={3} className="mb-4">
                                  <p class="text-muted mb-2">
                                    {props.t("Quantity")}
                                  </p>
                                  <AvForm>
                                    <AvField
                                      name="quantity"
                                      placeholder={props.t("Enter Quantity")}
                                      type="text"
                                      onChange={(e) => {
                                        setQuantity(e.target.value);
                                      }}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Quantity cannot be empty."
                                          ),
                                        },
                                        pattern: {
                                          value: "^\\d+(\\.\\d{1,2})?$",
                                          errorMessage: props.t(
                                            "Only numbers and decimals upto two places are allowed."
                                          ),
                                        },
                                      }}
                                    />
                                  </AvForm>
                                </Col>
                                <Col lg={2} className="mb-4">
                                  {" "}
                                  <Label>&nbsp;</Label>
                                  <br />
                                  <Link
                                    class="btn btn-info waves-effect waves-light btn-sm"
                                    onClick={handleAddProductForSale}
                                  >
                                    <i class="bx bx-add-to-queue"></i> Add
                                  </Link>
                                </Col>
                              </>
                            ) : (
                              <Col lg={6}>
                                <p class="text-muted mb-2">&nbsp;</p>
                                <div
                                  class="alert alert-danger fade show text-center"
                                  role="alert"
                                >
                                  {props.t("This product is out of stock.")}
                                </div>
                              </Col>
                            )
                          ) : null}

                          {productsForSale && productsForSale.length > 0 ? (
                            <>
                              <Col lg={12} md={12}>
                                <br />
                                <p class="text-muted mb-2">
                                  <b>{props.t("Products")} </b>
                                </p>
                              </Col>
                              <Col lg={12}>
                                <BootstrapTable
                                  bootstrap4
                                  keyField="name"
                                  key="name"
                                  data={productsForSale && productsForSale}
                                  columns={columns_products}
                                  cellEdit={cellEditFactory({
                                    mode: "click",
                                    blurToSave: true,
                                    afterSaveCell: (
                                      oldValue,
                                      newValue,
                                      row,
                                      column
                                    ) => {
                                      let total_product_cost = 0;
                                      // productsForSale &&
                                      //   productsForSale.map(
                                      //     (product) => {
                                      //       total_product_cost +=
                                      //         +product.selling_price *
                                      //         +product.quantity;
                                      //     }
                                      //   );
                                      let arr = [...productsForSale];
                                      for (let i = 0; i < arr.length; i++) {
                                        arr[i].total_price =
                                          +arr[i].selling_price *
                                          +arr[i].quantity;
                                        total_product_cost +=
                                          +arr[i].selling_price *
                                          +arr[i].quantity;
                                      }
                                      setProductsForSale(arr);
                                      setTotalProductAmount(total_product_cost);
                                    },
                                  })}
                                  noDataIndication="No data to display."
                                  striped
                                  hover
                                  condensed
                                />
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col lg="10">
                            <h4 className="mt-4 font-weight-semibold">
                              {props.t("Order Successful.")}
                            </h4>

                            <Row className="justify-content-center mt-3 mb-2">
                              <Col sm="12" xs="12">
                                <div>
                                  <img
                                    src={success_image}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="mt-4">
                              {/* button triggers modal */}
                              <Button
                                className="mr-2"
                                type="button"
                                color="warning"
                                onClick={() => {
                                  setSubmitSuccess(false);
                                }}
                              >
                                {props.t("Ok")}
                              </Button>
                              <a
                                className="btn btn-info"
                                href={
                                  submitResponse &&
                                  "/invoice?invoice=" + submitResponse._id
                                }
                                target="_blank"
                              >
                                <i className="bx bx-doc"></i>{" "}
                                {props.t("View Invoice")}
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </AvForm>
                  <Modal
                    size="md"
                    isOpen={newClientModal}
                    toggle={() => setNewClientModal(!newClientModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewClientModal(!newClientModal)}
                    >
                      {props.t("Add New Client")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewClient(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name") + " *"}
                                      placeholder={props.t("Enter Client Name")}
                                      type="text"
                                      errorMessage="Client name cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <AvField
                                      name="mobile"
                                      label={props.t("Mobile") + " *"}
                                      placeholder={props.t(
                                        "Enter Mobile Number"
                                      )}
                                      type="text"
                                      errorMessage="Mobile number cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  {confirmSubmit && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmSubmit(false);
                        submitOrderClicked();
                      }}
                      onCancel={() => {
                        setConfirmSubmit(false);
                      }}
                    >
                      {props.t(
                        "Once you submit this order, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AddOrder))
);

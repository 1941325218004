import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";

const ProductSalesReport = (props) => {
  const { SearchBar } = Search;

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    loadAllCategories();
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadProductSalesReport();
    }
  }, [dateFrom, dateTo]);

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const filterProducts = async () => {
    let arr = productSalesReport.filter((p) =>
      selectedCategory === "All"
        ? true
        : p.product_category === selectedCategory
    );
    setFilteredProductSalesReport(arr);
  };
  const [productSalesReport, setProductSalesReport] = useState([]);
  const [filteredProductSalesReport, setFilteredProductSalesReport] = useState(
    []
  );
  useEffect(() => {
    filterProducts();
  }, [selectedCategory]);

  const loadProductSalesReport = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/sales";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
      type: "product",
    });
    if (response && response.status === 1) {
      setProductSalesReport(response.data);
      setFilteredProductSalesReport(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "name",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return +rowIndex + 1;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "product_category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "sold_product_details.name",
      text: props.t("Product Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "sold_product_details.quantity",
      text: props.t("Quantity"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "sold_product_details.selling_price",
      text: props.t("Selling Price"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "sold_product_details.total_price",
      text: props.t("Total"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "client_details.client_name",
      text: props.t("Client Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Billed At"),
      formatter: (cell, row) => {
        return moment(row.createdAt).format("DD/MM/YYYY hh:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];
  const handleDownload = async () => {
    const fileName = "products_sales_report";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(productSalesReport));
    data.forEach(function (v) {
      v.client_name = v.client_details.client_name;
      v.client_mobile = v.client_details.client_mobile;
      v.product_name = v.sold_product_details.name;
      v.quantity = v.sold_product_details.quantity;
      v.selling_price = v.sold_product_details.selling_price;
      v.total_price = v.sold_product_details.total_price;

      v.billed_at = moment(v.createdAt).format("DD/MM/YYYY hh:mm");

      delete v._id;
      delete v.appointment_id;
      delete v.sold_product_details;
      delete v.service_amount;
      delete v.sold_product_amount;
      delete v.total_amount;
      delete v.paid_amount;
      delete v.discount;
      delete v.final_amount;
      delete v.paid_by_cash;
      delete v.paid_by_card;
      delete v.from_wallet;
      delete v.remarks;
      delete v.invoice_number;
      delete v.client_details;
      delete v.product_details;
      delete v.is_active;
      delete v.createdAt;
      delete v.updatedAt;
      delete v.__v;
      delete v.sales_transactions;
      delete v.use_transactions;
      delete v.status;
      delete v.service_details;
      delete v.serviceandsale_id;
    });
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Product Sales Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Product Sales Report")} </CardTitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    keyField="serial"
                    data={
                      filteredProductSalesReport && filteredProductSalesReport
                    }
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                          <Col lg={4}>
                            <select
                              name="product_categories"
                              className="form-control"
                              onChange={(e, v) => {
                                setSelectedCategory(e.target.value);
                              }}
                            >
                              <option value="All">All Categories</option>
                              {allCategories &&
                                allCategories.map((item) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(ProductSalesReport))
);

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import Moment from "react-moment";

import { withNamespaces } from "react-i18next";

//Import Image
import logo from "../assets/images/logo-light.png";
const ClosurePrint = (props) => {
  function printInvoice() {
    window.print();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <h4 className="float-right font-size-16">
                      {props.t("Closure Report")}
                      <br />
                      <Moment format="DD/MM/YYYY">
                        {props.closure_data &&
                          props.closure_data.closure.date_of_closure}
                      </Moment>
                    </h4>
                    <div className="mb-4">
                      <img src={logo} alt="logo" height="35" />
                    </div>
                  </div>
                  <hr />

                  {props.closure_data && props.closure_data.serviceandsales && (
                    <>
                      <h3>Income Details</h3>
                      <div className="table-responsive">
                        <Table className="table" style={{ fontSize: "17px" }}>
                          <thead>
                            <tr>
                              <th>{props.t("Invoice #")}</th>
                              <th className="text-right">
                                {props.t("Amount")}
                              </th>
                              <th className="text-right">
                                {props.t("Discount")}
                              </th>
                              <th className="text-right">{props.t("Cash")}</th>
                              <th className="text-right">{props.t("Card")}</th>
                              <th className="text-right">
                                {props.t("Wallet")}
                              </th>
                              <th className="text-right">
                                {props.t("Final Amount")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.closure_data &&
                              props.closure_data.serviceandsales.map(
                                (service, key) => (
                                  <tr>
                                    <td>{service.invoice_number}</td>
                                    <td className="text-right">
                                      {service.total_amount}
                                    </td>
                                    <td className="text-right">
                                      {service.discount}
                                    </td>
                                    <td className="text-right">
                                      {service.paid_by_cash}
                                    </td>
                                    <td className="text-right">
                                      {service.paid_by_card}
                                    </td>
                                    <td className="text-right">
                                      {service.from_wallet}
                                    </td>
                                    <td className="text-right">
                                      {service.final_amount}
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="border-0 text-right">
                                <strong></strong>
                              </td>
                              <td className="border-0 text-right">
                                <strong>
                                  {" "}
                                  {props.closure_data &&
                                    props.closure_data.total_amount}
                                </strong>
                              </td>
                              <td className="border-0 text-right">
                                <strong>
                                  {props.closure_data &&
                                    props.closure_data.discount}
                                </strong>
                              </td>
                              <td className="border-0 text-right">
                                <strong>
                                  {props.closure_data &&
                                    props.closure_data.paid_by_cash}
                                </strong>
                              </td>
                              <td className="border-0 text-right">
                                <strong>
                                  {props.closure_data &&
                                    props.closure_data.paid_by_card}
                                </strong>
                              </td>
                              <td className="border-0 text-right">
                                <strong>
                                  {props.closure_data &&
                                    props.closure_data.from_wallet}
                                </strong>
                              </td>
                              <td className="border-0 text-right">
                                <strong>
                                  {props.closure_data &&
                                    props.closure_data.final_amount}
                                </strong>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                      <h4>
                        Total Income:{" "}
                        {props.closure_data &&
                          props.closure_data.closure.total_income}
                      </h4>
                    </>
                  )}

                  <hr />
                  {props.closure_data && props.closure_data.expenses && (
                    <>
                      <h3>Expense Details</h3>
                      <div className="table-responsive">
                        <Table className="table" style={{ fontSize: "17px" }}>
                          <thead>
                            <tr>
                              <th>{props.t("Source Name")}</th>

                              <th>{props.t("Remarks")}</th>
                              <th>{props.t("Description")}</th>

                              <th className="text-right">
                                {props.t("Amount")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.closure_data &&
                              props.closure_data.expenses.map(
                                (expense, key) => (
                                  <tr>
                                    <td>{expense.source.name}</td>
                                    <td>{expense.remarks}</td>
                                    <td>{expense.description}</td>
                                    <td className="text-right">
                                      {expense.amount}
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </div>
                      <h4>
                        Total Expense:{" "}
                        {props.closure_data &&
                          props.closure_data.closure.total_expense}
                      </h4>
                    </>
                  )}
                  <hr />
                  <h3>Summary</h3>
                  <hr />
                  <Table className="table" style={{ fontSize: "17px" }}>
                    <tbody>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Total Income")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.total_income}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("Total Expense")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.total_expense}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("Total Profit")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.total_profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Client Visits")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.customers_visited}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("In Salon Services")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.in_salon_services}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("Home Services")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.home_services}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Completed Appointments")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.completed_appointments}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("Cancelled Appointments")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.cancelled_appointments}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("Wallet Credits")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.wallet_transactions
                              .credit}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Wallet Debits")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.wallet_transactions
                              .debit}
                        </td>

                        <td className="border-0 ">
                          <strong>{props.t("Products Sold")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.products_sold}
                        </td>
                        <td className="border-0 ">
                          <strong>{props.t("Cash in Hand")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.cash_in_hand}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Remarks")}</strong>
                        </td>
                        <td className="border-0 ">
                          {props.closure_data &&
                            props.closure_data.closure.remarks}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  <br />
                  <br />
                  <br />
                  <Table style={{ fontSize: "17px" }}>
                    <tbody>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Madame Lamya Bucheeri")}</strong>
                        </td>

                        <td className="border-0 ">
                          <strong>--------------------------</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-print-none">
                    <div className="float-right">
                      <Link
                        to="#"
                        onClick={() => {
                          printInvoice();
                        }}
                        className="btn btn-success waves-effect waves-light mr-2"
                      >
                        <i className="fa fa-print"></i> {props.t("Print")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(ClosurePrint);

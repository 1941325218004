import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";
import moment from "moment";

const ViewAttendance = (props) => {
  const [reportType, setReportType] = useState("get_by_date");
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [attendanceDate, setAttendanceDate] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    loadEmployees();
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
    setAttendanceDate(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (reportType === "get_by_date") {
      if (attendanceDate) {
        loadAttendanceRecords();
      } else {
        setAttendanceRecords([]);
      }
    } else {
      if (dateFrom && dateTo && selectedEmployee) {
        loadAttendanceRecords();
      } else {
        setAttendanceRecords([]);
      }
    }
  }, [dateFrom, dateTo, attendanceDate, reportType, selectedEmployee]);
  const [employees, setEmployees] = useState();
  const loadEmployees = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setEmployees(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const loadAttendanceRecords = async () => {
    let data_to_send = {};
    if (reportType === "get_by_date") {
      data_to_send.date_of_attendance = attendanceDate;
    } else {
      data_to_send.code = selectedEmployee;
      data_to_send.date_from = dateFrom;
      data_to_send.date_to = dateTo;
    }
    let url = process.env.REACT_APP_BASEURL + "attendances/" + reportType;
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      setAttendanceRecords(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [editModal, setEditModal] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit font-size-15"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal);
            setSelectedRecord(row);
            // setSelectedDesignation(row.designation);
            // setQatarID(row.qatarID);
            // setPassport(row.passport);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Code"),
      dataField: "employee_details.code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "employee_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      text: props.t("Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        return moment(row.date_of_attendance).format("DD-MM-YYYY");
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "in_time",
      text: props.t("In Time"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "out_time",
      text: props.t("Out Time"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "delay_in",
      text: props.t("Delay In"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "early_out",
      text: props.t("Early Out"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  const handleValidUpdate = async (e, v) => {
    v.id = selectedRecord._id;

    if (v.out_time === "") {
      v.out_time = null;
    }
    v.date_of_attendance = formatDate(selectedRecord.date_of_attendance);
    let url = process.env.REACT_APP_BASEURL + "attendances/update";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      showNotification(response.message, "Success");
      loadAttendanceRecords();
      setEditModal(!editModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Appointments")}
            breadcrumbItem={props.t("All Appointments")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12} className="mb-4">
                        <Label>{props.t("Search by")} </Label>
                        <div class="form-control border-0 p-0 h-auto is-untouched is-pristine av-invalid">
                          <div class="form-check form-check-inline">
                            <input
                              onChange={(e, v) => {
                                setReportType("get_by_date");
                              }}
                              type="radio"
                              id="get_by_date"
                              name="search_type"
                              value="get_by_date"
                              checked={
                                reportType && reportType === "get_by_date"
                                  ? true
                                  : false
                              }
                              className="is-untouched is-pristine av-invalid form-check-input"
                            />
                            <label
                              for="get_by_date"
                              className="form-check-label"
                            >
                              {props.t("Date Only")}
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              onChange={(e, v) => {
                                setReportType("get_by_code");
                              }}
                              type="radio"
                              id="get_by_code"
                              name="search_type"
                              value="get_by_code"
                              checked={
                                reportType && reportType === "get_by_code"
                                  ? true
                                  : false
                              }
                              className="is-untouched is-pristine av-invalid form-check-input"
                            />
                            <label
                              for="get_by_code"
                              className="form-check-label"
                            >
                              {props.t("Date & Employee")}
                            </label>
                          </div>
                        </div>
                      </Col>
                      {reportType === "get_by_date" ? (
                        <>
                          <Col lg={12}>
                            <AvField
                              name="dateFrom"
                              label={props.t("Attendance Date")}
                              placeholder={props.t("Select")}
                              type="date"
                              value={attendanceDate}
                              onChange={(e, v) => {
                                setAttendanceDate(formatDate(e.target.value));
                              }}
                              errorMessage={props.t("Date cannot be empty.")}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                        </>
                      ) : null}
                      {reportType === "get_by_code" ? (
                        <>
                          <Col lg={12}>
                            <AvField
                              name="employee_id"
                              label={props.t("Employee") + " *"}
                              placeholder={props.t("Select Employee")}
                              type="select"
                              onChange={(e, v) => {
                                setSelectedEmployee(e.target.value);
                              }}
                            >
                              <option value="">
                                -- {props.t("Select Employee")} --
                              </option>
                              {employees &&
                                employees.map((r) => (
                                  <option key={r._id} value={r.code}>
                                    {r.name}
                                  </option>
                                ))}
                            </AvField>
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="dateFrom"
                              label={props.t("From Date")}
                              placeholder={props.t("Select")}
                              type="date"
                              value={dateFrom}
                              onChange={(e, v) => {
                                setDateFrom(formatDate(e.target.value));
                              }}
                              errorMessage={props.t("Date cannot be empty.")}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="dateTo"
                              label={props.t("To Date")}
                              placeholder={props.t("Select")}
                              type="date"
                              value={dateTo}
                              onChange={(e, v) => {
                                setDateTo(formatDate(e.target.value));
                              }}
                              errorMessage={props.t("Date cannot be empty.")}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Attendace Records")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View attendance records here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={attendanceRecords && attendanceRecords}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    striped
                    hover
                    condensed
                  />
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Employee Name")}:{" "}
                      {selectedRecord && selectedRecord.employee_details.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedRecord && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={6}>
                                        {" "}
                                        <AvField
                                          name="in_time"
                                          label={props.t("In Time") + " *"}
                                          placeholder={props.t("Enter In Time")}
                                          type="time"
                                          errorMessage={props.t(
                                            "In time cannot be empty"
                                          )}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={selectedRecord.in_time}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        {" "}
                                        <AvField
                                          name="out_time"
                                          label={props.t("Out Time")}
                                          placeholder={props.t(
                                            "Enter Out Time"
                                          )}
                                          type="time"
                                          value={selectedRecord.out_time}
                                          // errorMessage={props.t("Out time cannot be empty")}
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};

export default withRouter(connect(null, {})(withNamespaces()(ViewAttendance)));

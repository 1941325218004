import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Invoice from "../components/invoice";

import { postSubmitForm } from "../helpers/forms_helper";

const SaleInvoice = (props) => {
  const [invoiceData, setInvoiceData] = useState();
  const loadInvoiceData = async (data) => {
    let url = process.env.REACT_APP_BASEURL + "serviceandsales/get";
    const response = await postSubmitForm(url, data);
    if (response && response.status === 1) {
      setInvoiceData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("invoice")) {
      console.log(urlParams.get("invoice"));
      loadInvoiceData({ serviceandsale_id: urlParams.get("invoice") });
    }
  }, []);

  return (
    <React.Fragment>
      <Invoice order={invoiceData && invoiceData}></Invoice>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(SaleInvoice))
);

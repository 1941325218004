import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

class LineBar extends Component {
  getOption = () => {
    let data = this.props.line_data;

    let dates = [];
    let counts = [];
    let values = [];

    //console.log (data);
    if (data) {
      if (this.props.type === "income_trend") {
        data.forEach((element) => {
          dates.push(element.date_of_closure);
          counts.push(element.total_income);
          values.push(element.total_income);
        });
      } else if (this.props.type === "expense_trend") {
        data.forEach((element) => {
          dates.push(element.date_of_closure);
          counts.push(element.total_expense);
          values.push(element.total_expense);
        });
      } else if (this.props.type === "profit_trend") {
        data.forEach((element) => {
          dates.push(element.date_of_closure);
          counts.push(element.total_profit);
          values.push(element.total_profit);
        });
      } else if (this.props.type === "monthly_income_trend") {
        data.forEach((element) => {
          dates.push(element.month_name_year);
          counts.push(element.total);
          values.push(element.total);
        });
      } else {
        data.forEach((element) => {
          dates.push(element.status_date);
          counts.push(element.count);
          values.push(element.value);
        });
      }
    }
    return {
      grid: {
        zlevel: 0,
        x: 80,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          magicType: {
            show: true,
            type: ["line", "bar"],
            title: { line: "Switch to Line Chart", bar: "Switch to Bar Chart" },
          },

          saveAsImage: { show: true, title: "Save as Image" },
        },
      },
      color: ["#3c4ccf", "#02a499", "#38a4f8"],

      xAxis: [
        {
          type: "category",
          data: dates,
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: this.props.type ? this.props.yAxisLabel : "Total Sale",
          min: 0,
          //max: 500000,
          minInterval: this.props.type === "monthly_income_trend" ? 450000 : 1,
          // maxInterval: 50000,
          //interval: 450000,
          axisLabel: {
            formatter: "QR {value}",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
      ],
      series: [
        {
          name: this.props.type ? this.props.yAxisLabel : "Sale",
          type: "bar",
          data: values,
        },
      ],
      textStyle: {
        color: ["#74788d"],
      },
    };
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
      </React.Fragment>
    );
  }
}
export default LineBar;

import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const animatedComponents = makeAnimated();

const AddProduct = (props) => {
  useEffect(() => {
    loadProducts();
    loadAllProductCategories();
  }, []);
  const [allProducts, setAllProducts] = useState([]);
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const refContainer = useRef(null);
  const handleValidSubmit = async (e, v) => {
    try {
      let data_to_send = {};
      data_to_send.product_category_details = {
        product_category_id: selectedCategoryID,
        name: selectedCategoryName,
      };
      data_to_send.name = v.name;
      data_to_send.is_for_sale = forSale;
      data_to_send.threshold_quantity_for_sale = forSale
        ? v.threshold_quantity_for_sale
        : 0;
      data_to_send.is_for_use = forUse;
      data_to_send.threshold_quantity_for_use = forUse
        ? v.threshold_quantity_for_use
        : 0;

      data_to_send.remarks = v.remarks;

      let url = process.env.REACT_APP_BASEURL + "products/insert";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadProducts();
        setForUse(false);
        setForSale(false);
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
      refContainer.current.reset();
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [selectedCategoryID, setSelectedCategoryID] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [allProductCategories, setAllProductCategories] = useState();
  const loadAllProductCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllProductCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [isNewCategoryModal, setNewCategoryModal] = useState();
  const handleAddNewCategory = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "productcategories/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllProductCategories();
      setSelectedCategoryID(response.data._id);
      setSelectedCategoryName(response.data.name);
      setNewCategoryModal(!isNewCategoryModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [forUse, setForUse] = useState(false);
  const [forSale, setForSale] = useState(false);
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      // dataField: "threshold_quantity_for_sale",
      text: props.t("Threshold (for sale)"),
      formatter: (cell, row) => {
        return row.is_for_sale
          ? row.threshold_quantity_for_sale
          : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      //dataField: "threshold_quantity_for_use",
      text: props.t("Threshold (for use)"),
      formatter: (cell, row) => {
        return row.is_for_use ? row.threshold_quantity_for_use : props.t("NA");
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "product_category_details.name",
      text: props.t("Product Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Products")}
            breadcrumbItem={props.t("Add Product")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Add Products")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new product"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={9}>
                            <AvField
                              name="product_categories"
                              label={props.t("Category") + " *"}
                              type="select"
                              value={selectedCategoryID}
                              onChange={(e, v) => {
                                const selectedIndex =
                                  e.target.options.selectedIndex;
                                setSelectedCategoryID(e.target.value);
                                setSelectedCategoryName(
                                  e.target.options[selectedIndex].getAttribute(
                                    "name"
                                  )
                                );
                              }}
                              required
                              errorMessage={props.t("Select Category")}
                            >
                              <option value="" name="">
                                -- {props.t("Select Category")} --
                              </option>
                              {allProductCategories &&
                                allProductCategories.map((item) => {
                                  return (
                                    <option value={item._id} name={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </AvField>
                          </Col>
                          <Col lg={3}>
                            <Label>&nbsp;</Label>
                            <br />
                            <Link
                              class="btn btn-success waves-effect waves-light btn-sm"
                              onClick={() => {
                                setNewCategoryModal(!isNewCategoryModal);
                              }}
                            >
                              <i class="mdi mdi-file-plus"></i> Add New
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name") + " *"}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          errorMessage="Name cannot be empty."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={3}>
                            <Label>{props.t("Purpose")}</Label>
                            <AvField
                              type="checkbox"
                              name="for_sale"
                              label="For Sale"
                              onChange={(e, v) => {
                                setForSale(v);
                              }}
                              required={!forUse}
                              errorMessage={props.t(
                                "Select either 'For Sale' or 'For Use.' or both."
                              )}
                            />
                          </Col>
                          <Col lg={9}>
                            {forSale ? (
                              <AvField
                                name="threshold_quantity_for_sale"
                                placeholder={props.t(
                                  "Enter Threshold Quantity For Sale"
                                )}
                                errorMessage="Threshold quantity cannot be empty."
                                type="text"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <AvField
                              type="checkbox"
                              name="for_use"
                              label="For Use"
                              onChange={(e, v) => {
                                setForUse(v);
                              }}
                              required={!forSale}
                              errorMessage={props.t(
                                "Select either 'For Sale' or 'For Use.' or both."
                              )}
                            />
                          </Col>
                          <Col lg={9}>
                            {forUse ? (
                              <AvField
                                name="threshold_quantity_for_use"
                                placeholder={props.t(
                                  "Enter Threshold Quantity For Use"
                                )}
                                errorMessage="Threshold quantity cannot be empty."
                                type="text"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={6}>
                        <br />
                        <AvField
                          name="remarks"
                          label={props.t("Remarks")}
                          placeholder={props.t("Enter Remarks")}
                          type="textarea"
                        />
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                  <Modal
                    size="md"
                    isOpen={isNewCategoryModal}
                    toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!isNewCategoryModal)}
                    >
                      Add New Product Category
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category Name") + " *"}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      errorMessage="Category name cannot be empty."
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <AvField
                                      name="remarks"
                                      label={props.t("Remarks")}
                                      placeholder={props.t("Enter Remarks")}
                                      type="textarea"
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Products")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing products here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allProducts && allProducts}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AddProduct))
);

import React, { useEffect, useRef, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postSubmitForm } from "../../helpers/forms_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const AddShifts = (props) => {
  useEffect(() => {
    loadShifts();
  }, []);
  const refContainer = useRef(null);
  const [isBreak, setIsBreak] = useState(false);
  const [allShifts, setAllShifts] = useState([]);
  const loadShifts = async () => {
    let url = process.env.REACT_APP_BASEURL + "shifts/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllShifts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleValidSubmit = async (e, v) => {
    v.is_break = isBreak;
    // if (!isBreak) {
    //   v.break_start_time = "";
    //   v.break_end_time = "";
    // }
    let url = process.env.REACT_APP_BASEURL + "shifts/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadShifts();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
    refContainer.current.reset();
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "in_time",
      text: props.t("In Time"),
      sort: true,
    },
    {
      dataField: "out_time",
      text: props.t("Out Time"),
      sort: true,
    },
    {
      dataField: "break_start_time",
      text: props.t("Break Start Time"),
      sort: true,
    },
    {
      dataField: "break_end_time",
      text: props.t("Break End Time"),
      sort: true,
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Shifts")}
            breadcrumbItem={props.t("Add Shift")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <CardTitle>{props.t("Add Shift")}</CardTitle>
                      <CardSubtitle className="mb-3">
                        {props.t(
                          "Enter the following details to add a new shift"
                        )}
                      </CardSubtitle>

                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                        ref={refContainer}
                      >
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <AvField
                              name="in_time"
                              label={props.t("In Time") + " *"}
                              placeholder={props.t("Enter In Time")}
                              type="time"
                              errorMessage={props.t("In time cannot be empty")}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <AvField
                              name="out_time"
                              label={props.t("Out Time") + " *"}
                              placeholder={props.t("Enter Out Time")}
                              type="time"
                              errorMessage={props.t("Out time cannot be empty")}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="name"
                              label={props.t("Shift Name") + " *"}
                              placeholder={props.t("Enter Name")}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Name cannot be empty",
                                },
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <Label>Is there a break in between? </Label>
                            <AvRadioGroup inline name="item_condition">
                              <AvRadio
                                onChange={(e, v) => {
                                  setIsBreak(true);
                                }}
                                label="Yes"
                                value={true}
                              />
                              <AvRadio
                                onChange={(e, v) => {
                                  setIsBreak(false);
                                }}
                                label="No"
                                value={false}
                              />
                            </AvRadioGroup>
                          </Col>
                          {isBreak ? (
                            <>
                              <Col lg={6}>
                                {" "}
                                <AvField
                                  name="break_start_time"
                                  label={props.t("Break Start Time") + " *"}
                                  placeholder={props.t("Enter Start Time")}
                                  type="time"
                                  errorMessage={props.t(
                                    "Start time cannot be empty"
                                  )}
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </Col>
                              <Col lg={6}>
                                {" "}
                                <AvField
                                  name="break_end_time"
                                  label={props.t("Break End Time") + " *"}
                                  placeholder={props.t("Enter End Time")}
                                  type="time"
                                  errorMessage={props.t(
                                    "End time cannot be empty"
                                  )}
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </Col>
                            </>
                          ) : null}
                          <Col lg={6}>
                            <AvField
                              name="remarks"
                              label={props.t("Remarks")}
                              placeholder={props.t("Enter Remarks")}
                              type="textarea"
                            />
                          </Col>

                          <Col lg={12}>
                            <hr />
                            <FormGroup className="mb-0 text-center">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="mr-1"
                                >
                                  {props.t("Submit")}
                                </Button>{" "}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Shifts")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing shifts here")}
                  </CardSubtitle>

                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allShifts && allShifts}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddShifts)));

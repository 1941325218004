import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import swal from "sweetalert2";

// Login Redux States
import { CHANGE_PASSWORD, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, apiError, changePasswordSuccess } from "./actions"; //logoutUserSuccess

//Include Helper Files with needed methods
import {
  postSubmitFormNoAuth,
  postSubmitForm,
} from "../../../helpers/forms_helper";

import React from "react";
import { Redirect } from "react-router-dom";

// Employees
import AddEmployee from "../../../pages/Employees/AddEmployee";
import AllEmployees from "../../../pages/Employees/AllEmployees";
import GenerateSalary from "../../../pages/Employees/GenerateSalary";
import ArrearDeduction from "../../../pages/Employees/ArrearDeduction";
import ViewSalary from "../../../pages/Employees/ViewSalary";
import Incentives from "../../../pages/Employees/Incentives";

// Sources
import AddSource from "../../../pages/Sources/AddSource";
import AllSources from "../../../pages/Sources/AllSources";

// Expenses
import AddExpense from "../../../pages/Expenses/AddExpense";
import AllExpenses from "../../../pages/Expenses/AllExpenses";

// Designations
import AddDesignation from "../../../pages/Designations/AddDesignation";
import AllDesignations from "../../../pages/Designations/AllDesignations";

// Shifts
import AddShift from "../../../pages/Shifts/AddShift";
import AllShifts from "../../../pages/Shifts/AllShifts";

// Clients
import AddClient from "../../../pages/Clients/AddClient";
import AllClients from "../../../pages/Clients/AllClients";

// Service Categories
import AddServiceCategory from "../../../pages/ServiceCategories/AddServiceCategory";
import AllServiceCategories from "../../../pages/ServiceCategories/AllServiceCategories";
import WalletTransaction from "../../../pages/Clients/WalletTransaction";
import WalletHistory from "../../../pages/Clients/WalletHistory";

// Services
import AddService from "../../../pages/Services/AddService";
import AllServices from "../../../pages/Services/AllServices";

// Product Categories
import AddProductCategory from "../../../pages/ProductCategories/AddProductCategory";
import AllProductCategories from "../../../pages/ProductCategories/AllProductCategories";

// Products
import AddProduct from "../../../pages/Products/AddProduct";
import AllProducts from "../../../pages/Products/AllProducts";

// Appointments
import AddAppointment from "../../../pages/Appointments/AddAppointment";
import OngoingAppointments from "../../../pages/Appointments/OngoingAppointments";
import AllAppointments from "../../../pages/Appointments/AllAppointments";

//Inventory
import UpdateInventory from "../../../pages/Inventory/UpdateInventory";
import ViewInventory from "../../../pages/Inventory/ViewInventory";
import UsedInventory from "../../../pages/Inventory/UsedInventory";

// Invoice
import SaleInvoice from "../../../pages/SaleInvoice";

// Product Sales
import AddOrder from "../../../pages/ProductSales/AddOrder";
import AllOrders from "../../../pages/ProductSales/AllOrders";

// Users
import AddUser from "../../../pages/Users/AddUser";
import AllUsers from "../../../pages/Users/AllUsers";
import ChangePassword from "../../../pages/ChangePassword";

// Attendance
import AddAttendance from "../../../pages/Attendance/AddAttendance";
import ViewAttendance from "../../../pages/Attendance/ViewAttendance";

// Report
import AppointmentsReport from "../../../pages/Reports/AppointmentsReport";
import ServiceSalesChart from "../../../pages/Reports/ServiceSalesChart";
import CharityReport from "../../../pages/Reports/CharityReport";
import ExpiredProductsReport from "../../../pages/Reports/ExpiredProductsReport";
import ExpiringProductsReport from "../../../pages/Reports/ExpiringProductsReport";
import WastedProductsReport from "../../../pages/Reports/WastedProductsReport";
import AccountsReport from "../../../pages/Reports/AccountsReport";
import LoyaltyReport from "../../../pages/Reports/LoyaltyReport";
import SubmitClosure from "../../../pages/Reports/Closure";
import ClosureReport from "../../../pages/Reports/ClosureReport";
import EmployeeReport from "../../../pages/Reports/EmployeeReport";
import InventoryTransfer from "../../../pages/Reports/InventoryTransfer";
import ServiceSalesReport from "../../../pages/Reports/ServiceSalesReport";
import ProductSalesReport from "../../../pages/Reports/ProductSalesReport";
import InventoryReport from "../../../pages/Reports/InventoryReport";

import AppSettings from "../../../pages/Settings/AppSettings";

// Dashboard
import Dashboard from "../../../pages/Dashboard/index";

function showNotification(message, type) {
  if (type === "Success") swal.fire(type, message, "success");
  else swal.fire(type, message, "error");
}
function* loginUser({ payload: { user, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/login";

    const response = yield call(postSubmitFormNoAuth, url, user);

    if (response.status === 1) {
      let routes = [];
      const role = response.data.role;
      if (role === "admin") {
        routes = [
          { path: "/dashboard", component: Dashboard },

          { path: "/add_employee", component: AddEmployee },
          { path: "/all_employees", component: AllEmployees },
          { path: "/generate_salary", component: GenerateSalary },
          { path: "/arrear_deduction", component: ArrearDeduction },
          { path: "/view_salary", component: ViewSalary },
          { path: "/incentives", component: Incentives },

          { path: "/add_source", component: AddSource },
          { path: "/all_sources", component: AllSources },

          { path: "/add_expense", component: AddExpense },
          { path: "/all_expenses", component: AllExpenses },

          { path: "/add_designation", component: AddDesignation },
          { path: "/all_designations", component: AllDesignations },

          { path: "/add_shift", component: AddShift },
          { path: "/all_shifts", component: AllShifts },

          { path: "/add_client", component: AddClient },
          { path: "/all_clients", component: AllClients },
          { path: "/wallet_transaction", component: WalletTransaction },
          { path: "/wallet_history", component: WalletHistory },

          { path: "/add_service_category", component: AddServiceCategory },
          { path: "/all_service_categories", component: AllServiceCategories },

          { path: "/add_service", component: AddService },
          { path: "/all_services", component: AllServices },

          { path: "/add_product_category", component: AddProductCategory },
          { path: "/all_product_categories", component: AllProductCategories },

          { path: "/add_product", component: AddProduct },
          { path: "/all_products", component: AllProducts },

          { path: "/appointments", component: AddAppointment },
          { path: "/ongoing_appointments", component: OngoingAppointments },
          { path: "/all_appointments", component: AllAppointments },

          { path: "/update_inventory", component: UpdateInventory },
          { path: "/view_inventory", component: ViewInventory },
          { path: "/used_inventory", component: UsedInventory },

          { path: "/invoice", component: SaleInvoice },

          { path: "/add_new_order", component: AddOrder },
          { path: "/all_orders", component: AllOrders },

          { path: "/add_attendance", component: AddAttendance },
          { path: "/view_attendance", component: ViewAttendance },

          { path: "/add_user", component: AddUser },
          { path: "/all_users", component: AllUsers },
          { path: "/user-changepassword", component: ChangePassword },

          { path: "/appointments_report", component: AppointmentsReport },
          { path: "/service_sales_chart", component: ServiceSalesChart },
          { path: "/charity_report", component: CharityReport },
          {
            path: "/expired_products_report",
            component: ExpiredProductsReport,
          },
          {
            path: "/expiring_products_report",
            component: ExpiringProductsReport,
          },
          { path: "/wasted_products_report", component: WastedProductsReport },
          { path: "/accounts_report", component: AccountsReport },
          { path: "/loyalty_report", component: LoyaltyReport },
          { path: "/submit_closure", component: SubmitClosure },
          { path: "/closure_report", component: ClosureReport },
          { path: "/employee_report", component: EmployeeReport },
          { path: "/inventory_transfer_report", component: InventoryTransfer },
          { path: "/service_sales_report", component: ServiceSalesReport },
          { path: "/product_sales_report", component: ProductSalesReport },
          { path: "/inventory_report", component: InventoryReport },

          { path: "/app_settings", component: AppSettings },

          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "manager") {
        routes = [
          { path: "/dashboard", component: Dashboard },

          { path: "/add_employee", component: AddEmployee },
          { path: "/all_employees", component: AllEmployees },
          { path: "/generate_salary", component: GenerateSalary },
          { path: "/arrear_deduction", component: ArrearDeduction },
          { path: "/view_salary", component: ViewSalary },
          { path: "/incentives", component: Incentives },

          { path: "/add_source", component: AddSource },
          { path: "/all_sources", component: AllSources },

          { path: "/add_expense", component: AddExpense },
          { path: "/all_expenses", component: AllExpenses },

          { path: "/add_designation", component: AddDesignation },
          { path: "/all_designations", component: AllDesignations },

          { path: "/add_shift", component: AddShift },
          { path: "/all_shifts", component: AllShifts },

          { path: "/add_client", component: AddClient },
          { path: "/all_clients", component: AllClients },
          { path: "/wallet_transaction", component: WalletTransaction },
          { path: "/wallet_history", component: WalletHistory },

          { path: "/add_service_category", component: AddServiceCategory },
          { path: "/all_service_categories", component: AllServiceCategories },

          { path: "/add_service", component: AddService },
          { path: "/all_services", component: AllServices },

          { path: "/add_product_category", component: AddProductCategory },
          { path: "/all_product_categories", component: AllProductCategories },

          { path: "/add_product", component: AddProduct },
          { path: "/all_products", component: AllProducts },

          { path: "/appointments", component: AddAppointment },
          { path: "/ongoing_appointments", component: OngoingAppointments },
          { path: "/all_appointments", component: AllAppointments },

          { path: "/update_inventory", component: UpdateInventory },
          { path: "/view_inventory", component: ViewInventory },
          { path: "/used_inventory", component: UsedInventory },

          { path: "/invoice", component: SaleInvoice },

          { path: "/add_new_order", component: AddOrder },
          { path: "/all_orders", component: AllOrders },

          { path: "/add_attendance", component: AddAttendance },
          { path: "/view_attendance", component: ViewAttendance },

          { path: "/add_user", component: AddUser },
          { path: "/all_users", component: AllUsers },
          { path: "/user-changepassword", component: ChangePassword },

          { path: "/appointments_report", component: AppointmentsReport },
          { path: "/service_sales_chart", component: ServiceSalesChart },
          { path: "/charity_report", component: CharityReport },
          {
            path: "/expired_products_report",
            component: ExpiredProductsReport,
          },
          {
            path: "/expiring_products_report",
            component: ExpiringProductsReport,
          },
          { path: "/wasted_products_report", component: WastedProductsReport },
          { path: "/accounts_report", component: AccountsReport },
          { path: "/loyalty_report", component: LoyaltyReport },
          { path: "/submit_closure", component: SubmitClosure },
          { path: "/closure_report", component: ClosureReport },
          { path: "/employee_report", component: EmployeeReport },
          { path: "/inventory_transfer_report", component: InventoryTransfer },
          { path: "/service_sales_report", component: ServiceSalesReport },
          { path: "/product_sales_report", component: ProductSalesReport },
          { path: "/inventory_report", component: InventoryReport },

          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "beautician") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else {
        routes = [
          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      }
      response.routes = routes;

      localStorage.setItem("username", response.data.username);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("mobile", response.data.mobile);
      localStorage.setItem("employee_id", response.data.employee_id);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("userToken", response.data.token);
      yield put(loginSuccess(response));
      history.push("/dashboard");
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/logout";
    const token = localStorage.getItem("userToken");
    if (token) {
      const response = yield call(postSubmitForm, url, {});
      //console.log(response);
      if (response.status === 1) {
        localStorage.clear();
        localStorage.removeItem("username");
        localStorage.removeItem("name");
        localStorage.removeItem("mobile");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        localStorage.removeItem("userToken");
        history.push("/login");
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* changePassword({ payload: { password_details, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/changepassword";

    const response = yield call(postSubmitForm, url, password_details);

    if (response.status === 1) {
      showNotification("Password changed successfully.", "Success");
      yield put(changePasswordSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchChangePassword),
  ]);
}

export default authSaga;

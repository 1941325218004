import React, { useEffect } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

const SidebarContent = (props) => {
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;
    console.log(pathName);
    const initMenu = () => {
      new MetisMenu("#side-menu");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-active"); // li
              parent5.childNodes[0].classList.add("mm-active"); //a
            }
          }
        }
      }
      return false;
    }
    return false;
  }
  const role = localStorage.getItem("role");

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("Menu")} </li>
          <li>
            <Link to="dashboard" className=" waves-effect">
              <i className="bx bx-home-circle"></i>
              <span>{props.t("Dashboard")}</span>
            </Link>
          </li>
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-car-arrow-left"></i>
                <span>{props.t("Register Car")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_car_for_sale">{props.t("Add Car")} </Link>
                </li>
                <li>
                  <Link to="update_car_for_sale">{props.t("Update Car")} </Link>
                </li>
                <li>
                  <Link to="all_cars_for_sale">
                    {props.t("View All Cars")}{" "}
                  </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role === "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-car-arrow-left"></i>
                <span>{props.t("Register Car")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_car_for_sale">{props.t("Add Car")} </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cart-plus"></i>
                <span>{props.t("Orders")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_order">{props.t("Add New Order")} </Link>
                </li>
                <li>
                  <Link to="update_order">{props.t("Update Order")} </Link>
                </li>
                <li>
                  <Link to="all_orders">{props.t("View All Orders")} </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role === "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cart-plus"></i>
                <span>{props.t("Orders")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_order">{props.t("Add New Order")} </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="reports-sales">{props.t("Sales Report")} </Link>
                </li>
                <li>
                  <Link to="inventory">{props.t("Inventory")} </Link>
                </li>
                <li>
                  <Link to="accounts">{props.t("Accounts")} </Link>
                </li>
                <li>
                  <Link to="closure">{props.t("Closure")} </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cash-plus"></i>
                <span>{props.t("Extra Income")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_income">{props.t("Add Income")} </Link>
                </li>
                <li>
                  <Link to="all_incomes">{props.t("View Incomes")} </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cash-minus"></i>
                <span>{props.t("Extra Expenses")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_expense">{props.t("Add Expense")} </Link>
                </li>
                <li>
                  <Link to="generate_salary">
                    {props.t("Generate Salary")}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="all_expenses">{props.t("View Expenses")} </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cash-usd-outline"></i>
                <span>{props.t("Investments")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="add_investment">{props.t("Add Investment")} </Link>
                </li>
                <li>
                  <Link to="all_investments">
                    {props.t("View Investments")}{" "}
                  </Link>
                </li>
                {/* <li>
                  <Link to="update_investment">
                    {props.t("Update Investment")}{" "}
                  </Link>
                </li> */}
              </ul>
            </li>
          ) : null}
          {role !== "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-book"></i>
                <span>{props.t("Master Data")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Car Models")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="add_car">{props.t("Add Car Model")} </Link>
                    </li>
                    <li>
                      <Link to="update_car">
                        {props.t("Update Car Model")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="all_cars">{props.t("View All Models")} </Link>
                    </li>
                    <li>
                      <Link to="make_model_variants">
                        {props.t("Make-Class-Models")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Employees")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="add_employee">{props.t("Add Employee")} </Link>
                    </li>
                    <li>
                      <Link to="update_employee">
                        {props.t("Update Employee")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="all_employees">
                        {props.t("View Employees")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Users")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="users-adduser">{props.t("Add User")} </Link>
                    </li>
                    <li>
                      <Link to="users-updateuser">
                        {props.t("Update User")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="users-allusers">{props.t("View Users")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Sources")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="add_source">{props.t("Add Source")} </Link>
                    </li>
                    <li>
                      <Link to="all_sources">{props.t("View Sources")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Sellers")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="sellers-addseller">
                        {props.t("Add Seller")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="sellers-updateseller">
                        {props.t("Update Seller")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="sellers-allsellers">
                        {props.t("View Sellers")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Buyers")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="buyers-addbuyer">{props.t("Add Buyer")} </Link>
                    </li>
                    <li>
                      <Link to="buyers-updatebuyer">
                        {props.t("Update Buyer")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="buyers-allbuyers">
                        {props.t("View Buyers")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          ) : null}
          {role === "salesexecutive" ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-book"></i>
                <span>{props.t("Master Data")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Car Models")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="add_car">{props.t("Add Car Model")} </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          ) : null}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(SidebarContent));

import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
} from "reactstrap";
import swal from "sweetalert2";
import Select from "react-select";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const WalletTransaction = (props) => {
  useEffect(() => {
    loadClients();
  }, []);
  const [transactionType, setTransactionType] = useState();
  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const loadClients = async () => {
    let url = process.env.REACT_APP_BASEURL + "clients/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllClients(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const refContainer = useRef(null);
  const handleValidSubmit = async (e, v) => {
    if (selectedClient && selectedClient !== "") {
      v.transaction_type = transactionType;
      v.transaction_remarks = v.remarks;
      v.client_id = selectedClient._id;
      let url = process.env.REACT_APP_BASEURL + "clients/wallet_transaction";
      const response = await postSubmitForm(url, v);
      if (response && response.status === 1) {
        showNotification(response.message, props.t("Success"));
        setSelectedClient("");
        refContainer.current.reset();
      } else {
        showNotification(response.message, props.t("Error"));
      }
    } else {
      showNotification(props.t("Please select client."), props.t("Error"));
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Clients")}
            breadcrumbItem={props.t("Wallet Transaction")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={refContainer}
                  >
                    <CardTitle>{props.t("Wallet Transaction")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a wallet transaction"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <Label>{props.t("Select Client") + " *"}</Label>

                            <Select
                              value={selectedClient}
                              onChange={(e) => {
                                setSelectedClient(e);
                              }}
                              placeholder={props.t("Select...")}
                              options={allClients}
                              classNamePrefix="select2-selection"
                              required
                            />
                          </Col>
                          {selectedClient && (
                            <Col lg={4}>
                              <Label>{props.t("Available Balance")}</Label>
                              <br />
                              <Label>
                                QR {selectedClient && selectedClient.wallet}
                              </Label>
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="transaction_amount"
                          label={props.t("Amount")}
                          placeholder={props.t("Enter Amount")}
                          errorMessage="Amount cannot be empty."
                          required
                          validate={{
                            pattern: {
                              value: "^\\d+(\\.\\d{1,2})?$",
                              errorMessage: props.t(
                                "Only numbers and decimals upto two places are allowed."
                              ),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <Label>{props.t("Transaction Type") + " *"}</Label>

                        <AvRadioGroup
                          inline
                          name="transaction_type"
                          validate={{
                            required: { value: true },
                          }}
                          errorMessage={props.t("Type cannot be empty")}
                        >
                          <AvRadio
                            label={props.t("Credit")}
                            value="Credit"
                            onChange={(e) => {
                              setTransactionType(e.target.value);
                            }}
                          />
                          <AvRadio
                            label={props.t("Debit")}
                            value="Debit"
                            onChange={(e) => {
                              setTransactionType(e.target.value);
                            }}
                          />
                        </AvRadioGroup>
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="remarks"
                          label={props.t("Remarks")}
                          placeholder={props.t("Enter Remarks")}
                          type="textarea"
                        />
                      </Col>

                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(WalletTransaction))
);

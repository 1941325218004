import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import swal from "sweetalert2";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postSubmitForm } from "../../helpers/forms_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import moment from "moment";

const GenerateSalary = (props) => {
  const [currentMonth, setCurrentMonth] = useState();
  const [currentDate, setCurrentDate] = useState(() => {
    var date = new Date();
    var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return moment(d).format("YYYY-MM-DD");
  });
  useEffect(() => {
    loadAllEmployeesForSalary();
    setCurrentMonth(currentDate.substring(0, 7));
  }, [currentDate]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [totalSalary, setTotalSalary] = useState(0);
  const [totalIncentive, setTotalIncentive] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const loadAllEmployeesForSalary = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/get_for_salary";
    const response = await postSubmitForm(url, { salary_date: currentDate });

    if (response && response.status === 1) {
      setAllEmployees(response.data.employee_details);
      setTotalSalary(response.data.total_salary);
      setTotalDeduction(response.data.total_deduction);
      setTotalIncentive(response.data.total_incentive);
      setGrandTotal(response.data.grand_total);
    } else {
      showNotification(props.t(response.message), props.t("Error"));
    }
  };
  const handleGenerateSalary = async (e, v) => {
    let data_to_send = {};
    data_to_send.salary_date = currentDate;
    data_to_send.total_salary = totalSalary;
    data_to_send.total_incentive = totalIncentive;
    data_to_send.total_deduction = totalDeduction;
    data_to_send.grand_total = grandTotal;
    data_to_send.employee_details = allEmployees;

    try {
      let url = process.env.REACT_APP_BASEURL + "employees/generate_salary";
      const response = await postSubmitForm(url, data_to_send);

      if (response && response.status === 1) {
        showNotification(props.t(response.message), props.t("Success"));
        //loadAllEmployeesForSalary();
      } else {
        showNotification(props.t(response.message), props.t("Error"));
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };

  const columns = [
    {
      dataField: "employee_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "designation",
      text: props.t("Designation"),
      sort: true,
    },
    {
      dataField: "target",
      text: props.t("Target"),
      sort: true,
    },
    {
      dataField: "achieved_target",
      text: props.t("Achieved Target"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "salary",
      text: props.t("Salary"),
      sort: true,
    },
    {
      dataField: "incentive",
      text: props.t("Incentive"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "deduction",
      text: props.t("Deduction"),
      sort: true,
    },
    {
      dataField: "total",
      text: props.t("Total"),
      sort: true,
    },
  ];

  function showNotification(message, type) {
    var title = type;
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem={props.t("Generate Salary")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Generate Salary")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all employees and generate salary here")}
                  </CardSubtitle>
                  <AvForm onValidSubmit={handleGenerateSalary}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col lg={2} sm={6}>
                                <div className="d-flex align-items-center mb-3">
                                  <h4 className="font-size-14 mb-0">
                                    {props.t("Salary")}
                                  </h4>
                                </div>
                                <div className="text-muted mt-4">
                                  <h5>QR {totalSalary && totalSalary}</h5>
                                </div>
                              </Col>
                              <Col lg={2} sm={6}>
                                <div className="d-flex align-items-center mb-3">
                                  <h4 className="font-size-14 mb-0">
                                    {props.t("Incentive")}
                                  </h4>
                                </div>
                                <div className="text-muted mt-4">
                                  <h5>QR {totalIncentive && totalIncentive}</h5>
                                </div>
                              </Col>
                              <Col lg={2} sm={6}>
                                <div className="d-flex align-items-center mb-3">
                                  <h4 className="font-size-14 mb-0">
                                    {props.t("Deduction")}
                                  </h4>
                                </div>
                                <div className="text-muted mt-4">
                                  <h5>QR {totalDeduction && totalDeduction}</h5>
                                </div>
                              </Col>
                              <Col lg={2} sm={6}>
                                <div className="d-flex align-items-center mb-3">
                                  <h4 className="font-size-14 mb-0">
                                    {props.t("Total")}
                                  </h4>
                                </div>
                                <div className="text-muted mt-4">
                                  <h5>QR {grandTotal && grandTotal}</h5>
                                </div>
                              </Col>
                              <Col lg={2} sm={6}>
                                <AvField
                                  name="salary_date"
                                  label={props.t("Select Month")}
                                  type="month"
                                  value={currentMonth}
                                  onChange={(e, v) => {
                                    var date = new Date();

                                    var d = new Date(
                                      v.substring(0, 4),
                                      v.substring(5, 7),
                                      0
                                    );

                                    setCurrentDate(
                                      moment(d).format("YYYY-MM-DD")
                                    );
                                  }}
                                />{" "}
                              </Col>
                              <Col lg={2} sm={6}>
                                <div>
                                  <Label>&nbsp;</Label>
                                  <br />
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="mr-1"
                                  >
                                    {props.t("Generate")}
                                  </Button>{" "}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </AvForm>

                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allEmployees && allEmployees}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(GenerateSalary)));

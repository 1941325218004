import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const AllOrders = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadOrders();
  }, []);

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    let url = process.env.REACT_APP_BASEURL + "serviceandsales/get_sales";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllOrders(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [editModal, setEditModal] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  function invoiceFormatter(cell, row) {
    return (
      <a
        href={"/invoice?invoice=" + row._id}
        style={{ color: "blue" }}
        target="_blank"
      >
        {row.invoice_number}
      </a>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Invoice"),
      formatter: invoiceFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Client Name"),
      dataField: "client_details.client_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Mobile"),
      dataField: "client_details.client_mobile",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "total_amount",
      text: props.t("Total Amount"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerAlign: (column, colIndex) => "right",
      align: (cell, row, rowIndex, colIndex) => {
        return "right";
      },
    },
    {
      dataField: "discount",
      text: props.t("Discount"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerAlign: (column, colIndex) => "right",
      align: (cell, row, rowIndex, colIndex) => {
        return "right";
      },
    },
    {
      dataField: "final_amount",
      text: props.t("Final Amount"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerAlign: (column, colIndex) => "right",
      align: (cell, row, rowIndex, colIndex) => {
        return "right";
      },
    },
  ];
  const handleDownload = () => {
    const fileName = "orders";
    const exportType = "xls";
    console.log(allOrders);
    if (allOrders) {
      var data = JSON.parse(JSON.stringify(allOrders));
      data.forEach(function (v) {
        // v.name = v.name;
        // v.available_quantity_for_sale = v.is_for_sale
        //   ? v.available_quantity_for_sale
        //   : props.t("NA");
        // v.threshold_quantity_for_sale = v.is_for_sale
        //   ? v.threshold_quantity_for_sale
        //   : props.t("NA");
        // v.available_quantity_for_use = v.is_for_use
        //   ? v.available_quantity_for_use
        //   : props.t("NA");
        // v.threshold_quantity_for_use = v.is_for_use
        //   ? v.threshold_quantity_for_use
        //   : props.t("NA");
        // v.available_quantity_for_use = v.available_quantity_for_use;
        // v.is_for_sale = v.is_for_sale ? props.t("Yes") : props.t("No");
        // v.is_for_use = v.is_for_use ? props.t("Yes") : props.t("No");

        // v.category = v.product_category_details.name;
        v.client_name = v.client_details.client_name;
        delete v.appointment_id;
        delete v.service_details;
        delete v.sold_product_details;
        delete v.service_amount;
        delete v.is_active;
        delete v._id;
        delete v.createdAt;
        delete v.updatedAt;
        delete v.__v;
        delete v.client_details;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Product Sales")}
            breadcrumbItem={props.t("All Orders")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing orders here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={allOrders && allOrders}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          {...props.searchProps}
                          style={{ width: "300px" }}
                        />

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AllOrders))
);
